import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import plur from "plur";
import "./LicensePage.styles.scss";
import { useSelector } from "react-redux";
import { isEnterpriseUser } from "../../selectors/basics";

const TechnicianCount = ({ sumCount, userCount, onSumCountClick }) => {
  const isEnterprise = useSelector(isEnterpriseUser);
  if (isEnterprise) {
    return <div className="license-card__header-text-container licence-card__header-item">Enterprise License</div>;
  }
  return (
    <div className="license-card__header-text-container licence-card__header-item">
      <p className="m0 license-card__small-text">Licenses</p>
      <p className="m0 license-card__medium-text">
        You have{" "}
        <b onClick={onSumCountClick} className={onSumCountClick ? "underline clickable" : ""}>
          {sumCount}
        </b>{" "}
        {plur("license", sumCount)}
      </p>
      <p className="m0 license-card__medium-text">
        <b>{userCount}</b> technician user {plur("license", userCount)}
      </p>
    </div>
  );
};

TechnicianCount.propTypes = {
  sumCount: PropTypes.number,
  userCount: PropTypes.number,
  onSumCountClick: PropTypes.func,
};

TechnicianCount.propTypes = {
  onSumCountClick: noop,
};

export default TechnicianCount;
