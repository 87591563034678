import React, { useEffect } from "react";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import { Preloader, MIcon } from "@macrium/shared-components";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import {
  clearState,
  googleRegisterAndRedirectAsync,
  googleTryRegisterAsync,
  microsoftRegisterAndRedirectAsync,
  microsoftTryRegisterAsync,
  registerAsync,
} from "../../slices/auth/register";
import { isLoadingRegister, getRegisterState } from "../../selectors/basics";
import GoogleButton from "./SSOButtons/Google.jsx";
import MicrosoftButton from "./SSOButtons/Microsoft.jsx";
import SSOContainer from "./SSOButtons/SSOContainer.jsx";
import * as TOAST_TYPES from "../../constants/toast";
import { REGISTER_TYPES } from "../../constants/auth";
import ROUTES from "../../app/routes";
import { getTrialFromCookie } from "../../utils/getTrialFromCookie";
import { isBusinessProduct } from "../../utils/products";
import { BUSINESS_STEP, HOME_STEP } from "./Register/steps";
import HomeForm from "./Register/HomeForm";
import BusinessForm from "./Register/BusinessForm";
import { isSuccessAction } from "../../utils/redux/actions";
import { setIsBlocking } from "../../slices/prompt/prompt.js";

const Register = () => {
  const [isEmailDisabled, setEmailDisabled] = React.useState(false);
  const [step, setStep] = React.useState(HOME_STEP);
  const [formToSubmit, setFormToSubmit] = React.useState({});

  const location = useLocation();

  const loading = useSelector(isLoadingRegister);
  const { registerType: type } = useSelector(getRegisterState);

  const trialProductDetails = getTrialFromCookie() || {};
  const trialEdition = get(trialProductDetails, "edition");
  const isBusinessTrial = isBusinessProduct(trialEdition);
  const isSSO = type === REGISTER_TYPES.GOOGLE || type === REGISTER_TYPES.MICROSOFT;

  const dispatch = useDispatch();

  const onGoogleTryRegister = async (response) => {
    const action = await dispatch(googleTryRegisterAsync({ accessToken: response.access_token }));

    if (isSuccessAction(action)) {
      const { firstName = "", lastName = "", email = "" } = get(action, "payload.data", {});

      setFormToSubmit({ firstName, lastName, email });
    }
  };

  const microsoftTryRegister = async (response) => {
    const data = {
      accessToken: response.accessToken,
      credential: response.idToken,
    };

    const action = await dispatch(microsoftTryRegisterAsync(data));

    if (isSuccessAction(action)) {
      const { firstName = "", lastName = "", email = "" } = get(action, "payload.data", {});

      setFormToSubmit({ firstName, lastName, email });
    }
  };

  const goBack = () => {
    dispatch(clearState());

    push(ROUTES.REGISTER);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const emailParam = params.get("email");

    if (emailParam) {
      setFormToSubmit({ ...formToSubmit, email: emailParam });
      setEmailDisabled(true);
    }

    // "formToSubmit" does not need to be a dependency as this is for only the first render
    // eslint-disable-next-line
  }, [location.search, setFormToSubmit, setEmailDisabled]);

  const goToBusinessStep = () => setStep(BUSINESS_STEP);
  const goToHomeStep = () => setStep(HOME_STEP);

  const onSave = async (data) => {
    const newFormToSubmit = { ...formToSubmit, ...data };
    dispatch(setIsBlocking(false));
    // Save "form" in case the request fails
    setFormToSubmit(newFormToSubmit);
    if (isBusinessTrial && step === HOME_STEP) {
      return goToBusinessStep();
    }
    if (type === REGISTER_TYPES.EMAIL) return dispatch(registerAsync(newFormToSubmit));
    if (type === REGISTER_TYPES.GOOGLE) return dispatch(googleRegisterAndRedirectAsync(newFormToSubmit));
    if (type === REGISTER_TYPES.MICROSOFT) return dispatch(microsoftRegisterAndRedirectAsync(newFormToSubmit));
  };

  return (
    <div className="register-container relative">
      <Preloader loading={loading} size="large" content="Registering" />

      {isSSO && step === HOME_STEP && (
        <div className="flex flex-column mb2 register-container__back">
          <div className="cGrey1 clickable flex flex-center" onClick={goBack}>
            <MIcon name="chevron left" className="mr1" />
            Back
          </div>
        </div>
      )}

      <div>
        {isSSO && (
          <div className="pt1 pb3">
            <h2>Hi There {formToSubmit["firstName"]}</h2>
            <p>Please check the details below and we’ll quickly get your account set up for you</p>
          </div>
        )}

        {step === HOME_STEP && (
          <HomeForm
            type={type}
            step={step}
            isBusinessTrial={isBusinessTrial}
            loading={loading}
            isEmailDisabled={isEmailDisabled}
            onSave={onSave}
            values={formToSubmit}
          />
        )}

        {step === BUSINESS_STEP && (
          <BusinessForm
            type={type}
            loading={loading}
            onSave={onSave}
            goBack={(data) => {
              // set form to submit so we don't lose the context
              setFormToSubmit({ ...formToSubmit, ...data });
              goToHomeStep();
            }}
            values={formToSubmit}
          />
        )}

        {type === REGISTER_TYPES.EMAIL && step === HOME_STEP && (
          <>
            <Divider horizontal className="my3">
              Or
            </Divider>

            <div className="mb2">
              <SSOContainer>
                <GoogleButton
                  onSuccess={onGoogleTryRegister}
                  onError={() => toast("Sign up has failed", { type: TOAST_TYPES.ERROR })}
                  register
                />

                <MicrosoftButton authCallback={microsoftTryRegister} register />
              </SSOContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
