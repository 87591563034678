import React from "react";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";

import { copyToClipboard } from "../../utils/generic";

const CopyToClipboard = ({ textToCopy }) => (
  <span data-testid="copy-button" className="prhalf clickable" onClick={() => copyToClipboard(textToCopy)}>
    <MIcon name="copy" size="small" />
  </span>
);

CopyToClipboard.propTypes = {
  textToCopy: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  textToCopy: "",
};

export default CopyToClipboard;
