import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import OfflineKeyActions from "./OfflineKeyActions";

const OfflineStepTwo = ({ handleNextStep, offlineKey }) => {
  return (
    <div className="flex justify-between flex-column offline-step-container">
      <div>
        <div className="offline-step-header">Step 2</div>
        <OfflineKeyActions offlineKey={offlineKey} />
      </div>
      <div>
        <Button primary content="Continue" onClick={handleNextStep} />
      </div>
    </div>
  );
};
OfflineStepTwo.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  offlineKey: PropTypes.string.isRequired,
};
export default OfflineStepTwo;
