import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Label } from "semantic-ui-react";
import { RED_LABEL, GREEN_LABEL, REFLECT_HOME, HOME_LICENSES, EXPIRED } from "../../constants/licenses.js";
import "./LicensePage.styles.scss";
import { SUBSCRIPTION } from "../../constants/licenses-products.js";

const SupportExpiry = ({ description, allRenewalSame, allExpired, nextExpiry, lastExpiry, subscription }) => (
  <div className="license-card__support-container licence-card__header-item">
    {subscription === SUBSCRIPTION &&
      (allExpired ? (
        <p className="bold m0 pb1">Subscription expired on {moment(lastExpiry).format("L")}</p>
      ) : (
        <p className="bold m0 pb1">
          {allRenewalSame ? "" : "Next "}Subscription renews on: {moment(nextExpiry).format("L")}
        </p>
      ))}
    <div className="flex flex-center">
      <p className="pr1 m0 bold">Support:</p>
      <Label
        className={allExpired ? RED_LABEL : GREEN_LABEL}
        content={allExpired ? EXPIRED : HOME_LICENSES[description] ? "Home" : "Business"}
      />
    </div>
    {subscription !== SUBSCRIPTION &&
      (allExpired ? (
        <p className="m0 pt1 license-card__small-text">{`Support expired on ${moment(lastExpiry).format("L")}`}</p>
      ) : (
        <p className="m0 pt1 license-card__small-text">
          {`${allRenewalSame && description === REFLECT_HOME ? "Next " : ""}Support ${
            description === REFLECT_HOME ? "expires " : "renews "
          }on: ${moment(nextExpiry).format("L")}`}
        </p>
      ))}
  </div>
);

SupportExpiry.propTypes = {
  description: PropTypes.string,
  allRenewalSame: PropTypes.bool,
  allExpired: PropTypes.bool,
  nextExpiry: PropTypes.object,
  lastExpiry: PropTypes.object,
  subscription: PropTypes.string.isRequired,
};

SupportExpiry.defaultProps = {
  description: "",
  allRenewalSame: false,
  allExpired: false,
  nextExpiry: {},
  lastExpiry: {},
};

export default SupportExpiry;
