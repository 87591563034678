import PropTypes from "prop-types";
import { ARTICLES_ALL, USER_FORUM } from "../../../constants/urls";

const FinishedContent = ({ productName }) => {
  return (
    <>
      <div className="flex centreOnMobile bold flex-center h3 ">
        <div className="finsishedHeader ">Congratulations & Welcome Aboard!</div>
      </div>
      <div className="bottomMediaBox">
        <div>
          <div className="py3">
            You&apos;ve successfully set sail on your journey with {productName}. You're now ready to protect and
            restore your precious data whenever needed.
          </div>
          <div className="my1 bold">What's next?</div>
          <div className="my1">
            <b>Learn: </b>Visit our{" "}
            <a href={ARTICLES_ALL} target="_blank" rel="noopener noreferrer">
              <span className=" underline black bold mrhalf">Knowledge Base</span>
            </a>
            for more tips, tricks, and tutorials to enhance your mastery.
          </div>
          <div className="my1">
            <b>Engage: </b>Join our{" "}
            <a href={USER_FORUM} target="_blank" rel="noopener noreferrer">
              <span className=" underline black bold mrhalf">Community</span>
            </a>
            of innovators. Share, learn, and grow with peers who are just as passionate as you are.
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishedContent;

FinishedContent.propTypes = {
  productName: PropTypes.string.isRequired,
};
