import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { MIcon, Preloader, SingleInputs } from "@macrium/shared-components";
import { push } from "connected-react-router";

import ROUTES from "../../app/routes";
import {
  getLoginForm,
  isLogin2FALoading,
  tfaState,
} from "../../selectors/basics";
import {
  set2FACode,
  login2FAAsync,
  clearState,
  resend2FACodeThunk,
} from "../../slices/auth/2fa";
import { clearState as clearStateLogin } from "../../slices/auth/login";
import ResendLink from "../generic/resendLink.jsx";

import "./2fa.styles.scss";

const Auth = () => {
  const loading = useSelector(isLogin2FALoading);
  const email = useSelector(getLoginForm)?.email ?? "";
  const { loadingMessage } = useSelector(tfaState);

  const dispatch = useDispatch();
  const setCode = React.useCallback(
    (code2) => dispatch(set2FACode({ code2 })),
    [dispatch]
  );

  const login2FA = () => {
    if (loading) return;

    dispatch(login2FAAsync());
  };

  const backToLogin = () => {
    dispatch(clearState());
    dispatch(clearStateLogin());
    dispatch(push(ROUTES.LOGIN));
  };

  return (
    <div className="flex center tfa-container">
      <Preloader loading={loading} size="big" content={loadingMessage} />

      <div className="tfa-container__back">
        <div className="flex flex-column tfa-container__back__btn">
          <div
            className="tfa-container__back__btn-container"
            onClick={backToLogin}
          >
            <MIcon name="chevron left" className="mr1" />
            Back
          </div>
        </div>
      </div>

      <div className="center tfa-container__content">
        <h2 className="mb1">Two-Factor Authentication Required</h2>

        <p className="mb3">
          Verify your account by entering the code sent to your email address{" "}
          {email}
        </p>

        <div className="mb2">
          <div className="tfa-code">
            <SingleInputs onChange={setCode} onSubmit={login2FA} />
          </div>

          <Button
            primary
            content="Verify"
            fluid
            className="mt3"
            onClick={login2FA}
          />
        </div>

        <div className="center">
          <ResendLink
            content="Resend Code"
            onClick={() => dispatch(resend2FACodeThunk())}
          />
        </div>
      </div>
    </div>
  );
};

export default Auth;
