/* Reach Hook Form validations. These are validated in order */
export const PASSWORD_VALIDATIONS = {
  startsWithChar: (v) => /(^\s+)|(\s+$)/.test(v) ? "Password cannot start or end with empty spaces" : undefined,
  sevenChars: (v) => v.length >= 7,
  oneUpper: (v) => /[A-Z]/.test(v),
  oneLower: (v) => /[a-z]/.test(v),
  oneSpecialChar: (v) => /[^a-zA-Z0-9\s]/.test(v),
  oneNumber:(v) => /\d/.test(v),
};

export const PASSWORD_REQUIREMENTS = [
  {
    text: "Seven characters",
    test: PASSWORD_VALIDATIONS["sevenChars"],
    name: "sevenChars"
  },
  {
    text: "One uppercase letter",
    test: PASSWORD_VALIDATIONS["oneUpper"],
    name: "oneUpper"
  },
  {
    text: "One lowercase letter",
    test: PASSWORD_VALIDATIONS["oneLower"],
    name: "oneLower"
  },
  {
    text: "One special character like $%^*",
    test: PASSWORD_VALIDATIONS["oneSpecialChar"],
    name: "oneSpecialChar"
  },
  {
    text: "One numerical character",
    test: PASSWORD_VALIDATIONS["oneNumber"],
    name: "oneNumber"
  },
];

//--------


/**
 * It checks if both passwords meet the requirements and returns the data needed before submitting the data
 * as well as if the passwords match
 * @param {string} password 
 * @param {string} confirmPassword 
 * @returns {object}
 */
export const checkPasswordRequirements = (password, confirmPassword) => {
  const requirementsMetResults = PASSWORD_REQUIREMENTS.map((req) => {
    
    return ({
      pass: req.test(password),
      text: req.text
    });
  });

 const allRequirementsMetResult = requirementsMetResults.every((req) => req.pass);

  return {
    requirementsMetResults,
    allRequirementsMetResult,
    passwordsMatch: password === confirmPassword,
    passwordStartEndsEmpty: !!PASSWORD_VALIDATIONS.startsWithChar(password)
  };
};