import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";
import { Link } from "@macrium/shared-components";
import { openModal } from "../../slices/offlineActivation/offlineActivation";

const OfflineLicenseButton = ({ data }) => {
  const dispatch = useDispatch();
  const productData = { description: data.description, licenses: data.licenses };
  return (
    <Popup
      className="fix-popup-alignment"
      trigger={
        <div className="mt1" onClick={() => dispatch(openModal(productData))}>
          <Link>Activate Licenses Offline</Link>
        </div>
      }
      wide
      hideOnScroll
      content={
        <div data-testid="add-keys-pop-up">
          <p>
            Offline activation allows you to activate your license on a device without requiring an internet connection.
          </p>
          <p>
            This method is useful in secure or isolated environments where direct online activation is not feasible.
          </p>
        </div>
      }
      position="bottom left"
    />
  );
};

export default OfflineLicenseButton;

OfflineLicenseButton.propTypes = {
  data: PropTypes.object.isRequired,
};
