import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ToastContainer, Slide, toast } from "react-toastify";
import moment from "moment";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GTagManager from "react-gtm-module";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { store, history } from "./app/store.js";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import CONFIG from "./app/config";

import "./app/sentry.js";

import "./css/index.css";
import "./scss/main.scss";

import "moment/min/locales";

const setLocale = () => {
  // set language
  moment.locale(window.navigator.userLanguage || window.navigator.language);
};

setLocale();

const tagManagerArgs = {
  gtmId: "GTM-KLS2BF5",
};

GTagManager.initialize(tagManagerArgs);

// MSAL configuration
// (https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md)
const configuration = {
  auth: {
    clientId: CONFIG.REACT_APP_MICROSOFT_CLIENT_ID,
  },
};

const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  React.createElement(() => (
    <GoogleOAuthProvider clientId={CONFIG.REACT_APP_GOOGLE_CLIENT_ID}>
      <MsalProvider instance={pca}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} transition={Slide} />
            <App />
          </ConnectedRouter>
        </Provider>
      </MsalProvider>
    </GoogleOAuthProvider>
  )),
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
