import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";
import { Link } from "@macrium/shared-components";
import { useDispatch } from "react-redux";

import { acceptPrivacyPolicyAsync } from "../../../slices/account/details";
import { PRIVACY_POLICY } from "../../../constants/urls";
import { logoutThunk } from "../../../slices/auth/login";

const PrivacyPolicyModal = ({ hasAcceptedPrivacyPolicy, isLoading }) => {
  const dispatch = useDispatch();

  const showModal = hasAcceptedPrivacyPolicy === false;
  if (!showModal) return null;

  const logout = () =>
    dispatch(
      logoutThunk({
        message: "You need to accept the Terms & Privacy Policy to access your account. Please login again to do so.",
      })
    );

  return (
    <Modal open={showModal} closeOnDimmerClick={false} size="mini">
      <Modal.Header>We&apos;ve upgraded our privacy policy</Modal.Header>
      <Modal.Content>
        <div className="block">
          By clicking 'Accept' you are agreeing to have your personal information managed in accordance with the terms
          of Macrium's{" "}
          <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
            <span className=" underline black bold">Privacy Notice.</span>
          </a>
          <div className="mt2">
            <Link content="Reject and Log Out" onClick={logout} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Accept"
          primary
          onClick={() => dispatch(acceptPrivacyPolicyAsync())}
          loading={isLoading}
          disabled={isLoading}
        />
      </Modal.Actions>
    </Modal>
  );
};

PrivacyPolicyModal.propTypes = {
  hasAcceptedPrivacyPolicy: PropTypes.bool,
  isLoading: PropTypes.bool,
};

PrivacyPolicyModal.defaultProps = {
  hasAcceptedPrivacyPolicy: true,
  isLoading: false,
};

export default PrivacyPolicyModal;
