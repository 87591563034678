import { createSelector } from "reselect";

import { getAccountDetails, isLoggedIn } from "./basics";
import ROUTES, { ROUTES_NAMING } from "../app/routes.js";
import { getInitials } from "../utils/generic";

export const getSideMenuProps = createSelector(
  [isLoggedIn, getAccountDetails],
  (loggedIn, { firstName, lastName }) => {
    const items = [];
    const footerItems = [];

    if (loggedIn) {
      items.push(
        {
          name: ROUTES.OVERVIEW,
          text: ROUTES_NAMING.OVERVIEW,
          iconName: "home",
          iconType: "macrium"
        },
        {
          name: ROUTES.LICENCES,
          text: ROUTES_NAMING.LICENCES,
          iconName: "key",
          iconType: "macrium"
        },
        {
          name: ROUTES.DOWNLOADS,
          text: 'Downloads',
          iconName: 'download',
          iconType: 'macrium'
        },
        {
          name: ROUTES.BILLING,
          text: "Billing",
          iconName: "billing",
          iconType: "macrium"
        }
      );

      footerItems.push(
        {
          name: ROUTES.ACCOUNT_SETTINGS,
          text: (
            <div className="flex flex-center">
              <div className="sl-menu__open-menu-item__initials">{getInitials(firstName, lastName)}</div>
              <span>{ROUTES_NAMING.ACCOUNT_SETTINGS}</span>
            </div>
          ),
        },
        {
          name: ROUTES.LOGOUT,
          text: ROUTES_NAMING.LOGOUT,
          iconName: "sign out",
          iconType: "macrium"
        }
      );
    } else {
      items.push(
        {
          name: ROUTES.LOGIN,
          text: ROUTES_NAMING.LOGIN,
          iconName: "file csv",
          iconType: "macrium"
        },
        {
          name: ROUTES.REGISTER,
          text: ROUTES_NAMING.REGISTER,
          iconName: "dashboard",
          iconType: "fa"
        }
      );
    }

    return {
      open: true,
      selected: ROUTES.OVERVIEW,
      items,
      footerItems
    };
  }
);
