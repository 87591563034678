import moment from "moment";
import { isUndefined } from "lodash";

const checkLastExpiry = (originalDate, newDate) => {
  if (isUndefined(originalDate)) return newDate;
  if (isUndefined(newDate)) return originalDate;
  if (moment(newDate).isAfter(moment(originalDate))) return newDate;
  return originalDate;
};
export default checkLastExpiry;
