import React from "react";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";

import "./AncillaryButton.styles.scss";

const AncillaryButton = ({ children, icon }) => (
  <div className={`ancillary-button  clickable`}>
    <MIcon name={icon} color="cLogoLightBlue" size="normalLarge" />

    <div className="ancillary-button__text">{children}</div>
  </div>
);

AncillaryButton.propTypes = {
  children: PropTypes.element.isRequired,
  icon: PropTypes.string.isRequired,
};

export default AncillaryButton;
