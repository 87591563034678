import { reduce, forEach, cloneDeep } from "lodash";

const combineCounts = (count1, count2) => {
  const countToReturn = cloneDeep(count1);

  forEach(count2, (value, key) => {
    if (countToReturn[key]) {
      countToReturn[key] += value;
    } else countToReturn[key] = value;
  });

  return reduce(Object.keys(countToReturn).sort(), (acc, key) => ({ ...acc, [key]: countToReturn[key] }), {});
};
export default combineCounts;
