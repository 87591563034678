import React from "react";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";
import { LICENSES_ICON } from "../../constants/licenses";

const ProductIcon = ({ productName }) => {
  const product = LICENSES_ICON[productName];

  return (
    <div className="product-icon">
      <MIcon name={product ? product.name : ""} size="large" color="cLogoNavy" />
    </div>
  );
};

ProductIcon.propTypes = {
  productName: PropTypes.oneOf(Object.keys(LICENSES_ICON)),
};

export default ProductIcon;
