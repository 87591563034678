import React from "react";
import { Button } from "semantic-ui-react";

const EditButton = ({ editMode, onEditClick, onSaveClick, disabled, isLoading }) => (
  <Button
    content={editMode ? "Apply" : "Edit"}
    size="medium"
    type={editMode ? "submit" : "button"}
    onClick={editMode ? onSaveClick : onEditClick}
    className={editMode ? "edit-button edit-button--active" : "edit-button"}
    secondary={!editMode}
    primary={editMode}
    loading={isLoading}
    disabled={disabled}
  />
);

export default EditButton;
