import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { get, isEmpty } from "lodash";
import * as DeviceDetect from "react-device-detect";

import { LOADING, IDLE, FAILED } from "../actionStatuses.js";
import { downloadTrial } from "../../app/api/downloads.js";
import { ERROR, INFO, SUCCESS } from "../../constants/toast.js";
import {
  downloadExternalFile,
  getAbortTrialDownloadController,
  removeWebsiteDownloadCookie,
} from "../../utils/download.js";
import { EDITION_TO_PRODUCT_DESCRIPTION } from "../../constants/licenses-products.js";
import { isDownloadingTrial, needsBusinessProfiling } from "../../selectors/basics.js";
import { clearTrialProduct, setIsProfiling, setTrialProduct } from "../products/trials.js";
import { gtmPushTrialDownloadComplete, gtmPushTrialDownloadStart } from "../../utils/gtm/downloads.js";
import { createToastWithHeader } from "../../components/generic/ToastWithHeader.jsx";
import { fetchOnboardingAsync, updateLastDownLoaded } from "../onboarding/onboarding.js";
import { isDocumentHidden } from "../../utils/generic.js";

const REDUCER_NAME = "downloads";

const initialState = {
  status: IDLE,
  error: "",
};

const startDownloadTrialThunk = createAsyncThunk("downloads/trial", async (data, thunkAPI) => {
  // This endpoint returns the necessary information to start the download on the frontend
  const res = await downloadTrial(data);

  if (!res.success || isEmpty(res.data)) {
    return thunkAPI.rejectWithValue(res);
  }

  return res;
});

const clearPostDownload = (dispatch) => () => {
  removeWebsiteDownloadCookie();

  dispatch(clearTrialProduct());
  dispatch(setLoading(false));
};
const refreshOnboardingData = (dispatch, productName) => {
  dispatch(updateLastDownLoaded(productName));
  dispatch(fetchOnboardingAsync());
};
export const downloadTrialAsync =
  ({ architecture, edition, description, productName }, { siteType }) =>
  async (dispatch, getStore) => {
    const clear = clearPostDownload(dispatch);
    try {
      // Prevent multiple tabs downloading the same trial at the same time
      // https://macrium.atlassian.net/browse/SP-1289
      if (isDocumentHidden()) return;

      const isMobileOrTablet = DeviceDetect.isMobile || DeviceDetect.isTablet || DeviceDetect.isIOS;

      if (isMobileOrTablet) {
        clear();

        return toast("Downloads are only available on desktop devices", { type: INFO, autoClose: false });
      }

      const isLoading = isDownloadingTrial(getStore());

      if (isLoading) return;

      // Put the download info into the store in case we lose context because of the business profiling
      dispatch(setTrialProduct({ edition, architecture, description, siteType }));

      const needsProfiling = needsBusinessProfiling(getStore());

      if (needsProfiling && edition !== 6) return dispatch(setIsProfiling(true));

      const args = { edition, architecture };
      if (description) args.description = description;
      const { payload } = await dispatch(startDownloadTrialThunk(args));

      if (!payload.success) {
        clear();
        if (payload.message) {
          return toast(payload.message, { type: ERROR });
        }
        return toast("Could not start Download", { type: ERROR });
      }

      const data = get(payload, "data", {});

      dispatch(setLoading(true));

      // Push event to GTM
      gtmPushTrialDownloadStart({ arch: architecture, edition, siteType });

      const result = await downloadExternalFile(data, {
        progressMessage: `Downloading ${EDITION_TO_PRODUCT_DESCRIPTION[edition]} Trial`,
        onCancel: () => dispatch(cancelTrialDownload()),
      });

      if (get(result, "success")) {
        gtmPushTrialDownloadComplete({ arch: architecture, edition, siteType });
        refreshOnboardingData(dispatch, productName);
        toast(
          createToastWithHeader(
            "Your download has completed successfully",
            <p>Please check your browser downloads or your downloads folder to install</p>
          ),
          { type: SUCCESS, autoClose: false, className: "toast-big" }
        );
      }
    } catch (err) {
      console.error("Error downloading trial ", err);

      toast("Could not complete the download", { type: ERROR });
    }

    // Make sure we always clear the cookie/state regardless if there was an error or not, after a success download or failed download
    clear();
  };

export const cancelTrialDownload = () => async (dispatch) => {
  try {
    const controller = getAbortTrialDownloadController();

    controller.abort();

    await dispatch(cancelTrial());
  } catch (err) {
    toast("Could not cancel the download", { type: ERROR });
  }
};

export const slice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setLoading: (state, { payload: loading }) => {
      state.status = loading ? LOADING : IDLE;
    },
    cancelTrial: (state) => {
      state.status = IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startDownloadTrialThunk.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(startDownloadTrialThunk.fulfilled, (state) => {
        state.status = IDLE;
      })
      .addCase(startDownloadTrialThunk.rejected, (state) => {
        state.status = FAILED;
      });
  },
});

export const { setLoading, cancelTrial } = slice.actions;

export default slice.reducer;
