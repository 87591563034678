import React, { useState } from "react";
import { getLocation, push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import PropTypes from "prop-types";

import "./EmailChange.styles.scss";
import { changeEmailAsync } from "../../../slices/account/details";
//
import EmailInput from "../../generic/Inputs/EmailInput";
import { getAccountDetails, isEmailChangePending } from "../../../selectors/basics";
import ROUTES from "../../../app/routes";
import { REGISTER_TYPES } from "../../../constants/auth";

const EmailChange = ({ label, value, loginMethod }) => {
  const { email: queryEmail, code } = useSelector(getLocation)?.query;
  const isPending = useSelector(isEmailChangePending);
  const { newEmail } = useSelector(getAccountDetails);

  const [editMode] = useState(false);
  const [email, setEmail] = useState("");
  const [error] = useState("");

  const dispatch = useDispatch();

  // const cancel = async (e) => {
  //   e.preventDefault();

  //   let res = {};

  //   setError("");

  //   if (isPending) {
  //     res = await dispatch(cancelEmailChangeAsync());
  //   }

  //   if (!isPending || get(res, "payload.success")) {
  //     setEmail("");
  //     setEditMode(false);
  //   }
  // };

  React.useEffect(() => {
    async function confirmEmailChange() {
      await dispatch(changeEmailAsync({ newEmail: queryEmail, code }));
    }

    if (isPending && queryEmail && code) {
      confirmEmailChange();
      // Clear query params in case the user cancels ChangingEmail
      // and tries again
      dispatch(push(ROUTES));
    }
  }, [dispatch, isPending, queryEmail, code]);

  return (
    <div className="account-details__section__right__item">
      <div className={`flex flex-auto email-form ${editMode ? "email-form--edit-mode" : ""}`}>
        <div className="fullwidth">
          <div className="fullwidth flex justify-between items-center">
            <div className="mb1 bold">{label}</div>{" "}
            {loginMethod !== REGISTER_TYPES.EMAIL && (
              <div className="mb1">Managed by {loginMethod === REGISTER_TYPES.GOOGLE ? "Google" : "Microsoft"}</div>
            )}
          </div>
          <div>
            {editMode || isPending ? (
              <>
                <p>{value}</p>

                <p className="mt3">
                  {isPending ? (
                    <>
                      You will need to verify this new email address by clicking on the link we have sent to your inbox
                    </>
                  ) : (
                    <>
                      Please enter a new email address to associate with your account. You will need to verify this new
                      email address by clicking on the link we have sent to your inbox
                    </>
                  )}
                </p>

                <Form>
                  <Form.Field error={!!error}>
                    {isPending ? (
                      <EmailInput key="isPending" value={newEmail} disabled />
                    ) : (
                      <EmailInput
                        placeholder="Enter new Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                    <FormFieldError error={error} />
                  </Form.Field>
                </Form>
              </>
            ) : (
              <p className="mb1">{value}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
EmailChange.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  loginMethod: PropTypes.string.isRequired,
};

export default EmailChange;
