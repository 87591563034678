import { createSelector } from "reselect";
import { reduce, toNumber } from "lodash";

import { getCurrentProducts } from "./licenses";
import { isLegacy } from "../utils/products";

/** 
 * Order and split products into Products and Legacy products
 */
export const getProductsToDownloadsPage = createSelector(
  [getCurrentProducts], 
  (allProducts) => {
    return reduce(allProducts, (res, product) => {
      // Make sure we don't show V10 as it does not make sense on a downloads page. The product version is 8
      const downloadVersion = toNumber(product.version) > 8 ? "8" : product.version;

      const updatedVersionProduct = { ...product, version: downloadVersion };

      if (product.isTrial === "1" || !isLegacy(product.version)) {
        res.products.push(updatedVersionProduct);
      } else {
        res.legacyProducts.push(updatedVersionProduct);
      }

      return res;
    }, {
      products: [],
      legacyProducts: []
    });
  }
);
