import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { IDLE, LOADING, FAILED } from '../actionStatuses';
import * as API from '../../app/api/authAccount';
import { getLoginState, tfaState } from '../../selectors/basics';
import { setAuthTokenAfter2FA } from './login';
import * as TOAST_TYPES from '../../constants/toast';
import { handleLoginResponse } from './helpers';

const REDUCER_NAME = 'tfa';

const initialState = {
  status: IDLE,
  loadingMessage: '',
  code2: '',
  resentCode: false
};

/**
 * Async Actions
 */
const login2FAThunk = createAsyncThunk(
  `${REDUCER_NAME}/login2FARequest`,
  async (data, thunkAPI) => {
    const response = await API.login2FA(data);

    if (!response.success) {
      toast(response.message, { type: TOAST_TYPES.ERROR });
      
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

// Redirect the user to the previous page/overview page when a successfully login
export const login2FAAsync = () => async (dispatch, getStore) => {
  const { code1, form: { email } = {} } = getLoginState(getStore());
  const { code2 } = tfaState(getStore());

  const { payload } = await dispatch(login2FAThunk({ code1, code2, email }));

  if (payload.success) {
    dispatch(setAuthTokenAfter2FA({ authToken: payload.data.token }));
    
    handleLoginResponse(dispatch, payload)
  }

  // Router is redirecting user
}

// Redirect the user to the previous page/overview page when a successfully login
export const resend2FACodeThunk = createAsyncThunk(
  `${REDUCER_NAME}/resend2FACodeRequest`,
  async (data, thunkAPI) => {
    const { code1, form: { email } = {} } = getLoginState(thunkAPI.getState());

    const response = await API.resend2FACode({ code1, email });

    if (!response.success) {
      toast('Could not resend code.', { type: TOAST_TYPES.ERROR });
      
      return thunkAPI.rejectWithValue(response);
    }

    toast('A code has been resent to your email address.', { type: TOAST_TYPES.SUCCESS });

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

/**
 * Reducer
 */
export const tfaReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
    set2FACode: (state, { payload }) => {
      state.code2 = payload.code2;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login2FAThunk.pending, state => {
        state.status = LOADING;
        state.loadingMessage = 'Logging in';
      })
      .addCase(login2FAThunk.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.loadingMessage = '';
      })
      .addCase(login2FAThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.loadingMessage = '';
        state.code2 = '';
      })

      .addCase(resend2FACodeThunk.pending, state => {
        state.status = LOADING;
        state.loadingMessage = 'Resending code to your email address';
      })
      .addCase(resend2FACodeThunk.fulfilled, state => {
        state.status = IDLE;
        state.resentCode = true;
        state.loadingMessage = '';
      })
      .addCase(resend2FACodeThunk.rejected, state => {
        state.status = IDLE;
        state.loadingMessage = '';
      })
  }
});

/**
 * Actions
 */
export const { set2FACode, clearState } = tfaReducer.actions;

export default tfaReducer.reducer;
