import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MIcon, Preloader } from "@macrium/shared-components";

import "./ForgotPassword.styles.scss";
import ResetSuccessMessage from "../ResetSuccessMessage/ResetSuccessMessage";
import PasswordResetForm from "../PasswordResetForm/PasswordResetForm";
import {
  requestPasswordResetThunk,
  clearState,
} from "../../../slices/auth/forgotPassword";
import {
  getLoginForm,
  getForgotPasswordState,
} from "../../../selectors/basics";
import ROUTES from "../../../app/routes";
import { LOADING, SUCCESS } from "../../../slices/actionStatuses";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { errorMessage, status } = useSelector(getForgotPasswordState);
  const loginForm = useSelector(getLoginForm);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (loginForm.email) {
      setEmail(loginForm.email);
    }
  }, [loginForm.email]);

  const handleRequestPasswordReset = () => {
    dispatch(requestPasswordResetThunk(email));
  };

  const handleBackToLogin = () => {
    dispatch(clearState());
    history.push(ROUTES.LOGIN);
  };

  if (status === SUCCESS) {
    return <ResetSuccessMessage handleBackToLogin={handleBackToLogin} />;
  }

  return (
    <>
      <div className="forgot-password__container">
        <Preloader loading={status === LOADING} size="large" />

        <div className="forgot-password__back">
          <div className="flex flex-column forgot-password__back__btn">
            <div
              className="forgot-password__back__btn-container"
              onClick={handleBackToLogin}
            >
              <MIcon name="chevron left" className="mr1" />
              Back
            </div>
          </div>
        </div>
        <div className="forgot-password__main">
          <h2>Recover password</h2>
          <p className="forgot-password__text">
            We’ll quickly reset your password and get you back on your way
          </p>
          <PasswordResetForm
            email={email}
            setEmail={setEmail}
            handleRequestPasswordReset={handleRequestPasswordReset}
            errorMessage={errorMessage}
          />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
