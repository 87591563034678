import { get, includes, split, toUpper } from 'lodash';
import { toast } from "react-toastify";
import moment from "moment";

import { ERROR, INFO } from '../constants/toast';

export const openInNewTab = (url) => window.open(url, "_blank", "noreferrer");

export const getInitials = (firstName = '', lastName = '') => {
  const firstInitial = !firstName ? '' : get(firstName, '0');

  let secondInitial = '';

  if (includes(lastName, '-')) {
    const [, surname] = split(lastName, '-');

    secondInitial = get(surname, '0');;
  } else {
    secondInitial = !lastName ? '' : get(lastName, '0');
  }

  return `${toUpper(firstInitial)}${toUpper(secondInitial)}`;
};

const CLIPBOARD_TOAST_CONFIG = {
  pauseOnFocusLoss: false,
  autoClose: 2000
};

export const copyToClipboard = (string) => {
  navigator.clipboard.writeText(string).then(
    () => {
      toast("Copied to the clipboard", {
        type: INFO,
        ...CLIPBOARD_TOAST_CONFIG
      });
    },
    () => {
      toast("An error has occurred", {
        type: ERROR,
        ...CLIPBOARD_TOAST_CONFIG
      });
    }
  );
};

/**
 * I takes a unix timestamp and returns a localized formatted date
 * @param {number} date date to format (unix)
 * @param {object} options
 * @param {string} options.format format of the final date
 * @returns 
 */
export const formatDate = (date, { format } = {}) => {
  if (!date) return "";

  return moment.unix(date).format(format || "L");
};

/**
 * Returns if the document/tab is visible or not.
 * @returns {boolean} if the document is visible or not
 */
export const isDocumentHidden = () => document.hidden;
