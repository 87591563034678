export const DEFAULT_HOME_FIELDS = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  subscribe: "", // can be 1 or 0
  twoFA: false,
};

export const DEFAULT_BUSINESS_FIELDS = {
  job: "",
  company: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  stateProvince: "",
  postalZipCode: "",
  country: "",
};
