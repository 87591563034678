import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Input } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";

const regex = /[0-9()+]{1}/;

const PhoneInput = ({ value, onChange, onSave, showIcon, ...props }) => {
  const onKeyDown = (e) => {
    // If the pressed key is not a number, "+ ( )" or special keys, prevent default behavior
    if (
      !regex.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== " " &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
    if (e.key === "Enter") {
      return onSave();
    }
  };

  return (
    <Input
      icon={!showIcon}
      iconPosition={showIcon ? "left" : null}
      placeholder="Phone Number"
      value={value}
      onChange={(e) => onChange(e, "phone")}
      onKeyDown={onKeyDown}
      {...props}
    >
      <input />
      {showIcon && <MIcon name="phone" />}
    </Input>
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func,
  showIcon: PropTypes.bool,
  onSave: PropTypes.func,
};

PhoneInput.defaultProps = {
  value: "",
  onSave: noop,
  showIcon: false,
};

export default PhoneInput;
