import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { get } from "lodash";

import { IDLE, LOADING, FAILED } from "../actionStatuses";
import * as API from "../../app/api/authAccount";
import * as TOAST_TYPES from "../../constants/toast";
import { createToastWithHeader } from "../../components/generic/ToastWithHeader";
import { setCookieAndBroadcast } from "../auth/login";

const REDUCER_NAME = "details";

const initialState = {
  status: IDLE,
  data: {},
  error: "",
  // Separate the loading statuses for when updating the email after clicking on the email.
  updatingEmailStatus: false,
};

/**
 * Async Actions
 */
export const getAccountDetailsAsync = createAsyncThunk(`${REDUCER_NAME}/getDetailsRequest`, async (form, thunkAPI) => {
  const response = await API.getAccountDetails();

  if (!response.success) return thunkAPI.rejectWithValue(response);

  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const updateAccountDetailsAsync = createAsyncThunk(
  `${REDUCER_NAME}/updateDetailsRequest`,
  async (data, thunkAPI) => {
    const response = await API.updateAccountDetails(data);

    if (!response.success) {
      toast(createToastWithHeader("Update Failed", response.message), { type: TOAST_TYPES.ERROR });

      return thunkAPI.rejectWithValue(response);
    }

    toast(createToastWithHeader("Update Successful"), { type: TOAST_TYPES.SUCCESS });

    return response;
  }
);

export const requestEmailChangeAsync = createAsyncThunk(
  `${REDUCER_NAME}/requestEmailChangeRequest`,
  async (data, thunkAPI) => {
    const response = await API.requestEmailChange(data);

    if (!response.success) {
      toast(createToastWithHeader("Email Change Failed", "Please try again"), { type: TOAST_TYPES.ERROR });

      return thunkAPI.rejectWithValue(response);
    }

    toast(createToastWithHeader("Please check your inbox", "Click on the link we have sent to your inbox"), {
      type: TOAST_TYPES.INFO,
    });

    return response;
  }
);

export const changeEmailAsync = createAsyncThunk(`${REDUCER_NAME}/changeEmailRequest`, async (data, thunkAPI) => {
  const response = await API.changeEmail(data);

  if (!response.success) {
    toast(createToastWithHeader("Email Change Failed", response.message), { type: TOAST_TYPES.ERROR });

    return thunkAPI.rejectWithValue(response);
  }

  toast(createToastWithHeader("Update Successful", "Your email address has been changed"), {
    type: TOAST_TYPES.SUCCESS,
  });

  setCookieAndBroadcast(get(response, "data.token"));

  return response;
});

export const cancelEmailChangeAsync = createAsyncThunk(
  `${REDUCER_NAME}/cancelEmailChangeRequest`,
  async (data, thunkAPI) => {
    const response = await API.cancelEmailUpdate(data);

    if (!response.success) {
      toast("Cancel Email Change Failed", { type: TOAST_TYPES.ERROR });

      return thunkAPI.rejectWithValue(response);
    }

    toast("Email Change Canceled", { type: TOAST_TYPES.INFO });

    return response;
  }
);

export const updatePasswordAsync = createAsyncThunk(`${REDUCER_NAME}/updatePasswordRequest`, async (data, thunkAPI) => {
  const response = await API.changePassword(data);

  if (!response.success) {
    toast(createToastWithHeader("Password Change Failed", response.message), { type: TOAST_TYPES.ERROR });

    return thunkAPI.rejectWithValue(response);
  }

  toast(createToastWithHeader("Update Successfully", response.message), { type: TOAST_TYPES.SUCCESS });

  return response;
});
export const acceptPrivacyPolicyAsync = createAsyncThunk(`${REDUCER_NAME}/acceptpolicy`, async (data, thunkAPI) => {
  const res = await API.acceptPrivacyPolicy(data);
  if (!res.success) return thunkAPI.rejectWithValue(res);
  return res;
});

export const acceptCookiePolicyAsync = createAsyncThunk(`${REDUCER_NAME}/acceptcookies`, async (data, thunkAPI) => {
  const res = await API.acceptCookies(data);
  if (!res.success) return thunkAPI.rejectWithValue(res);
  return res;
});
/**
 * Reducer
 */
export const detailsReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountDetailsAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(getAccountDetailsAsync.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.data = get(payload, "data", {});
      })
      .addCase(getAccountDetailsAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload;
      })

      .addCase(updateAccountDetailsAsync.pending, (state) => {
        state.updatingAccountDetails = LOADING;
        state.error = null;
      })
      .addCase(updateAccountDetailsAsync.rejected, (state, { payload }) => {
        state.updatingAccountDetails = FAILED;
        state.error = payload.data;
      })
      .addCase(updateAccountDetailsAsync.fulfilled, (state, { payload }) => {
        state.updatingAccountDetails = IDLE;
        state.data = get(payload, "data", {});
      })

      .addCase(updatePasswordAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(updatePasswordAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload;
      })
      .addCase(updatePasswordAsync.fulfilled, (state) => {
        state.status = IDLE;
        state.error = null;
      })

      .addCase(requestEmailChangeAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(requestEmailChangeAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload;
      })
      .addCase(requestEmailChangeAsync.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.error = null;
        state.data = get(payload, "data", {});
      })

      .addCase(changeEmailAsync.pending, (state) => {
        state.updatingEmailStatus = LOADING;
        state.error = null;
      })
      .addCase(changeEmailAsync.rejected, (state, { payload }) => {
        state.updatingEmailStatus = FAILED;
        state.error = payload;
      })
      .addCase(changeEmailAsync.fulfilled, (state, { payload }) => {
        state.updatingEmailStatus = IDLE;
        state.error = null;
        state.data = get(payload, "data", {});
      })

      .addCase(cancelEmailChangeAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(cancelEmailChangeAsync.rejected, (state) => {
        state.status = FAILED;
      })
      .addCase(cancelEmailChangeAsync.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.error = null;
        state.data = get(payload, "data", {});
      })
      .addCase(acceptPrivacyPolicyAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(acceptPrivacyPolicyAsync.rejected, (state) => {
        state.status = FAILED;
      })
      .addCase(acceptPrivacyPolicyAsync.fulfilled, (state) => {
        state.status = IDLE;
        state.error = null;
        state.data.policyAccepted = true;
      })
      .addCase(acceptCookiePolicyAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(acceptCookiePolicyAsync.rejected, (state) => {
        state.status = FAILED;
      })
      .addCase(acceptCookiePolicyAsync.fulfilled, (state) => {
        state.status = IDLE;
        state.error = null;
        state.data.cookiesAccepted = true;
      });
  },
});

/**
 * Actions
 */
// export const { login } = detailsReducer.actions;

export default detailsReducer.reducer;
