import React from "react";
import PropTypes from "prop-types";
import { ErrorBoundary } from "@macrium/shared-components";

import { SUBMIT_TICKET } from "../../constants/urls";

import "./CustomErrorBoundary.styles.scss";

const ErrorBoundaryMessage = () => (
  <>
    <p>
      If this problem persists, please get in touch with our{" "}
      <a href={SUBMIT_TICKET} target="_blank" rel="noopener noreferrer">
        support team
      </a>
      .
    </p>
    <p>We are here to help.</p>
  </>
);

export const CustomErrorBoundary = ({ children }) => (
  <ErrorBoundary className="ui segment app-error-boundary" message={<ErrorBoundaryMessage />}>
    {children}
  </ErrorBoundary>
);

CustomErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};
