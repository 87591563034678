import { get } from "lodash";
import checkNextExpiry from "./checkNextExpiry";
import checkLastExpiry from "./checkLastExpiry";
import checkFirstExpiry from "./checkFirstExpiry";

const addFloatingLicense = (version, license, product, isActive, expiryDate, purchaseDate, seats, floatingLicenses) => {
  const productDescriptionWithVersion = `${product.description}&${version}`;
  let productInfo = get(floatingLicenses, productDescriptionWithVersion, false);
  if (productInfo) {
    productInfo.licenses.push(license);
    productInfo.nextExpiry = checkNextExpiry(productInfo.nextExpiry, expiryDate);
    productInfo.lastExpiry = checkLastExpiry(productInfo.lastExpiry, expiryDate);
    productInfo.firstExpiry = checkFirstExpiry(productInfo.firstExpiry, expiryDate);
    productInfo.lastPurchasedOn = checkLastExpiry(productInfo.lastPurchasedOn, purchaseDate);
    productInfo.seats = productInfo.seats + parseInt(seats);
    productInfo.totalLicenses++;

    if (isActive) productInfo.activeCount++;
    return;
  }
  floatingLicenses[productDescriptionWithVersion] = {
    ...product,
    version,
    licenses: [license],
    productDescriptionWithVersion,
    activeCount: isActive ? 1 : 0,
    nextExpiry: expiryDate,
    firstExpiry: expiryDate,
    lastExpiry: expiryDate,
    lastPurchasedOn: purchaseDate,
    seats: parseInt(seats),
    totalLicenses: 1,
  };
};
export default addFloatingLicense;
