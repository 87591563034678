import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isEmpty, get } from "lodash";

import { getProductsRange } from "../../app/api/products";
import { IDLE, LOADING } from "../actionStatuses";

const REDUCER_NAME = "productsRange";

const initialState = {
  status: IDLE,
  data: []
}

export const getProductsRangeThunk = createAsyncThunk(
  `${REDUCER_NAME}/fetchProductsRange`,
  async (thunkAPI) => {
    const response = await getProductsRange();

    if (!response.success) return thunkAPI.rejectWithValue(response);

    return response;
  }
);

export const productsRangeSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductsRangeThunk.pending, (state) => {
        state.status = LOADING
      })
      .addCase(getProductsRangeThunk.fulfilled, (state, { payload }) => {
        let data = get(payload, "data.summary", [])

        state.status = IDLE
        state.data = isEmpty(data) ? [] : data
      })
      .addCase(getProductsRangeThunk.rejected, state => {
        state.status = IDLE
      })
  }
});

export default productsRangeSlice.reducer;
