import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Input, Icon, Checkbox } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import VALIDATOR from "validator";
import { forEach, isEmpty } from "lodash";

import { DEFAULT_HOME_FIELDS } from "./fields";
import { REGISTER_TYPES } from "../../../constants/auth";
import SSOButton from "../SSOButtons/SSOButton";
import PasswordRequirements from "../../generic/PasswordRequirements";
import { PASSWORD_VALIDATIONS, checkPasswordRequirements } from "../../../utils/requirements";
import { ContinueButton, RegisterButton } from "./Buttons";
import FormFieldController from "../../generic/FormFieldController";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

const HomeForm = ({ type, isBusinessTrial, loading, onSave, isEmailDisabled, values }) => {
  const {
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    watch,
    setValue,
    trigger,
    getFieldState,
  } = useForm({
    defaultValues: { ...DEFAULT_HOME_FIELDS },
  });

  const { isTouched: isPasswordTouched, isDirty: isPasswordDirty } = getFieldState("password");
  const { isTouched: isConfirmPasswordTouched, isDirty: isConfirmPasswordDirty } = getFieldState("confirmPassword");

  React.useEffect(() => {
    if (isEmpty(values)) return;

    forEach(DEFAULT_HOME_FIELDS, (defaultValue, key) => {
      let newValue = "";

      if (key === "subscribe") {
        newValue = values[key] === 1 || values[key] === 0 ? values[key] : defaultValue;
      } else {
        newValue = values[key] || defaultValue;
      }

      // dirty/touch fields in case values are coming from a parent component
      setValue(key, newValue, { shouldDirty: true, shouldTouch: true });
    });
  }, [setValue, values]);

  const subscribeValue = watch("subscribe");
  const onSubmit = (data) => onSave(data);

  const { requirementsMetResults } = checkPasswordRequirements(watch("password"), watch("confirmPassword"));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field error={!!errors?.firstName}>
        <label>First Name</label>
        <FormFieldController
          name="firstName"
          control={control}
          rules={{ required: "First Name is required" }}
          render={({ field }) => (
            <>
              <Input icon iconPosition="left" placeholder="First Name" autoFocus {...field}>
                <input />
                <Icon name="user" />
              </Input>

              <FormFieldError error={errors?.firstName?.message} />
            </>
          )}
        />
      </Form.Field>

      <Form.Field error={!!errors?.lastName}>
        <label>Last Name</label>
        <FormFieldController
          name="lastName"
          control={control}
          rules={{ required: "Last Name is required" }}
          render={({ field }) => (
            <>
              <Input icon iconPosition="left" placeholder="Last Name" {...field}>
                <input />
                <Icon name="user" />
              </Input>

              <FormFieldError error={errors?.lastName?.message} />
            </>
          )}
        />
      </Form.Field>
      <Form.Field error={!!errors?.email}>
        <label>Email</label>

        <FormFieldController
          name="email"
          control={control}
          type="email"
          enableDefaultMaxLength={false}
          rules={{
            required: "Email is required",
            validate: (value) => {
              return VALIDATOR.isEmail(value) || "Email is not valid";
            },
          }}
          render={({ field }) => (
            <>
              <Input
                icon
                iconPosition="left"
                placeholder="Email"
                disabled={type !== REGISTER_TYPES.EMAIL || isEmailDisabled}
                {...field}
              >
                <input />
                <Icon name="envelope" />
              </Input>

              <FormFieldError error={errors?.email?.message} />
            </>
          )}
        />
      </Form.Field>
      {type === REGISTER_TYPES.EMAIL && (
        <>
          <Form.Field error={!!errors?.password}>
            <label>Password</label>

            <FormFieldController
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                validate: PASSWORD_VALIDATIONS,
              }}
              render={({ field: { onChange, ...field } }) => (
                <>
                  <Input
                    icon
                    iconPosition="left"
                    placeholder="Create Password"
                    type="password"
                    autoComplete="off"
                    onChange={(...args) => {
                      onChange(...args);

                      if (isSubmitted) trigger("confirmPassword");
                    }}
                    {...field}
                  >
                    <input data-testid="password-input" />
                    <Icon name="lock" />
                  </Input>

                  <FormFieldError error={errors?.password?.message} />
                </>
              )}
            />
          </Form.Field>

          <Form.Field error={!!errors?.password || !!errors?.confirmPassword}>
            <label>Confirm Password</label>

            <FormFieldController
              name="confirmPassword"
              control={control}
              rules={{
                required: "Password is required",
                validate: {
                  match: (v) => v === watch("password"),
                },
              }}
              render={({ field: { onChange, ...field } }) => (
                <>
                  <Input
                    icon
                    iconPosition="left"
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="off"
                    onChange={(...args) => {
                      onChange(...args);

                      if (isSubmitted) trigger("password");
                    }}
                    {...field}
                  >
                    <input data-testid="confirmPassword-input" />
                    <Icon name="lock" />
                  </Input>

                  <FormFieldError
                    error={
                      errors?.password?.type === "match" || errors?.confirmPassword?.type === "match"
                        ? "Passwords need to match"
                        : errors?.confirmPassword?.message
                    }
                  />
                </>
              )}
            />
          </Form.Field>

          {(isPasswordTouched || isPasswordDirty || isConfirmPasswordTouched || isConfirmPasswordDirty) && (
            <div className="mt3">
              <PasswordRequirements requirementsMetResults={requirementsMetResults} showValidation />
            </div>
          )}
        </>
      )}
      <hr className="mt3" />
      <Form.Field error={!!errors?.twoFA?.message}>
        <label className="twoFA-label mt3">Account Security - 2FA</label>
        <div className="flex flex-center">
          <div className="flex flex-center checkbox-container">
            <FormFieldController
              name="twoFA"
              control={control}
              render={({ field }) => (
                <Checkbox
                  toggle
                  name="twoFA"
                  checked={field.value}
                  onChange={() =>
                    setValue("twoFA", field.value ? false : true, { shouldDirty: true, shouldTouch: true })
                  }
                />
              )}
            />
          </div>
          <p>
            When enabled you will be sent a code to your account email to be used when logging in for additional
            security.
          </p>
        </div>
      </Form.Field>
      <hr className="mt3" />
      <p className="mbhalf macrium-news-offers">
        I'd like Macrium to keep me up to date with news and offers about the software by email:
      </p>
      <Form.Group inline className="mb3">
        <Form.Field error={!!errors?.subscribe?.message}>
          <FormFieldController
            name="subscribe"
            control={control}
            rules={{
              required: subscribeValue === 1 || subscribeValue === 0 || "Notifications needs to be set",
            }}
            render={() => (
              <Checkbox
                radio
                label="Yes"
                name="subscribe"
                checked={subscribeValue === 1}
                onChange={() => {
                  setValue("subscribe", 1, { shouldDirty: true, shouldTouch: true });
                  trigger("subscribe");
                }}
                value={1}
              />
            )}
          />
        </Form.Field>
        <Form.Field error={!!errors?.subscribe?.message}>
          <FormFieldController
            name="subscribe"
            control={control}
            rules={{
              required: subscribeValue === 1 || subscribeValue === 0 || "Notifications needs to be set",
            }}
            render={() => (
              <Checkbox
                radio
                label="No"
                name="subscribe"
                checked={subscribeValue === 0}
                onChange={() => {
                  setValue("subscribe", 0, { shouldDirty: true, shouldTouch: true });
                  trigger("subscribe");
                }}
                value={0}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      {isBusinessTrial ? (
        <ContinueButton loading={loading} />
      ) : (
        <>
          <PrivacyPolicy />
          {type === REGISTER_TYPES.EMAIL && <RegisterButton loading={loading} />}
          {type === REGISTER_TYPES.GOOGLE && <SSOButton type="google" text="Register" fluid />}
          {type === REGISTER_TYPES.MICROSOFT && <SSOButton type="microsoft" text="Register" fluid />}
        </>
      )}
    </Form>
  );
};

HomeForm.proTypes = {
  isBusinessTrial: PropTypes.bool,
  loading: PropTypes.bool,
  isEmailDisabled: PropTypes.bool,
  values: PropTypes.object,
  step: PropTypes.number.isRequired,
  type: PropTypes.oneOf([REGISTER_TYPES.EMAIL, REGISTER_TYPES.GOOGLE, REGISTER_TYPES.MICROSOFT]).isRequired,
  onSave: PropTypes.func.isRequired,
};

HomeForm.defaultProps = {
  isBusinessTrial: false,
  loading: false,
  isEmailDisabled: false,
  values: {},
};

export default HomeForm;
