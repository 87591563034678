import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty, reduce, isUndefined, findIndex, filter } from "lodash";

import { getLicenceInfo, getUnassignedLicenses } from "../../app/api/licences";
import { IDLE, LOADING, FAILED } from "../actionStatuses";
import {
  LICENSES_DESCRIPTIVE_STRING,
  REFLECT_HOME,
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_WORKSTATION,
  REFLECT_SERVER,
  SERVER_PLUS_VM,
  SERVER_PLUS,
  DEPLOYMENT_KIT,
  TECHNICIAN,
  SITEDEPLOY,
  SITE_MANAGER,
  LICENSES_WITH_SUBSCRIPTIONS,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  REFLECT_SERVER_SUBSCRIPTION,
  SERVER_PLUS_SUBSCRIPTION,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  TECHNICIAN_SUBSCRIPTION,
  SITE_MANAGER_SUBSCRIPTION,
} from "../../constants/licenses";

const REDUCER_NAME = "products";

const DEFAULT_LICENSES_FILTER = {
  [REFLECT_HOME]: [],
  [REFLECT_HOME_SUBSCRIPTION]: [],
  [REFLECT_WORKSTATION]: [],
  [REFLECT_WORKSTATION_SUBSCRIPTION]: [],
  [REFLECT_SERVER]: [],
  [REFLECT_SERVER_SUBSCRIPTION]: [],
  [SERVER_PLUS_VM]: [],
  [SERVER_PLUS]: [],
  [SERVER_PLUS_SUBSCRIPTION]: [],
  [DEPLOYMENT_KIT]: [],
  [TECHNICIAN]: [],
  [DEPLOYMENT_KIT_SUBSCRIPTION]: [],
  [TECHNICIAN_SUBSCRIPTION]: [],
  [SITEDEPLOY]: [],
  [SITE_MANAGER]: [],
  [SITE_MANAGER_SUBSCRIPTION]: [],
};

const initialState = {
  status: IDLE,
  data: [],
  unassigned: [], // TO-DO: I think this should be moved to another slice related to licenses in the future
  errorUnassigned: "",
  error: "",
  loaded: false,
  licensesFilter: DEFAULT_LICENSES_FILTER,
};

export const fetchLicenceInfoAsync = createAsyncThunk(
  `${REDUCER_NAME}/fetchLicenceInfoRequest`,
  async (email, thunkAPI) => {
    const response = await getLicenceInfo(email);

    if (!response.success) return thunkAPI.rejectWithValue(response);

    return response;
  }
);

export const fetchUnassignedLicensesAsync = createAsyncThunk(
  "licenses/fetchUnassignedLicenses",
  async (email, thunkAPI) => {
    const response = await getUnassignedLicenses(email);

    if (!response.success) return thunkAPI.rejectWithValue(response);

    return response;
  }
);

export const productsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
    updateFilter: (state, { payload: { key, filter: filterArray } }) => {
      if (isUndefined(key) || isUndefined(filterArray)) return state;

      return {
        ...state,
        licensesFilter: {
          ...state.licensesFilter,
          [key]: reduce(
            filterArray,
            (newFilter, filterStr) => {
              const indexToRemove = findIndex(state.licensesFilter[key], (existingStr) => existingStr === filterStr);
              if (indexToRemove > -1) return filter(newFilter, (existingStr) => existingStr !== filterStr);
              return [...newFilter, filterStr];
            },
            state.licensesFilter[key]
          ),
        },
      };
    },
    clearFilters: (state) => {
      state.licensesFilter = DEFAULT_LICENSES_FILTER;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenceInfoAsync.pending, (state) => {
        if (!state.loaded || !isEmpty(state.error)) {
          state.status = LOADING;
          state.error = "";
        }
      })
      .addCase(fetchLicenceInfoAsync.fulfilled, (state, { payload }) => {
        const summary = reduce(
          get(payload, "data.summary", []),
          (newProducts, product) => {
            if (get(LICENSES_DESCRIPTIVE_STRING, product.description)) {
              newProducts.push(
                product.version >= 10 && LICENSES_WITH_SUBSCRIPTIONS[product.description]
                  ? {
                      ...product,
                      description: LICENSES_WITH_SUBSCRIPTIONS[product.description],
                    }
                  : product
              );
            }

            return newProducts;
          },
          []
        );
        state.status = IDLE;
        state.data = summary;
        state.loaded = true;
        state.error = "";
      })
      .addCase(fetchLicenceInfoAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.loaded = true;
        state.error = payload.message;
      })
      .addCase(fetchUnassignedLicensesAsync.pending, (state) => {
        state.status = LOADING;
        state.errorUnassigned = null;
      })
      .addCase(fetchUnassignedLicensesAsync.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.unassigned = get(payload, "data.summary", []);
      })
      .addCase(fetchUnassignedLicensesAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.errorUnassigned = payload.message;
      });
  },
});

export const { clearState, updateFilter, clearFilters } = productsSlice.actions;

export default productsSlice.reducer;
