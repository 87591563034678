import moment from "moment";
import { isNil } from "lodash";

/**
 * This function takes the expireDate (local time), add/subtracts the bias to calculate the remaining hours to trial expiration
 * @param {object} from - moment object
 * @param {number} expireDate - local time hence the need for the bias
 * @param {number} bias - The bias is the difference, in minutes, between Coordinated Universal Time (UTC) and local time
 * @returns {number} number of hours remaining to "expireDate"
 */
export const getTrialExpiresInHours = (from, expireDate, bias) => {
  const clonedFrom = from.clone(); // clone moment otherwise it will get mutated

  const fromWithBias = isNil(bias) ? clonedFrom : clonedFrom.add(-bias / 60, "hours"); // .add deals with positive and negative numbers

  const diff = moment.unix(expireDate).diff(fromWithBias, "hours", true);

  const expiresInHours = diff;

  return Math.ceil(expiresInHours);
}
