import { EXPIRED, EXPIRED_SUPPORT_TEXT, QTY } from "../../constants/licenses";

const doNotShowTag = ({ tagName, allRenewalSame, allExpired, isEnterprise, isOfflineActivated }) => {
  if (isOfflineActivated) return false;
  if (allRenewalSame && (tagName.startsWith("Ren") || tagName.startsWith("Sup"))) return true;
  if (allExpired && (tagName === EXPIRED || tagName === EXPIRED_SUPPORT_TEXT)) return true;
  if (tagName === QTY && isEnterprise) return true;
  return false;
};
export default doNotShowTag;
