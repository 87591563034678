import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { openInNewTab } from "../../utils/generic";
import { SUBMIT_TICKET } from "../../constants/urls";

const SubmitTicketButton = ({ size }) => {
  return <Button secondary content="Submit A Ticket" size={size} onClick={() => openInNewTab(SUBMIT_TICKET)} />;
};

export default SubmitTicketButton;
SubmitTicketButton.propTypes = {
  size: PropTypes.string,
};
SubmitTicketButton.defaultProps = {
  size: "small",
};
