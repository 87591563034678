import { get, includes } from "lodash";

import { hasUnsavedChanges, isBlockingModalOpen } from "../../selectors/basics";
import { setOpen } from "../../slices/prompt/prompt";

/**
 * This middleware is to check if there are unsaved changes before moving to another page.
 * 
 */
const unsavedChanges = store => next => action => {  
  try {
    if (includes(action.type, "@@router/LOCATION_CHANGE") || includes(action.type, "@@router/CALL_HISTORY_METHOD")) {
      const isBlocking = hasUnsavedChanges(store.getState());
      const isModalOpen = isBlockingModalOpen(store.getState());
      
      const requestedPage = get(action, 'payload.args.0');

      if (isBlocking) {
        return store.dispatch(setOpen({ open: true, requestedPage }));
      } else if (isModalOpen) {
        store.dispatch(setOpen({ open: false }));
      }
    }

    return next(action);
  } catch (err) {
    console.log("Unsaved Changes Middleware Error ", err);

    return next(action);
  }
}

export default unsavedChanges;