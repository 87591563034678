import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Input } from "semantic-ui-react";
import { FormFieldError, MIcon } from "@macrium/shared-components";
import { forEach, isEmpty } from "lodash";

import { DEFAULT_BUSINESS_FIELDS } from "./fields";
import { REGISTER_TYPES } from "../../../constants/auth";
import ProfilingAddress from "../../profiling/ProfilingAddress";
import PhoneInput from "../../generic/Inputs/PhoneInput";
import { RegisterButton } from "./Buttons";
import SSOButton from "../SSOButtons/SSOButton";
import FormFieldController from "../../generic/FormFieldController";
import { PHONE_MAX_LENGTH, JOB_MAX_LENGTH, COMPANY_MAX_LENGTH } from "../../../constants/forms/validation";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

const BusinessForm = ({ type, loading, onSave, values, goBack }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: { ...DEFAULT_BUSINESS_FIELDS, ...values },
  });

  React.useEffect(() => {
    if (isEmpty(values)) return;

    forEach(DEFAULT_BUSINESS_FIELDS, (defaultValue, key) => {
      // dirty/touch fields in case values are coming from a parent component
      setValue(key, values[key] || defaultValue, { shouldDirty: true, shouldTouch: true });
    });
  }, [setValue, values]);

  const onSubmit = (data) => onSave(data);

  return (
    <>
      <div className="register-container__back_first_step" onClick={() => goBack(getValues())}>
        <MIcon name="chevron left" color="cGrey3" />
        <span className="ml1">Back</span>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field error={!!errors?.job}>
          <label>Job Role</label>
          <FormFieldController
            name="job"
            control={control}
            rules={{ required: "Job Role is required", ...JOB_MAX_LENGTH }}
            render={({ field }) => (
              <>
                <Input icon iconPosition="left" placeholder="Job Role" autoFocus {...field}>
                  <input />
                  <MIcon name="job role" />
                </Input>

                <FormFieldError error={errors?.job?.message} />
              </>
            )}
          />
        </Form.Field>

        <Form.Field error={!!errors?.company}>
          <label>Company</label>
          <FormFieldController
            name="company"
            control={control}
            rules={{ required: "Company is required", ...COMPANY_MAX_LENGTH }}
            render={({ field }) => (
              <>
                <Input icon iconPosition="left" placeholder="Company" {...field}>
                  <input />
                  <MIcon name="company" />
                </Input>

                <FormFieldError error={errors?.company?.message} />
              </>
            )}
          />
        </Form.Field>

        <Form.Field error={!!errors?.phone}>
          <label>Phone Number (Optional)</label>
          <FormFieldController
            name="phone"
            control={control}
            rules={PHONE_MAX_LENGTH}
            render={({ field }) => <PhoneInput placeholder="" {...field} showIcon />}
          />

          <FormFieldError error={errors?.phone?.message} />
        </Form.Field>

        <ProfilingAddress
          addressLine1={watch("addressLine1")}
          addressLine2={watch("addressLine2")}
          city={watch("city")}
          stateProvince={watch("stateProvince")}
          postalZipCode={watch("postalZipCode")}
          country={watch("country")}
          setValue={setValue}
          errors={errors}
          control={control}
          autoFocus={false}
        />
        <PrivacyPolicy />
        {type === REGISTER_TYPES.EMAIL && <RegisterButton loading={loading} className="mt3" />}
        {type === REGISTER_TYPES.GOOGLE && <SSOButton type="google" text="Register" fluid className="mt3" />}
        {type === REGISTER_TYPES.MICROSOFT && <SSOButton type="microsoft" text="Register" fluid className="mt3" />}
      </Form>
    </>
  );
};

BusinessForm.proTypes = {
  loading: PropTypes.bool,
  values: PropTypes.object,
  type: PropTypes.oneOf([REGISTER_TYPES.EMAIL, REGISTER_TYPES.GOOGLE, REGISTER_TYPES.MICROSOFT]).isRequired,
  onSave: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

BusinessForm.defaultProps = {
  loading: false,
  values: {},
};

export default BusinessForm;
