import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import { noop } from 'lodash';
import EditInput from "../../generic/Inputs/EditInput";

const PasswordFormField = ({
  field,
  placeholder,
  value,
  onChange,
  className,
  error,
  autoFocus,
  onKeyDown,
}) => (
  <Form.Field error={!!error}>
    <EditInput
      onKeyDown={onKeyDown}
      className={className}
      fluid
      icon="lock"
      iconPosition="left"
      placeholder={placeholder}
      value={value || ""}
      type="password"
      onChange={(e) => onChange(e.target.value, field)}
      autoFocus={autoFocus}
      autoComplete="false"
    />
    <FormFieldError error={error} />
  </Form.Field>
);

PasswordFormField.propTypes = {
  field: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func
};

PasswordFormField.defaultProps = {
  field: "",
  placeholder: "",
  value: "",
  className: "",
  onKeyDown: noop
};

export default PasswordFormField;
