import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import CookieWarning from "./components/generic/CookieWarning/CookieWarning.jsx";
import ROUTES from "./app/routes";
import { can2FA } from "./selectors/auth";
import {
  isLoggedIn,
  getPreviousRoute,
  hasRegistered,
  needsActivationAfterLogin,
  getPrivacyPolicy,
  isLoadingDetails,
} from "./selectors/basics";
import PortalSideMenu from "./components/sideMenu.jsx";
import PrivacyPolicyModal from "./components/generic/PrivacyPolicyModal/PrivacyPolicyModal.jsx";
import { getLocation } from "connected-react-router";
import { addCookieData } from "./slices/offlineActivation/offlineActivation.js";
import { OFFLINE_ACTIVATION_PARAM } from "./constants/params.js";

// A wrapper for <Route> that redirects to the login
// screen if user is not yet authenticated.
export const PrivateRoute = ({ render: Component, ...rest }) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedIn);
  const { query } = useSelector(getLocation);
  const { key, activationCode, action } = query;
  if ((key && activationCode) || action?.toLowerCase() === OFFLINE_ACTIVATION_PARAM) {
    dispatch(addCookieData({ key, activationCode, action }));
  }

  const hasAcceptedPrivacyPolicy = useSelector(getPrivacyPolicy);

  const isLoading = useSelector(isLoadingDetails);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          <>
            {hasAcceptedPrivacyPolicy || isLoading ? (
              <PortalSideMenu>
                <Component />
                <CookieWarning />
              </PortalSideMenu>
            ) : (
              <PrivacyPolicyModal hasAcceptedPrivacyPolicy={hasAcceptedPrivacyPolicy} isLoading={isLoading} />
            )}
          </>
        ) : (
          <>
            <Redirect
              to={{
                pathname: ROUTES.LOGIN,
                state: { from: location },
              }}
            />
          </>
        )
      }
    />
  );
};

// Redirect the user if they are logged in and try to go to the Login/Register page.
// It also redirects the user after logging in.
export const PreventLoggedInRoute = ({ render: Component, ...rest }) => {
  const loggedIn = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const { query } = useSelector(getLocation);
  const { key, activationCode } = query;
  if (key && activationCode) {
    dispatch(addCookieData({ key, activationCode }));
  }
  const previousRoute = useSelector(getPreviousRoute);
  const canShow2FAPage = useSelector(can2FA);
  const canShowAfterRegisterPage = useSelector(hasRegistered);
  const needsActivation = useSelector(needsActivationAfterLogin);

  const currentRoute = rest.location.pathname;

  const prevent2FAPage = React.useMemo(
    () => !loggedIn && currentRoute === ROUTES.TFA && !canShow2FAPage,
    [loggedIn, currentRoute, canShow2FAPage]
  );
  const preventAfterRegisterPage = React.useMemo(
    () => !loggedIn && currentRoute === ROUTES.AFTER_REGISTER && !canShowAfterRegisterPage && !needsActivation,
    [loggedIn, currentRoute, canShowAfterRegisterPage, needsActivation]
  );

  return (
    <Route
      {...rest}
      render={() => (
        <React.Fragment>
          {!loggedIn && (prevent2FAPage || preventAfterRegisterPage) && <Redirect to={{ pathname: ROUTES.LOGIN }} />}

          {loggedIn && <Redirect to={{ pathname: previousRoute || ROUTES.OVERVIEW }} />}

          {!loggedIn && <Component />}
        </React.Fragment>
      )}
    />
  );
};
