import ProgressBar from "../ProgressBar/ProgressBar";
import "./CardProgressBox.styles.scss";
import PropTypes from "prop-types";
import { finalStage } from "../progressStageData";
import StepController from "../StepController/StepController";
import ProgressStage from "./ProgressStage/ProgressStage";
const getCurrentProgress = (stageProgress) => {
  const completedSteps = stageProgress.filter((stage) => stage.isComplete);
  return Math.round((completedSteps.length / stageProgress.length) * 100);
};
const CardProgressBox = ({ stageProgress, setCurrentStage, currentStage }) => {
  const currentProgress = getCurrentProgress(stageProgress);
  const extraStageNumber = stageProgress.length + 1;
  return (
    <div className="progressBoxContainer">
      <div>
        <div className="progressBarContainer">
          <ProgressBar progress={currentProgress} alternate={false} />
        </div>
        <div className="stagesContainer">
          {stageProgress.map((stage) => (
            <ProgressStage
              key={stage.number}
              stage={stage}
              setCurrentStage={setCurrentStage}
              isCurrentStage={currentStage?.number === stage?.number}
            />
          ))}
          {currentProgress === 100 && (
            <ProgressStage
              key={extraStageNumber}
              stage={{ ...finalStage, number: extraStageNumber }}
              setCurrentStage={setCurrentStage}
              isCurrentStage={currentStage?.number === extraStageNumber}
            />
          )}
        </div>
      </div>
      <StepController
        showOnDesktop={false}
        currentStage={currentStage?.number}
        totalStages={currentProgress === 100 ? stageProgress.length + 1 : stageProgress.length}
        isProgress={true}
        onLeftClick={() => setCurrentStage(stageProgress.find((stage) => currentStage.number - 1 === stage.number))}
        onRightClick={() =>
          setCurrentStage(
            stageProgress?.find((stage) => currentStage.number + 1 === stage.number) || {
              ...finalStage,
              number: stageProgress.length + 1,
            }
          )
        }
      />
    </div>
  );
};

export default CardProgressBox;

CardProgressBox.propTypes = {
  stageProgress: PropTypes.array,
  setCurrentStage: PropTypes.func.isRequired,
  isCurrentStage: PropTypes.bool,
};
CardProgressBox.defaultProps = {
  isCurrentStage: false,
  stageProgress: [],
};
