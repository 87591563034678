import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";

const NumberCircle = ({ stage }) => {
  const { number, isActive, isComplete } = stage;
  const findStyling = () => {
    if (isActive) return "number-active";
    if (isComplete) return "number-complete";
    return "number-neutral";
  };
  return (
    <div className={`circleNumber ${findStyling()}`}>
      {isComplete ? <MIcon name="check circle invert" size="large" color="blue" /> : <span>{number}</span>}
    </div>
  );
};
export default NumberCircle;

NumberCircle.propTypes = {
  stage: PropTypes.shape({
    isComplete: PropTypes.bool,
    isActive: PropTypes.bool,
    url: PropTypes.string,
    videoStages: PropTypes.array,
    number: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};
