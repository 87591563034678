import { replace, includes, split } from "lodash";

import {
  REFLECT_HOME,
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_SERVER,
  REFLECT_WORKSTATION,
  SERVER_PLUS,
  SERVER_PLUS_VM,
  DEPLOYMENT_KIT,
  TECHNICIAN,
  SITEDEPLOY,
  SITE_MANAGER,
  LICENSES_DESCRIPTIVE_STRING,
  SERVER_PLUS_SUBSCRIPTION,
  REFLECT_SERVER_SUBSCRIPTION,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  TECHNICIAN_SUBSCRIPTION,
  SITE_MANAGER_SUBSCRIPTION,
} from "./licenses";

export const SUBSCRIPTION = "Subscription";
export const PERPETUAL = "Perpetual";
export const ANNUAL_PLAN = "Annual Plan";
// For TRIALS, the `Subscription` field is empty

const KB_8 = "KNOW80";
const KB_7 = "KNOW72";
const KB_6 = "KNOW";

const VERSION_TO_KB_VERSION = {
  6: KB_6,
  7: KB_7,
  8: KB_8,
  10: KB_8,
};

const PRODUCTS_KB = {
  [REFLECT_HOME]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Quick+Start",
  [REFLECT_HOME_SUBSCRIPTION]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Quick+Start",
  [REFLECT_SERVER]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Quick+Start",
  [REFLECT_SERVER_SUBSCRIPTION]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Quick+Start",
  [REFLECT_WORKSTATION]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Quick+Start",
  [REFLECT_WORKSTATION_SUBSCRIPTION]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Quick+Start",

  [SERVER_PLUS]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Server+Plus",
  [SERVER_PLUS_SUBSCRIPTION]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Server+Plus",
  [SERVER_PLUS_VM]: "https://knowledgebase.macrium.com/display/KNOW80/Macrium+Reflect+Server+Plus",
  [DEPLOYMENT_KIT]:
    "https://knowledgebase.macrium.com/display/KNOW80/Windows+Deployment+Services+PXE+Boot+Configuration",

  [TECHNICIAN]: "https://knowledgebase.macrium.com/display/KNOW80/Technicians+portable+application+support",
  [DEPLOYMENT_KIT_SUBSCRIPTION]:
    "https://knowledgebase.macrium.com/display/KNOW80/Windows+Deployment+Services+PXE+Boot+Configuration",

  [TECHNICIAN_SUBSCRIPTION]:
    "https://knowledgebase.macrium.com/display/KNOW80/Technicians+portable+application+support",
  [SITEDEPLOY]: "https://knowledgebase.macrium.com/display/MSD",
  [SITE_MANAGER]: "https://knowledgebase.macrium.com/display/MSM8/MSM+User+Guide",
};

/**
 * Takes the KB for Version 8 and returns the appropriate version
 * @param {string} description - name of the product
 * @param {version} version - version of the product
 * @returns {string} knowledgeBase url specific to the version
 */
export const getProductKBUrl = (description, version) => {
  if (description === SITE_MANAGER || description === SITE_MANAGER_SUBSCRIPTION) {
    switch (version) {
      case "6":
        return "https://knowledgebase.macrium.com/display/CMC";
      case "7":
        return "https://knowledgebase.macrium.com/display/MSM";
      default:
        return PRODUCTS_KB[SITE_MANAGER];
    }
  }

  if (description === DEPLOYMENT_KIT) {
    switch (version) {
      case "6":
        return "https://knowledgebase.macrium.com/display/KNOW/PXE+Deployment";
      case "7":
        return "https://knowledgebase.macrium.com/display/KNOW72/PXE+Deployment";
      default:
        return PRODUCTS_KB[DEPLOYMENT_KIT];
    }
  }

  if (description === SITEDEPLOY) return PRODUCTS_KB[description];

  if (!version) return PRODUCTS_KB[description];

  let majorVersion = version;

  // if full version included, get major version (Trials, for example)
  if (includes(version, ".")) majorVersion = split(version, ".")[0];

  return replace(PRODUCTS_KB[description], KB_8, VERSION_TO_KB_VERSION[majorVersion]);
};

export const REFLECT_HOME_DESCRIPTION =
  "The complete backup solution for personal use. Protect documents, data and operating systems using advanced disk imaging technology.";
export const SERVER_DESCRIPTION =
  "This fast and reliable solution once again sets the standard for image based server backup solutions.";
export const SERVER_PLUS_DESCRIPTION =
  "Fully featured and sophisticated tool for backing up the most critical of servers running apps such as Microsoft Exchange email, and SQL databases.";
export const DK_TECH_DESCRIPTION =
  "IT maintenance and support engineers can put the power of Macrium Reflect's backup engine in their pocket - on a USB stick.";
export const REFLECT_WORKSTATION_DESCRIPTION =
  "The complete backup solution for commercial use. Protect documents, data and operating systems using advanced disk imaging technology.";

export const PRODUCTS_DESCRIPTION = {
  [REFLECT_HOME]: REFLECT_HOME_DESCRIPTION,
  [REFLECT_HOME_SUBSCRIPTION]: REFLECT_HOME_DESCRIPTION,

  [REFLECT_SERVER]: SERVER_DESCRIPTION,
  [REFLECT_SERVER_SUBSCRIPTION]: SERVER_DESCRIPTION,

  [REFLECT_WORKSTATION]: REFLECT_WORKSTATION_DESCRIPTION,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: REFLECT_WORKSTATION_DESCRIPTION,

  [SERVER_PLUS]: SERVER_PLUS_DESCRIPTION,
  [SERVER_PLUS_SUBSCRIPTION]: SERVER_PLUS_DESCRIPTION,
  [SERVER_PLUS_VM]: SERVER_PLUS_DESCRIPTION,

  [DEPLOYMENT_KIT]: DK_TECH_DESCRIPTION,
  [TECHNICIAN]: DK_TECH_DESCRIPTION,
  [DEPLOYMENT_KIT_SUBSCRIPTION]: DK_TECH_DESCRIPTION,
  [TECHNICIAN_SUBSCRIPTION]: DK_TECH_DESCRIPTION,
  [SITEDEPLOY]:
    "Controls and restores devices remotely, from PCs and thin clients to laptops and servers. Vital for organizations now that hybrid working is here to stay.",
  [SITE_MANAGER]:
    "Macrium Site Manager allows you to manage multiple instances of Macrium Reflect in a single, easy to use interface.",
  [SITE_MANAGER_SUBSCRIPTION]:
    "Macrium Site Manager allows you to manage multiple instances of Macrium Reflect in a single, easy to use interface.",
};

const HOME_VIDEO = "https://www.youtube.com/embed/xOFTJs8cgd4";
const WORKSTATION_VIDEO = "https://www.youtube.com/embed/uSmFdoa4oLo";
const REFLECT_SERVER_VIDEO = "https://www.youtube.com/embed/_2cBEMICcQI";
const SERVER_PLUS_VIDEO = REFLECT_SERVER_VIDEO;

export const PRODUCTS_VIDEO_URLS = {
  [REFLECT_HOME]: HOME_VIDEO,
  [REFLECT_HOME_SUBSCRIPTION]: HOME_VIDEO,

  [REFLECT_WORKSTATION]: WORKSTATION_VIDEO,
  [REFLECT_WORKSTATION_SUBSCRIPTION]: WORKSTATION_VIDEO,
  [REFLECT_SERVER]: REFLECT_SERVER_VIDEO,
  [REFLECT_SERVER_SUBSCRIPTION]: REFLECT_SERVER_VIDEO,
  [SERVER_PLUS]: SERVER_PLUS_VIDEO,
  [SERVER_PLUS_SUBSCRIPTION]: SERVER_PLUS_VIDEO,
  [TECHNICIAN]: "https://www.youtube.com/embed/61hB6McavCs",
  [TECHNICIAN_SUBSCRIPTION]: "https://www.youtube.com/embed/61hB6McavCs",
};

export const PRODUCTS_EDITIONS = {
  [REFLECT_HOME]: 6,
  [REFLECT_HOME_SUBSCRIPTION]: 6,
  [REFLECT_SERVER]: 8,
  [REFLECT_WORKSTATION]: 7,

  [SERVER_PLUS]: 9,
  [SERVER_PLUS_VM]: 9,
  [DEPLOYMENT_KIT]: 12,

  [TECHNICIAN]: 10,
  [SITEDEPLOY]: 11,
  [SITE_MANAGER]: 1,
};

export const EDITION_TO_PRODUCT_DESCRIPTION = {
  6: LICENSES_DESCRIPTIVE_STRING[REFLECT_HOME],
  8: LICENSES_DESCRIPTIVE_STRING[REFLECT_SERVER],
  7: LICENSES_DESCRIPTIVE_STRING[REFLECT_WORKSTATION],

  9: LICENSES_DESCRIPTIVE_STRING[SERVER_PLUS],

  10: LICENSES_DESCRIPTIVE_STRING[TECHNICIAN],
  1: LICENSES_DESCRIPTIVE_STRING[SITE_MANAGER],
  11: LICENSES_DESCRIPTIVE_STRING[SITEDEPLOY],
  12: LICENSES_DESCRIPTIVE_STRING[DEPLOYMENT_KIT],
};

export const PRODUCTS_BUY_NOW = {
  [REFLECT_HOME]: "https://www.macrium.com/products/home",
  [REFLECT_HOME_SUBSCRIPTION]: "https://www.macrium.com/products/home",
  [REFLECT_SERVER]: "https://www.macrium.com/products/business/standalone/server",
  [REFLECT_SERVER_SUBSCRIPTION]: "https://www.macrium.com/products/business/standalone/server",
  [REFLECT_WORKSTATION]: "https://www.macrium.com/products/business/standalone/workstation",
  [REFLECT_WORKSTATION_SUBSCRIPTION]: "https://www.macrium.com/products/business/standalone/workstation",

  [SERVER_PLUS]: "https://www.macrium.com/products/business/standalone/server-plus",
  [SERVER_PLUS_SUBSCRIPTION]: "https://www.macrium.com/products/business/standalone/server-plus",
  [SERVER_PLUS_VM]: "https://www.macrium.com/products/business/standalone/server-plus",

  [DEPLOYMENT_KIT]: "https://www.macrium.com/products/business/technicians/deployment-kit",
  [TECHNICIAN]: "https://www.macrium.com/products/business/technicians/enterprise",
  [DEPLOYMENT_KIT_SUBSCRIPTION]: "https://www.macrium.com/products/business/technicians/deployment-kit",
  [TECHNICIAN_SUBSCRIPTION]: "https://www.macrium.com/products/business/technicians/enterprise",

  [SITEDEPLOY]: "https://www.macrium.com/products/business/technicians/sitedeploy-imaging-deployment-software",
  [SITE_MANAGER]: "https://www.macrium.com/site-manager",
};

export const LEGACY_RELEASE_NOTES = {
  6: {
    [REFLECT_HOME]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",
    [REFLECT_SERVER]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",
    [REFLECT_WORKSTATION]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",

    [SERVER_PLUS]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",
    [SERVER_PLUS_VM]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",

    [DEPLOYMENT_KIT]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",
    [TECHNICIAN]: "https://updates.macrium.com/reflect/v6/v6.3.1865/details6.3.1865.htm",
    // SiteDeploy didn't exist yet
    [SITE_MANAGER]: "https://updates.macrium.com/reflect/v6/v6.3.1846/detailsMD6.3.1846.md",
  },
  7: {
    [REFLECT_HOME]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",
    [REFLECT_SERVER]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",
    [REFLECT_WORKSTATION]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",

    [SERVER_PLUS]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",
    [SERVER_PLUS_VM]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",

    [DEPLOYMENT_KIT]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",
    [TECHNICIAN]: "https://updates.macrium.com/reflect/v7/v7.3.6391/details7.3.6391.htm",
    // SiteDeploy didn't exist yet
    [SITE_MANAGER]: "https://updates.macrium.com/reflect/v7/v7.3.5920/detailsMD7.3.5920.md",
  },
};
export const SITE_MANAGER_V8_x86_DOWNLOAD_URL =
  "https://cloud-eu.macrium.com/reflect/v8/v8.0.7327/macrium_remote_mgnt_x86.exe";

export const LEGACY_DOWNLOAD_URLS = {
  6: {
    [REFLECT_HOME]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_home_setup_x64.exe",
    [REFLECT_SERVER]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_server_setup_x64.exe",
    [REFLECT_WORKSTATION]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_wkstn_setup_x64.exe",

    [SERVER_PLUS]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_server_plus_setup_x64.exe",
    [SERVER_PLUS_VM]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_server_plus_setup_x64.exe",

    [DEPLOYMENT_KIT]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_server_setup_x64.exe",
    [TECHNICIAN]: "https://download.macrium.com/reflect/v6/v6.3.1865/reflect_server_setup_x64.exe",
    // SiteDeploy didn't exist yet
    [SITE_MANAGER]: "https://cloud-eu.macrium.com/reflect/v6/v6.3.1846/macrium_remote_mgnt_x64.exe",
  },
  7: {
    [REFLECT_HOME]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_home_setup_x64.exe",
    [REFLECT_SERVER]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_server_setup_x64.exe",
    [REFLECT_WORKSTATION]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_wkstn_setup_x64.exe",

    [SERVER_PLUS]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_server_plus_setup_x64.exe",
    [SERVER_PLUS_VM]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_server_plus_setup_x64.exe",

    [DEPLOYMENT_KIT]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_server_setup_x64.exe",
    [TECHNICIAN]: "https://download.macrium.com/reflect/v7/v7.3.6391/reflect_server_setup_x64.exe",
    // SiteDeploy didn't exist yet
    [SITE_MANAGER]: "https://cloud-eu.macrium.com/reflect/v7/v7.3.5920/macrium_remote_mgnt_x64.exe",
  },
};

export const ARCH64 = "x64";
export const ARCH86 = "x86";
export const LEGACY_ARCH86_SM8 = "SM8_x86";

export const MACHINE_DEFAULT_NAME = {
  [SITE_MANAGER]: "Site Manager Server",
  [SITEDEPLOY]: "SiteDeploy Server",
  [SERVER_PLUS]: "Server",
  [SERVER_PLUS_VM]: "Server",
  [SERVER_PLUS_SUBSCRIPTION]: "Server",
  [REFLECT_SERVER]: "Server",
  [TECHNICIAN]: "Technician",
};
