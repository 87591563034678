import NumberCircle from "../NumberCircle/NumberCircle";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";

const ProgressStage = ({ stage, setCurrentStage, isCurrentStage }) => (
  <div
    onClick={() => setCurrentStage(stage)}
    className={`flex flex-center justify-between stageButton ${isCurrentStage ? "stageButton-current" : ""}`}
  >
    <div className="flex flex-center pr1">
      <NumberCircle stage={stage} />
      <div className={`ml2 ${stage.isComplete ? "strikeThrough" : "normalText"} ${isCurrentStage ? "bold" : ""}`}>
        {stage.title}
      </div>
    </div>
    {isCurrentStage && <MIcon name="chevron right" size="small" color="grey" />}
  </div>
);
export default ProgressStage;

ProgressStage.propTypes = {
  stage: PropTypes.shape({
    isComplete: PropTypes.bool,
    isActive: PropTypes.bool,
    url: PropTypes.string,
    videoStages: PropTypes.array,
    number: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  setCurrentStage: PropTypes.func.isRequired,
  isCurrentStage: PropTypes.bool,
};
ProgressStage.defaultProps = {
  isCurrentStage: false,
};
