import axios from "axios";

import CONFIG from "../../app/config";
import { store } from "../../app/store";
import { getAuthToken } from "../../selectors/basics";

export const doRequest = (method) => async (url, data, params) => {
  try {
    const authToken = getAuthToken(store.getState());

    const config = {
      method,
      url: `${CONFIG.REACT_APP_BACK_END_URL}${url}`,
    };

    if (authToken) {
      config.headers = {
        Authorization: `bearer ${authToken}`,
      };
    }

    if (data) config.data = data;
    if (params) config.params = params;

    const response = await axios(config);

    if (!response.data.success) {
      console.error("Error response from the server: ", response.data);
    } else {
      console.info("Response from the server: ", response);
    }

    return response.data || {};
  } catch (error) {
    console.error("Error on requester api: ", error);

    const status = error?.response?.status;

    const tooManyRequests = status === 429;

    const tooManyRequestsError = "Too Many Requests. Try again soon.";

    return {
      success: false,
      status,
      message: tooManyRequests ? tooManyRequestsError : "Something went wrong.",
      data: {},
    };
  }
};

export const doGetRequest = doRequest("get");
export const doPostRequest = doRequest("post");
