import { Controller } from "react-hook-form";
import PropTypes from "prop-types";

import { DEFAULT_MAX_LENGTH } from "../../constants/forms/validation";

const FormFieldController = ({ name, control, render, rules, enableDefaultMaxLength, ...props }) => {
  let updatedRules = { ...rules };

  if (enableDefaultMaxLength) {
    updatedRules = { ...DEFAULT_MAX_LENGTH, ...updatedRules };
  }

  return (
    <Controller
      name={name}
      control={control}
      render={render}
      rules={updatedRules} // All fields should have a max length validation by default
      {...props}
    />
  );
};

FormFieldController.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  render: PropTypes.func.isRequired,
  rules: PropTypes.object,
  enableDefaultMaxLength: PropTypes.bool,
};

FormFieldController.defaultProps = {
  rules: {},
  enableDefaultMaxLength: true,
};

export default FormFieldController;
