import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";

const OfflineStepThree = ({ handleFinish, handleStartAgain, licenceKey, showStartAgain }) => {
  return (
    <div className="flex justify-between flex-column offline-step-container">
      <div>
        <div className="offline-step-header">Step 3</div>
        <div className="flex offline-step-content flex-column flex-center">
          <div>License key {licenceKey ? `- ${licenceKey} -` : ""} was successfully offline activated.</div>
          <MIcon name="check circle" className="mt2" color="green" size="massive" />
        </div>
      </div>
      <div className="flex offline-step-button-container">
        <Button primary content="Finish" onClick={handleFinish} />
        {showStartAgain && <Button secondary content="Activate Another License?" onClick={handleStartAgain} />}
      </div>
    </div>
  );
};

OfflineStepThree.propTypes = {
  handleFinish: PropTypes.func.isRequired,
  handleStartAgain: PropTypes.func.isRequired,
  showStartAgain: PropTypes.bool.isRequired,
  licenceKey: PropTypes.string.isRequired,
};
export default OfflineStepThree;
