import { createSelector } from "reselect";
import { map, filter, find, reduce, keyBy, includes } from "lodash";

import { getProductsRange } from "./basics";
import { getLicenseGroups } from "./licenses";
import { REFLECT_HOME, REFLECT_HOME_SUBSCRIPTION } from "../constants/licenses";

// Prepare products range array to facilitate other selectors
export const getComputedProductsRange = createSelector(
  [getProductsRange],
  (productsRange) => {
    // Merge Reflect Home and Reflect Home Subscription
    const mergedProductsRange = reduce(productsRange, (res, prod) => {
      if (prod.description === REFLECT_HOME) return res;

      if (prod.description === REFLECT_HOME_SUBSCRIPTION) {
        res.push({ ...prod, description: REFLECT_HOME })
      } else {
        res.push(prod);
      }

      return res;
    }, []);

    return map(mergedProductsRange, ({ description, releaseNotesURL, majorVersion, minorVersion, build, ...prod }) => {
      const version = `${majorVersion}.${minorVersion}.${build}`;
  
      return {
      version,
      releaseNotesUrl: releaseNotesURL,
      description,
        ...prod
      };
    });;
  }
);

// Create an object "[Description]: ReleaseNotes" to easily get the Release Notes URL for any product (trials or not)
export const getReleaseNotesPerProduct = createSelector(
  [getComputedProductsRange],
  (productRange) => reduce(productRange, (res, { description, releaseNotesUrl }) => {
    res[description] = releaseNotesUrl;
    res[`${description} Subscription`] = releaseNotesUrl;

    return res;
  }, {})
);

const findInOwnedProduct = (ownedProducts, description) => find(ownedProducts, ({ description: ownedProductDescription }) => includes(ownedProductDescription, description));

// Filter owned products from product range
export const getFilteredProductsRange = createSelector(
  [getComputedProductsRange, getLicenseGroups],
  (productsRange, ownedProducts) => filter(productsRange, ({ description }) => !findInOwnedProduct(ownedProducts, description))
);

// There is an issue with Upgraded/Downgraded keys
// For example, the license is a V7 but has been upgraded.
// Because it's a V7, no Download link is returned from the backend.
// To simply things for now (until most of the logic has been moved into the backend),
// This should be the source of truth for the download links (V8).
export const getDownloadURLsForV8 = createSelector(
  [getProductsRange], (productsRange) => keyBy(productsRange, 'description')
)
