import { useEffect, useRef, useState } from "react";
import { MIcon } from "@macrium/shared-components";
import "./TypeAhead.styles.scss";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

const Option = ({ option, onClick, isSelected }) => (
  <li
    className={`option-container ${option.disabled ? "disabled-option" : ""}`}
    onClick={option.disabled ? () => {} : () => onClick(option.id)}
    onKeyDown={(e) => {
      if (e.key === "Enter") onClick(option.id);
    }}
    disabled={option.disabled}
    tabIndex={option.disabled ? -1 : 0}
  >
    <div className="flex flex-center justify-between">
      <div style={{ fontSize: "14px" }}>{option.title}</div>
      {isSelected && <MIcon name="check circle" color="cLogoLightBlue" />}
    </div>
    <div className="flex flex-center justify-between">
      {option.subHeading && <div className="small-gray-text">{option.subHeading}</div>}
      {option.description && <div className="small-gray-text">{option.description}</div>}
    </div>
  </li>
);

const DropDownMenu = ({ options, onClick, selectedOptions }) => {
  return (
    <ul className="typeahead-dropdown">
      {options.map((option) => (
        <Option key={option.id} option={option} onClick={onClick} isSelected={selectedOptions.includes(option.id)} />
      ))}
    </ul>
  );
};

const TypeAhead = ({ options, onSelect, selectedOptions, showChevron }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchValue, setSearchValue] = useState("");
  const typeaheadRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (typeaheadRef.current && !typeaheadRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (searchValue) {
      return setFilteredOptions(
        options.filter(
          (option) =>
            option.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            option?.description?.toLowerCase()?.includes(searchValue.toLowerCase())
        )
      );
    }
    return setFilteredOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const onClick = (id) => {
    onSelect(id);
    setSearchValue("");
    setMenuOpen(false);
  };
  return (
    <div className="relative mainTypeAhead" ref={typeaheadRef}>
      <div className="relative">
        <Input
          fluid
          type="text"
          className="ui right icon input"
          placeholder="Search by License Key..."
          value={menuOpen ? searchValue : selectedOptions[0]}
          onChange={(e) => setSearchValue(e.target.value)}
          onFocus={menuOpen ? () => {} : () => setMenuOpen(!menuOpen)}
          onClick={menuOpen ? () => {} : () => setMenuOpen(!menuOpen)}
        />
        {showChevron && (
          <div
            style={{
              pointerEvents: "none",
            }}
            className="chevronDown"
          >
            <MIcon name="chevron down" color="grey" size="small" />
          </div>
        )}
      </div>
      {menuOpen && <DropDownMenu options={filteredOptions} onClick={onClick} selectedOptions={selectedOptions} />}
    </div>
  );
};

export default TypeAhead;

TypeAhead.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subHeading: PropTypes.string,
      description: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  showChevron: PropTypes.bool,
};
TypeAhead.defaultProps = {
  showChevron: false,
};
