import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";
import "./StepController.styles.scss";

const StepController = ({ totalStages, currentStage, isProgress, onRightClick, onLeftClick, showOnDesktop }) => {
  const disableLeftButton = currentStage === 1;
  const disableRightButton = currentStage === totalStages;
  return (
    <div
      className={`${isProgress ? "" : "darkBackGround mb2"} ${
        showOnDesktop ? "stageChangeContainer" : "mobileStagesContainer"
      }`}
    >
      <div
        onClick={disableLeftButton ? null : onLeftClick}
        className={disableLeftButton ? "disabledButton" : "notDisabledButton"}
        data-testid="left-button"
      >
        <MIcon name="chevron left" color="grey" size="small" />{" "}
      </div>
      <div>
        <span className="highlightPart">
          {isProgress ? "Part" : "Step"} {currentStage}
        </span>{" "}
        <span>of {totalStages}</span>
      </div>
      <div
        data-testid="right-button"
        className={disableRightButton ? "disabledButton" : "notDisabledButton"}
        onClick={disableRightButton ? null : onRightClick}
      >
        <MIcon name="chevron right" color="grey" size="small" />
      </div>
    </div>
  );
};

export default StepController;

StepController.propTypes = {
  totalStages: PropTypes.number.isRequired,
  currentStage: PropTypes.number.isRequired,
  isProgress: PropTypes.bool,
  onRightClick: PropTypes.func.isRequired,
  onLeftClick: PropTypes.func.isRequired,
  showOnDesktop: PropTypes.bool,
};
StepController.defaultProps = {
  showOnDesktop: true,
  isProgress: true,
};
