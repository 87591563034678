import React from "react";
import { isEmpty, replace } from "lodash";
import VALIDATOR from "validator";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Icon, Button, Checkbox, Divider } from "semantic-ui-react";
import { Link, Preloader } from "@macrium/shared-components";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

import { getRememberEmail, getRememberCheckBox } from "../../utils/auth";
import ROUTES from "../../app/routes";
import { PARTNER_PORTAL } from "../../constants/urls";
import {
  setFormFieldValue,
  loginAndThenRedirectAsync,
  googleLoginAndThenRedirectAsync,
  microsoftLoginAndThenRedirectAsync,
} from "../../slices/auth/login";
import { isLoadingLogin, getLoginForm } from "../../selectors/basics";
import { FormFieldError } from "@macrium/shared-components";
import GoogleButton from "./SSOButtons/Google.jsx";
import MicrosoftButton from "./SSOButtons/Microsoft.jsx";
import SSOContainer from "./SSOButtons/SSOContainer.jsx";
import * as TOAST_TYPES from "../../constants/toast";

const EMAIL = "email";
const PASSWORD = "password";
const REMEMBERME = "rememberMe";

const Login = () => {
  const [errors, setErrors] = React.useState({});

  const { email, password, rememberMe } = useSelector(getLoginForm);
  const loading = useSelector(isLoadingLogin);

  const dispatch = useDispatch();
  const goTo = (value) => dispatch(push(value));

  const setFormField = React.useCallback((field, value) => dispatch(setFormFieldValue({ field, value })), [dispatch]);

  React.useEffect(() => {
    const rememberEmail = getRememberEmail();
    const rememberCheckBox = getRememberCheckBox();
    if (rememberEmail) {
      setFormField(EMAIL, rememberEmail);
    }
    setFormField(REMEMBERME, rememberCheckBox === "true" ? true : false);
  }, [dispatch, setFormField]);

  const onSubmit = () => {
    if (loading) return;

    const errors = {};

    const validEmail = VALIDATOR.isEmail(email);
    const validPassword = !!replace(password, " ", "");

    if (!validEmail) errors[EMAIL] = "Invalid email";
    if (!validPassword) errors[PASSWORD] = "Invalid password";

    setErrors(errors);

    if (isEmpty(errors)) dispatch(loginAndThenRedirectAsync());
  };

  const onGoogleLoginSuccess = (response) => {
    dispatch(googleLoginAndThenRedirectAsync({ accessToken: response.access_token }));
  };

  const microsoftLogin = (response) => {
    const data = {
      accessToken: response.accessToken,
      credential: response.idToken,
    };
    dispatch(microsoftLoginAndThenRedirectAsync(data));
  };

  return (
    <div>
      <Preloader loading={loading} size="massive" content="Logging in" />

      <Form onSubmit={onSubmit}>
        <Form.Field error={!!errors[EMAIL]}>
          <label>Email</label>
          <Input
            icon
            iconPosition="left"
            placeholder="Email"
            value={email}
            onChange={(e, { value }) => setFormField(EMAIL, value)}
            autoFocus
            type="email"
          >
            <input />
            <Icon name="envelope" />
          </Input>
          <FormFieldError error={errors[EMAIL]} />
        </Form.Field>

        <Form.Field error={!!errors[PASSWORD]}>
          <label>Password</label>
          <Input
            icon
            iconPosition="left"
            placeholder="Password"
            value={password}
            onChange={(e, { value }) => setFormField(PASSWORD, value)}
            type="password"
            autoComplete="off"
          >
            <input />
            <Icon name="lock" />
          </Input>
          <FormFieldError error={errors[PASSWORD]} />
        </Form.Field>

        <Form.Field className="mb3 flex justify-between macrium-remember-forgot-passwd">
          <Checkbox
            label="Remember me"
            checked={rememberMe}
            onChange={(e, { checked }) => setFormField(REMEMBERME, checked)}
          />
          <div onClick={() => goTo(ROUTES.FORGOT_PASSWORD)}>
            <Link content="Forgot Password?" />
          </div>
        </Form.Field>

        <Button className="mt2" content="Login" size="large" primary fluid type="submit" disabled={loading} />
      </Form>

      <Divider horizontal className="my3">
        Or
      </Divider>

      <SSOContainer>
        <GoogleButton
          onSuccess={onGoogleLoginSuccess}
          onError={() => toast("Login has failed", { type: TOAST_TYPES.ERROR })}
        />

        <MicrosoftButton authCallback={microsoftLogin} />
      </SSOContainer>

      <div className="mt4 mb3 flex justify-center ">
        Looking for the
        <a href={PARTNER_PORTAL} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "4px" }}>
          <Link content="Partner Portal?" />
        </a>
      </div>
    </div>
  );
};

export default Login;
