import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Checkbox } from "semantic-ui-react";

const TwoFA = ({ twoFAFlag, handleChange, handleSave }) => {
  const [twoFA, setTwoFA] = useState(twoFAFlag);

  const handleTwoFAChange = (e, { checked }) => {
    handleChange(e, "twoFA");
    handleSave({ twoFA: checked });
    setTwoFA(checked);
  };

  return (
    <div className="account-details__section">
      <div className="account-details__section__left">
        <h3 className="account-details__section__left__title">Two Factor</h3>
        <h3 className="account-details__section__left__title">Authentication</h3>
      </div>

      <div className="account-details__section__right twofa-section">
        <p className="account-details__section__right__text">
          When enabled you will be sent a code to your account email to be used when logging in for additional security.
        </p>
        <Form>
          <Form.Field>
            <div>
              <Checkbox style={{ zIndex: 0 }} toggle checked={twoFA} onChange={handleTwoFAChange} />
            </div>
          </Form.Field>
        </Form>
      </div>
    </div>
  );
};

TwoFA.propTypes = {
  twoFAFlag: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
TwoFA.defaultProps = {
  twoFAFlag: false,
};
export default TwoFA;
