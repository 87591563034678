import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";

import CancelButton from "../../generic/CancelButton";
import EditButton from "../../generic/EditButton";
import ProfilingAddress from "../../profiling/ProfilingAddress";
import { getCountryName } from "../../../utils/countries";
import { isSuccessAction } from "../../../utils/redux/actions";
import { useDispatch } from "react-redux";
import { updateAccountDetailsAsync } from "../../../slices/account/details";
import { setIsBlocking } from "../../../slices/prompt/prompt";
import FieldWrapper from "../FieldWrapper/FieldWrapper";
import { Form } from "semantic-ui-react";

const AddressChange = ({ accountDetails }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    control,
    setValue,
    watch,
    reset,
  } = useForm();
  const onSave = async (newFields = {}) => {
    if (isEmpty(newFields)) return;
    const updatedData = { ...accountDetails, ...newFields };
    const res = await dispatch(updateAccountDetailsAsync(updatedData));

    return res;
  };

  const [editMode, setEditMode] = useState(false);

  const { addressLine1, addressLine2, city, stateProvince, postalZipCode, country } = accountDetails;

  const hasNoValues = !addressLine1 && !addressLine2 && !city && !stateProvince && !postalZipCode && !country;

  const onSubmit = async (form) => {
    if (!editMode || !isEmpty(errors)) return;

    const action = await onSave(form);

    if (isSuccessAction(action)) {
      dispatch(setIsBlocking(false));
      setEditMode(!editMode);
      reset();
    }
  };

  const handleEditClick = () => {
    setValue("addressLine1", addressLine1 || "");
    setValue("addressLine2", addressLine2 || "");
    setValue("city", city || "");
    setValue("stateProvince", stateProvince || "");
    setValue("postalZipCode", postalZipCode || "");
    setValue("country", country || "");
    setEditMode(true);
  };

  const handleCancelClick = () => {
    dispatch(setIsBlocking(false));
    setEditMode(false);
    reset();
  };

  return (
    <FieldWrapper header="Address">
      <Form>
        <div className={`account-details__section__right__item ${editMode && "edit-mode"}`}>
          <div className="account-details__section__right__item__wrapper account-details__address__wrapper">
            <div className="account-details__name flex flex-column account-details__address__wrapper__section">
              {editMode ? (
                <ProfilingAddress
                  addressLine1={watch("addressLine1")}
                  addressLine2={watch("addressLine2")}
                  city={watch("city")}
                  stateProvince={watch("stateProvince")}
                  postalZipCode={watch("postalZipCode")}
                  country={watch("country")}
                  setValue={setValue}
                  errors={errors}
                  control={control}
                  oneFieldPerLine
                  autoFocus
                />
              ) : (
                <>
                  <div>
                    {hasNoValues ? (
                      "-"
                    ) : (
                      <>
                        {addressLine1 && (
                          <>
                            {addressLine1}
                            <br />
                          </>
                        )}
                        {addressLine2 && (
                          <>
                            {addressLine2}
                            <br />
                          </>
                        )}
                        {city && (
                          <>
                            {city}
                            <br />
                          </>
                        )}
                        {postalZipCode && (
                          <>
                            {postalZipCode}
                            <br />
                          </>
                        )}
                        {stateProvince && (
                          <>
                            {stateProvince}
                            <br />
                          </>
                        )}
                        {country && (
                          <>
                            {getCountryName(country)}
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={`account-details__btn-wrap ${editMode && "account-details__btn-wrap--edit-mode"}`}>
            {editMode && <CancelButton onCancelClick={handleCancelClick} disabled={isSubmitting} />}{" "}
            <EditButton
              editMode={editMode}
              onEditClick={handleEditClick}
              onSaveClick={handleSubmit(onSubmit)}
              disabled={editMode && (!isDirty || isSubmitting)}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      </Form>
    </FieldWrapper>
  );
};

export default AddressChange;
