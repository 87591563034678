import React from "react";
import PropTypes from "prop-types";
import { MIcon, Link } from "@macrium/shared-components";
import { useSelector } from "react-redux";
import { get, split } from "lodash";

import { getReleaseNotesPerProduct } from "../../../selectors/products.js";
import {
  getProductKBUrl,
  PRODUCTS_DESCRIPTION,
  LEGACY_RELEASE_NOTES,
  SUBSCRIPTION,
} from "../../../constants/licenses-products.js";
import { PRODUCT_SUPPORT_POLICY } from "../../../constants/urls";
import { isLegacy } from "../../../utils/products.js";

const getReleaseNotes = (data, releaseNotes) => {
  let releaseNotesUrl = "";

  const [majorVersion] = split(data.version, ".");

  if (isLegacy(data.version)) {
    releaseNotesUrl = get(LEGACY_RELEASE_NOTES, `${majorVersion}.${data.description}`);
  } else {
    releaseNotesUrl = releaseNotes[data.description];
  }

  return releaseNotesUrl;
};

const LicenseDescription = ({ data, extended }) => {
  const releaseNotes = useSelector(getReleaseNotesPerProduct);

  const releaseNotesForProduct = getReleaseNotes(data, releaseNotes);

  const kbUrl = getProductKBUrl(data.description, data.version);

  return (
    <div className="product-card__description">
      <div className="product-card__description__version">
        <div className="mb0">
          {data.isTrial === "1" ? null : (
            <>
              {isLegacy(data.version) ? (
                <a href={PRODUCT_SUPPORT_POLICY} target="_blank" rel="noopener noreferrer">
                  <Link>v{data.version} (End of Life)</Link>
                </a>
              ) : data.subscription === SUBSCRIPTION ? null : data.version ? (
                `v${data.version}`
              ) : (
                ""
              )}
            </>
          )}
        </div>

        {extended && kbUrl && (
          <a href={kbUrl} target="_blank" rel="noreferrer" className="product-card__description__guide">
            <MIcon name="external" size="normalLarge" color="cBlue" />
            <span className="ml1">User Guide</span>
          </a>
        )}

        {!extended && releaseNotesForProduct && (
          <a href={releaseNotesForProduct} target="_blank" rel="noreferrer">
            <Link content="Release Notes" />
          </a>
        )}
      </div>

      <div className="product-card__description__text">{PRODUCTS_DESCRIPTION[data.description]}</div>
    </div>
  );
};

LicenseDescription.propTypes = {
  data: PropTypes.object,
  extended: PropTypes.bool,
};

export default LicenseDescription;
