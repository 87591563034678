import { useEffect, useState } from "react";
import { hasAcceptedCookies } from "../../../selectors/basics";
import { useDispatch, useSelector } from "react-redux";
import "./CookieWarning.style.scss";
import { Link } from "@macrium/shared-components";
import { Button } from "semantic-ui-react";
import { REJECTED } from "../../../constants/redux-action-statuses";
import { get } from "lodash";
import { acceptCookiePolicyAsync } from "../../../slices/account/details";
import { COOKIE_POLICY } from "../../../constants/urls";

const CookieWarning = () => {
  const dispatch = useDispatch();
  const [showCookieWarning, setShowCookieWarning] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const hasAcceptedCookiePolicy = useSelector(hasAcceptedCookies);
  useEffect(() => {
    if (hasAcceptedCookiePolicy === false) {
      setShowCookieWarning(true);
      setTimeout(() => setShowBox(true), 2000);
    }
  }, [hasAcceptedCookiePolicy]);
  const handleClose = async () => {
    const res = await dispatch(acceptCookiePolicyAsync());
    if (get(res, "meta.requestStatus") === REJECTED) return res;
    setShowBox(false);
    setTimeout(() => setShowCookieWarning(false), [1000]);
    return res;
  };
  if (!showCookieWarning) return null;
  return (
    <div className="container" style={{ bottom: showBox ? "0px" : "-200px", transition: "all 1s ease-out" }}>
      <div className="displayBox">
        <div>
          <p className="mb0">
            Our site uses cookies to help deliver the best experience for you. By using our website, you agree to the
            use of cookies.
          </p>
          <div className="flex flex-wrap ">
            <span className="nowrap mr1">Find out how we use cookies in our</span>
            <a href={COOKIE_POLICY} className="nowrap" target="_blank" rel="noopener noreferrer">
              <Link> cookies policy.</Link>
            </a>
          </div>
        </div>
        <Button content="OK" size="medium" primary type="button" onClick={handleClose} />
      </div>
    </div>
  );
};

export default CookieWarning;
