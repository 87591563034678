import { useDispatch, useSelector } from "react-redux";
import { isEmpty, get } from "lodash";
import { getLastDownloadedProduct, getLastActiveProduct } from "../../selectors/basics";
import BasicOnboardingCard from "./BasicOnboardingCard/BasicOnboardingCard";
import { updateOnboardingAsync, updateLastDownLoaded, fetchOnboardingAsync } from "../../slices/onboarding/onboarding";
import { REJECTED } from "../../constants/redux-action-statuses";
import { useEffect } from "react";
import FullOnBoardContainer from "./FullOnBoardContainer/FullOnBoardContainer";

import OnboardingDropdown from "./OnboardingDropdown/OnboardingDropdown";
import { progressStages } from "./progressStageData";
import { getFilteredOnboardingCards } from "../../selectors/onboarding";
import { LAST_ACTIVE_PRODUCT } from "../../constants/localStorage";
import { ONBOARDING_CARD_CLOSED } from "../../constants/hotjarEvents";

const OnboardingContainer = () => {
  const dispatch = useDispatch();
  const productCards = useSelector(getFilteredOnboardingCards);
  const lastDownloadedProduct = useSelector(getLastDownloadedProduct);
  const scrollToProduct = () => {
    const element = document.getElementById(`${lastDownloadedProduct}-onboarding`);
    if (element && lastDownloadedProduct) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      dispatch(updateLastDownLoaded(null));
    }
  };
  const lastActiveProduct = useSelector(getLastActiveProduct);

  useEffect(() => {
    if (!isEmpty(productCards)) {
      if (lastDownloadedProduct) {
        setTimeout(() => scrollToProduct(), 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCards]);
  if (productCards.length === 0 || !productCards) return null;
  const currentProduct = productCards.find((prod) => prod.product === lastActiveProduct) || productCards[0];
  const lastProductStages = progressStages.find((prod) => prod.productName === currentProduct.product) || null;
  const lastProductWithStagesAndUrl = {
    ...currentProduct,
    stages: lastProductStages
      ? lastProductStages.stages.map((stage) => {
          if (stage.number <= currentProduct.lastCompletedStep) {
            return { ...stage, isComplete: true, isActive: false };
          }
          if (stage.number === currentProduct.lastCompletedStep + 1)
            return { ...stage, isComplete: false, isActive: true };
          return { ...stage, isComplete: false, isActive: false };
        })
      : null,
    videoUrl: lastProductStages?.videoUrl || null,
  };
  const handleClose = async () => {
    const res = await dispatch(updateOnboardingAsync({ id: Number(currentProduct.id), open: false, completed: true }));
    if (get(res, "meta.requestStatus") === REJECTED) return res;
    if (window.hj) {
      window.hj("event", ONBOARDING_CARD_CLOSED);
    }
    localStorage.removeItem(LAST_ACTIVE_PRODUCT);
    dispatch(fetchOnboardingAsync());
    return res;
  };
  return (
    <div className="mb3">
      <h1 className="flex flex-wrap mb3 flex-center">
        <div className="mr2">Getting started with:</div>
        {productCards.length > 1 ? (
          <OnboardingDropdown options={productCards} lastActiveProduct={currentProduct} />
        ) : (
          <div className="bold ml1">{currentProduct.product}</div>
        )}
      </h1>
      {lastProductWithStagesAndUrl && lastProductWithStagesAndUrl.stages && (
        <FullOnBoardContainer currentProduct={lastProductWithStagesAndUrl} handleClose={handleClose} />
      )}
      {currentProduct && !lastProductWithStagesAndUrl.stages && (
        <BasicOnboardingCard
          key={currentProduct.id}
          expiryDate={currentProduct.trialExpiry}
          name={currentProduct.product}
          licenseKey={currentProduct.licenseKey}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default OnboardingContainer;
