import { isUndefined, reduce, find, forEach, get } from "lodash";
import { QTY } from "../../constants/licenses";
const createCount = (licenses, filters) => {
  return reduce(
    licenses,
    (newCount, { statuses }) => {
      // foreach licence check that it has a tag that matches all filters
      // this logic potentially superfluous because this function is only called with a filter after the licences have been filtered
      if (
        isUndefined(filters) ||
        reduce(
          filters,
          (matchesAllFilters, filterStr) => {
            if (!matchesAllFilters) return matchesAllFilters;
            return find(statuses, ({ id, text }) => filterStr === id || filterStr === text) ? matchesAllFilters : false;
          },
          true
        )
      ) {
        forEach(statuses, ({ id, text }) => {
          if (id !== QTY) {
            if (get(newCount, text)) {
              newCount[text] += 1;
            } else newCount[text] = 1;
          }
        });
        if (get(newCount, "totalCount")) newCount.totalCount++;
        else newCount.totalCount = 1;
      }
      return newCount;
    },
    {}
  );
};
export default createCount;
