import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOADING, IDLE, FAILED } from "../actionStatuses.js";
import {
  getOnboardingInfo,
  updateOnboardingDetails,
  updateStepCompleted,
  createOnboarding,
} from "../../app/api/onboarding.js";
import { get, isEmpty } from "lodash";
import { LICENSES_DESCRIPTIVE_STRING } from "../../constants/licenses.js";
import { LAST_ACTIVE_PRODUCT } from "../../constants/localStorage.js";

const REDUCER_NAME = "onboarding";

const initialState = {
  status: LOADING,
  onboardingProductCards: [],
  error: "",
  loaded: false,
  lastDownloaded: null,
  lastActiveProduct: null,
};
export const addOnboardingCardAsync = createAsyncThunk(`${REDUCER_NAME}/productdownload`, async (data, thunkAPI) => {
  const res = await createOnboarding(data);
  if (!res.success) return thunkAPI.rejectWithValue(res);
  thunkAPI.dispatch(updateLastDownLoaded(LICENSES_DESCRIPTIVE_STRING[data.description]));
  thunkAPI.dispatch(fetchOnboardingAsync());
  return res;
});
export const fetchOnboardingAsync = createAsyncThunk(`${REDUCER_NAME}/fetchOnboarding`, async (email, thunkAPI) => {
  const res = await getOnboardingInfo(email);
  if (!res.success) return thunkAPI.rejectWithValue(res);
  return res;
});
export const updateOnboardingAsync = createAsyncThunk(
  `${REDUCER_NAME}/updateDetailsRequest`,
  async (data, thunkAPI) => {
    const res = await updateOnboardingDetails(data);
    if (!res.success) return thunkAPI.rejectWithValue(res);
    thunkAPI.dispatch(fetchOnboardingAsync());
    return res;
  }
);
export const saveLastStepCompletedAsync = createAsyncThunk(
  `${REDUCER_NAME}/saveTheLastCompletedStep`,
  async (data, thunkAPI) => {
    const res = await updateStepCompleted(data);
    if (!res.success) return thunkAPI.rejectWithValue(res);
    thunkAPI.dispatch(fetchOnboardingAsync());
    return res;
  }
);

export const onboardingSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setLastActiveProduct: (state, { payload: productName }) => {
      state.lastActiveProduct = productName;
    },
    updateLastDownLoaded: (state, { payload: product }) => {
      if (product) localStorage.setItem(LAST_ACTIVE_PRODUCT, product);
      state.lastDownloaded = product;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnboardingAsync.pending, (state) => {
        if (!state.loaded || !isEmpty(state.error)) state.status = LOADING;
      })
      .addCase(fetchOnboardingAsync.fulfilled, (state, { payload }) => {
        const lastActiveProduct = localStorage.getItem(LAST_ACTIVE_PRODUCT);
        const basicOnboardingProductCards = get(payload, "data").onboardingProductCards;
        state.status = IDLE;
        state.onboardingProductCards = basicOnboardingProductCards;
        state.lastActiveProduct = lastActiveProduct ? lastActiveProduct : basicOnboardingProductCards[0]?.product;
        state.error = "";
        state.loaded = true;
      })
      .addCase(fetchOnboardingAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.message;
        state.loaded = false;
      })
      .addCase(updateOnboardingAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(updateOnboardingAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.data;
      })
      .addCase(updateOnboardingAsync.fulfilled, (state) => {
        state.error = null;
      })
      .addCase(saveLastStepCompletedAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(saveLastStepCompletedAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.data;
      })
      .addCase(saveLastStepCompletedAsync.fulfilled, (state) => {
        state.error = null;
      })
      .addCase(addOnboardingCardAsync.pending, (state) => {
        state.status = LOADING;
        state.error = null;
      })
      .addCase(addOnboardingCardAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.data;
      })
      .addCase(addOnboardingCardAsync.fulfilled, (state) => {
        state.error = null;
      });
  },
});

export default onboardingSlice.reducer;
export const { updateLastDownLoaded, setLastActiveProduct } = onboardingSlice.actions;
