import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { FormFieldError, Link } from "@macrium/shared-components";
import { SUBMIT_TICKET } from "../../../constants/urls";

const NoLicenseStep = ({ handleClose, selectedLicenseKey }) => {
  return (
    <div className="flex justify-between flex-column offline-step-container">
      <div style={{ fontSize: "16px" }}>
        <FormFieldError
          className="pt1 pb2 red"
          error={
            selectedLicenseKey
              ? "The License key you have selected does not exist on this account or is not eligible for offline activation."
              : "You currently do not have any license keys available for offline activation."
          }
        />
        <p>
          Please ensure that you are using the correct account or verify that your license keys have not already been
          activated on the maximum allowed number of devices.
        </p>
        <p>
          If you need to activate additional devices, you may purchase an additional license key. Alternatively, if you
          believe this message is an error, please check your account details or contact support for further assistance.
        </p>
      </div>
      <div className="flex flex-center">
        <Button primary onClick={() => handleClose()} content="Close" />
        <a className="ml2" href={SUBMIT_TICKET}>
          <Link>Need help?</Link>
        </a>
      </div>
    </div>
  );
};

export default NoLicenseStep;
NoLicenseStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedLicenseKey: PropTypes.string,
};
NoLicenseStep.defaultProps = {
  selectedLicenseKey: null,
};
