import { createSlice } from "@reduxjs/toolkit";
import { IDLE } from "../actionStatuses";

const REDUCER_NAME = "trials";

const initialState = {
  status: IDLE,
  product: {},
  error: null,
  isProfiling: false
};

export const trialsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setTrialProduct: (state, { payload }) => {
      state.product.edition = payload.edition;
      state.product.architecture = payload.architecture;
      state.product.description = payload.description;
      state.product.siteType = payload.siteType;
      state.error = null;
    },
    clearTrialProduct: (state) => {
      state.product = {};
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setIsProfiling: (state, action) => {
      state.isProfiling = action.payload;
    },
  },
});

export const { setTrialProduct, clearTrialProduct, setError, setIsProfiling } =
  trialsSlice.actions;

export default trialsSlice.reducer;
