import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";

import { isDownloadingTrial } from "../selectors/basics";
import { refreshAuthTokenAsync, logoutThunk } from "../slices/auth/login";
import { AUTH_TOKEN_EXP_TIME, LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME } from "../constants/auth";
import { hasAuthTokenCookieExpired } from "../utils/auth";

const IDLE_NAME = "SP_USER_PORTAL_IDLE";
const MESSAGE_EVENT_NAME = "REQUEST_LEADER_TO_REFRESH_AUTH_TOKEN";

/**
 * It checks the local storage to see if client side is trying to refresh the token at the same time (on a specific time window)
 * @returns
 */
const canRefreshTokenAgain = () => {
  const lastRefreshTime = localStorage.getItem(LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME);

  if (!lastRefreshTime) return true;

  return moment().diff(moment.unix(lastRefreshTime), "seconds") > 2;
};

const IdleActivity = React.memo(() => {
  const dispatch = useDispatch();

  const onIdleLogout = () => {
    // Each tab will call this once it reaches the timeout
    dispatch(
      logoutThunk({
        message:
          "Your session has timed out, and we logged you out to protect your account. To continue where you left off please log in below",
      })
    );
  };

  const { isLeader, message } = useIdleTimer({
    onIdle: onIdleLogout,
    onActive: () => {
      if (hasAuthTokenCookieExpired()) {
        if (isDownloadingTrial()) return dispatch(refreshAuthTokenAsync());
        return onIdleLogout();
      }
    },
    onAction: () => {
      /**
       * The idea is that ONLY the leader tab refreshes the auth token.
       */
      if (isLeader() && canRefreshTokenAgain()) {
        localStorage.setItem(LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME, moment().unix());

        return dispatch(refreshAuthTokenAsync());
      }

      // If is not the leader, send a message to the leader tab to refresh the token
      message(MESSAGE_EVENT_NAME);
    },
    onMessage: (event) => {
      /**
       * When tabs receive a message, check again if it's the leader.
       * If so, refresh the token
       */
      if (event === MESSAGE_EVENT_NAME && isLeader() && canRefreshTokenAgain()) {
        localStorage.setItem(LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME, moment().unix());

        return dispatch(refreshAuthTokenAsync());
      }
    },
    timeout: AUTH_TOKEN_EXP_TIME,
    throttle: 60 * 1000,
    leaderElection: true,
    crossTab: true,
    name: IDLE_NAME,
    syncTimers: 400,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      // 'visibilitychange', // This was creating a bug when we hover over the browsers app (taskbar) the token was being refreshed at the same time.
      "focus",
    ],
  });

  React.useEffect(() => {
    // Keep checking if the cookie has expired in case something fails
    const interval = setInterval(() => {
      if (hasAuthTokenCookieExpired()) {
        if (isDownloadingTrial()) return dispatch(refreshAuthTokenAsync());
        return onIdleLogout();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };

    // componentDidMount
    // eslint-disable-next-line
  }, []);

  return null;
});

export default IdleActivity;
