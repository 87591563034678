import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { getTrialProduct, needsBusinessProfiling } from "../../selectors/basics";

const useTrialProduct = () => {
  const trialProductDetails = useSelector(getTrialProduct);
  const needsProfiling = useSelector(needsBusinessProfiling);

  if (isEmpty(trialProductDetails)) return null;

  const { edition } = trialProductDetails;

  return {
    profilingNeeded: needsProfiling && edition !== 6,
  };
};

export default useTrialProduct;
