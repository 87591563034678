import PropTypes from "prop-types";

const ListItem = ({ item }) => {
  return (
    <li className="list-item mb1">
      <div className="list-item-dot p0 mr2">{item.id}</div>
      <div>{item.text}</div>
    </li>
  );
};
ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.node,
  }).isRequired,
};
export default ListItem;
