import React from "react";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";

import { copyToClipboard } from "../../../utils/generic";
import FieldWrapper from "../FieldWrapper/FieldWrapper";

const RegistrationCode = ({ urc }) => {
  return (
    <FieldWrapper header="Registration Code">
      <span className="account-details__section__right__text">{urc}</span>
      <span className="ml1" style={{ cursor: "pointer" }} onClick={() => copyToClipboard(urc)}>
        <MIcon name="copy" size="small" />
      </span>
    </FieldWrapper>
  );
};

RegistrationCode.propTypes = {
  urc: PropTypes.string.isRequired,
};

export default RegistrationCode;
