import { map } from "lodash";
import * as COUNTRIES from "countries-list";
import { find, get, orderBy } from "lodash";

export const COUNTRIES_LIST = orderBy(map(COUNTRIES.countries, ({ name }, countryShortName) => ({
  id: countryShortName,
  text: name,
  value: countryShortName,
})), ["text"]);

export const getCountryShortName = country => {
  if (!country) return "";

  const countryFromList = find(COUNTRIES_LIST, ({ text }) => text === country)

  return countryFromList ? get(countryFromList, "id", "") : "";
}

export const getCountryName = name => {
  if (!name) return "";

  // check if "name" is country code
  const country = find(COUNTRIES_LIST, ({ id }) => id === name)

  return country ? get(country, "text", "") : name;
}

export const COUNTRIES_SHORT_NAMES = Object.keys(COUNTRIES.countries);
export const COUNTRIES_NAMES = map(COUNTRIES.countries, "name");
