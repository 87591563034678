import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

export const ContinueButton = ({ loading, ...props }) => (
  <Button content="Continue" primary fluid size="large" disabled={loading} {...props} />
);

ContinueButton.propTypes = {
  loading: PropTypes.bool,
};

ContinueButton.defaultProps = {
  loading: false,
};

export const RegisterButton = ({ loading, ...props }) => (
  <Button content="Register" primary fluid size="large" type="submit" disabled={loading} {...props} />
);

RegisterButton.propTypes = {
  loading: PropTypes.bool,
};

RegisterButton.defaultProps = {
  loading: false,
};
