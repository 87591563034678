import PropTypes from "prop-types";
import { setLastActiveProduct } from "../../../slices/onboarding/onboarding";
import { useDispatch } from "react-redux";
import "./OnboardingDropdown.styles.scss";
import { LAST_ACTIVE_PRODUCT } from "../../../constants/localStorage";
import ContextMenu from "../../ContextMenu/ContextMenu";

const OnboardingDropdown = ({ lastActiveProduct, options }) => {
  const dispatch = useDispatch();

  const optionsExcludingCurrent = options.filter((opt) => opt.id !== lastActiveProduct.id);
  const menuOptions = optionsExcludingCurrent.map((product) => {
    return {
      key: product.id,
      className: "flex justify-between regular flex-center menuOption",
      value: product.id,
      onClick: () => {
        localStorage.setItem(LAST_ACTIVE_PRODUCT, product.product);
        dispatch(setLastActiveProduct(product.product));
      },
      content: <span>{product.product}</span>,
    };
  });
  return (
    <ContextMenu
      trigger={<div className="bold triggerButton">{lastActiveProduct.product}</div>}
      includeChevron
      menuContents={menuOptions}
    />
  );
};

export default OnboardingDropdown;

OnboardingDropdown.propTypes = {
  lastActiveProduct: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.string,
    isComplete: PropTypes.bool,
  }).isRequired,
  options: PropTypes.array.isRequired,
};
