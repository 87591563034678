import { getLocation, push } from "connected-react-router";
import { Divider } from "semantic-ui-react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../app/routes";
import CreateAnAccount from "../CreateAnAccount";
import { getCollectedCookieData } from "../../../selectors/basics";
import { addCookieData } from "../../../slices/offlineActivation/offlineActivation";

const OfflineActivationLink = () => {
  const { query } = useSelector(getLocation);
  const dispatch = useDispatch();
  const goTo = useCallback((value) => dispatch(push(value)), [dispatch]);
  const storedCookies = useSelector(getCollectedCookieData);
  if (!storedCookies) {
    const { key, activationCode, action } = query;
    if ((!key || !activationCode) && !action) return null;

    dispatch(addCookieData({ key, activationCode, action }));
  }
  const goToPage = () => goTo(ROUTES.OFFLINE_ACTIVATION);
  return (
    <>
      <CreateAnAccount
        header="Offline License Activation"
        subHeader="Create an account or login to activate your licence offline"
        hideForMobile={false}
      />
      <div>
        If you are unable to create an account please click below to begin your{" "}
        <span as="a" className="underline bold offline-link" style={{ cursor: "pointer" }} onClick={goToPage}>
          Offline Activation.
        </span>
      </div>
      <Divider />
    </>
  );
};

export default OfflineActivationLink;
