import StepController from "../StepController/StepController";
import { Preloader } from "@macrium/shared-components";
import PropTypes from "prop-types";
import VideoContainer from "./VideoContainer";

const StandardContent = ({ videoUrl, stage, isSaving, currentStep, goToNextStep, goToPreviousStep, thisStep }) => {
  return (
    <>
      <div className="flex centreOnMobile bold flex-center h3 pb2">
        <div className="mediaHeader-step mr2">
          Part <span>{stage.number}</span>
        </div>
        <div>{stage.title}</div>
      </div>
      <div className="bottomMediaBox">
        <div className="boxSize relative">
          <Preloader loading={isSaving} size="medium" />
          <StepController
            showOnDesktop={true}
            currentStage={currentStep}
            totalStages={stage.videoStages.length}
            onRightClick={goToNextStep}
            onLeftClick={goToPreviousStep}
            isProgress={false}
          />
          <div className="my3 contentContainer">
            <div className="bold mb1">{thisStep.title}</div>
            <div>{thisStep.content}</div>
          </div>
        </div>
        <div className="boxSize ">
          <VideoContainer videoUrl={videoUrl} />
        </div>
      </div>
    </>
  );
};

export default StandardContent;

StandardContent.propTypes = {
  stage: PropTypes.shape({
    isActive: PropTypes.bool,
    isComplete: PropTypes.bool,
    url: PropTypes.string,
    videoStages: PropTypes.array,
    number: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  videoUrl: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
  thisStep: PropTypes.shape({
    number: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.element,
  }).isRequired,
};
