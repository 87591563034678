import { Button } from "semantic-ui-react";
import { Link } from "@macrium/shared-components";
import PropTypes from "prop-types";

import "./CardMediaBox.styles.scss";
import { useEffect, useState } from "react";

import { ARTICLES_ALL, SUBMIT_TICKET } from "../../../constants/urls";
import FinishedContent from "./Finishedcontent";
import StandardContent from "./StandardContent";
import StageCompletedContent from "./StageCompletedContent";

const CardMediaBox = ({
  videoUrl,
  isSaving,
  stage,
  productName,
  handleIsComplete,
  isCurrentStep,
  isFinished,
  handleClose,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stageCompletedAlready, setStageCompletedAlready] = useState(false);
  useEffect(() => {
    setCurrentStep(1);
    setStageCompletedAlready(!!stage.isComplete);
  }, [stage]);
  const goToNextStep = () => setCurrentStep(currentStep + 1);
  const goToPreviousStep = () => setCurrentStep(currentStep - 1);
  const thisStep = stage?.videoStages?.find((vid) => vid.number === currentStep) || null;
  const isFinalStep = currentStep === stage?.videoStages?.length;

  return (
    <div className="mediaBoxContainer ">
      <div>
        {isFinished && <FinishedContent productName={productName} />}
        {!isFinished && !stageCompletedAlready && thisStep && (
          <StandardContent
            isSaving={isSaving}
            stage={stage}
            currentStep={currentStep}
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            thisStep={thisStep}
            videoUrl={videoUrl}
          />
        )}
        {stageCompletedAlready && !isFinished && <StageCompletedContent stage={stage} />}
      </div>
      <div className="buttonContainer ">
        {isFinished && (
          <Button
            primary
            className="fullWidthOnMobile"
            content="Finish"
            onClick={handleClose}
            loading={isSaving}
            disabled={isSaving}
          />
        )}
        {!isFinished && !stageCompletedAlready && (
          <>
            {isCurrentStep && !isFinalStep && (
              <Button primary content="I've done this step" className="fullWidthOnMobile" onClick={goToNextStep} />
            )}
            {isCurrentStep && isFinalStep && (
              <Button
                primary
                loading={isSaving}
                disabled={isSaving}
                content="Finish"
                className="fullWidthOnMobile"
                onClick={() => {
                  handleIsComplete();
                  setCurrentStep(1);
                }}
              />
            )}
            {!isCurrentStep && <div />}
          </>
        )}
        {stageCompletedAlready && !isFinished && stage.number > 1 && (
          <Button
            secondary
            content="View again?"
            onClick={() => {
              setCurrentStep(1);
              setStageCompletedAlready(false);
            }}
          />
        )}
        {!isFinished && (
          <a href={ARTICLES_ALL} target="_blank" rel="noopener noreferrer" className="desktopButton">
            <Link>View Knowledge base</Link>
          </a>
        )}
      </div>
      {!stageCompletedAlready && !isFinished && (
        <div className="mobileButtonRow">
          <a href={SUBMIT_TICKET} target="_blank" rel="noopener noreferrer">
            <Link>Need help?</Link>
          </a>
          <a href={ARTICLES_ALL} target="_blank" rel="noopener noreferrer">
            <Link>View Knowledge base</Link>
          </a>
        </div>
      )}
    </div>
  );
};

export default CardMediaBox;

CardMediaBox.propTypes = {
  stage: PropTypes.shape({
    isActive: PropTypes.bool,
    isComplete: PropTypes.bool,
    url: PropTypes.string,
    videoStages: PropTypes.array,
    number: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
  videoUrl: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  productName: PropTypes.string.isRequired,
  handleIsComplete: PropTypes.func.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
