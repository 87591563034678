import { OFF_ACTIVATION_KB, OFF_ACTIVATION_VIDEO_URL } from "../../constants/urls";
import { Link } from "@macrium/shared-components";
import PropTypes from "prop-types";

const MediaContainer = ({ hasNoAvailableLicenses }) => {
  return (
    <div className="offline-videoContent p0">
      <iframe
        data-testid="video-container"
        style={{
          aspectRatio: "16 / 9",
          width: "100%",
          border: "none",
        }}
        src={OFF_ACTIVATION_VIDEO_URL}
        title="YouTube video player"
        controls="1"
        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
      <div className="offline-video-title pt1">How-to Video</div>
      <div className="pb2">How to Activate Reflect Offline</div>
      {!hasNoAvailableLicenses && (
        <a href={OFF_ACTIVATION_KB} target="_blank" rel="noopener noreferrer">
          <Link>Need more help?</Link>
        </a>
      )}
    </div>
  );
};

MediaContainer.propTypes = {
  hasNoAvailableLicenses: PropTypes.bool,
};
export default MediaContainer;
