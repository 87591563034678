import React from "react";
import PropTypes from "prop-types";

import { PASSWORD_REQUIREMENTS } from "../../utils/requirements";

const PasswordRequirements = ({ header, requirementsMetResults, showValidation }) => (
  <div className="reset-password">
    <h2>{header}</h2>

    <p className="reset-password__text">Create a secure password. Make sure it has at least:</p>
    <div className="mb2">
      {PASSWORD_REQUIREMENTS.map((req, index) => (
        <div key={index} className="reset-password__requirements">
          {requirementsMetResults[index].pass ? (
            <div
              data-testid="green-dot"
              className="reset-password__requirements__dot reset-password__requirements__dot--met"
            />
          ) :
            (<div
              data-testid="red-dot"
              className={`reset-password__requirements__dot reset-password__requirements__dot--not-met  ${showValidation && !requirementsMetResults[index].pass ? "reset-password__requirements__dot--invalid" : ""
                }`}
            />)}
          <p>{req.text}</p>
        </div>
      ))}
    </div>
  </div>
);

PasswordRequirements.propTypes = {
  header: PropTypes.any,
  requirementsMetResults: PropTypes.array.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

PasswordRequirements.defaultProps = {
  header: null,
};

export default PasswordRequirements;
