export const AUTH_TOKEN_EXP_TIME = 10 * 60 * 1000; // 10 minutes
export const RESEND_TIMEOUT = 30 * 1000; // 30 seconds

export const REGISTER_TYPES = {
  EMAIL: "Email",
  GOOGLE: "Google",
  MICROSOFT: "Microsoft",
};

export const LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME = "USER_PORTAL_LAST_REFRESH";
