import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocation, push } from "connected-react-router";
import { Preloader, MIcon } from "@macrium/shared-components";

import {
  isResendingEmailConfirmationEmail,
  needsActivationAfterLogin,
} from "../../selectors/basics";
import { resendEmailConfirmationEmailThunk } from "../../slices/auth/register";
import ResendLink from "../generic/resendLink.jsx";
import ROUTES from "../../app/routes";

const ActivateAccount = ({ email }) => {
  const [hasSent, setHasSent] = React.useState(false);
  const loading = useSelector(isResendingEmailConfirmationEmail);
  const afterLoginActivation = useSelector(needsActivationAfterLogin);

  const dispatch = useDispatch();

  const goBack = () => dispatch(push(ROUTES.LOGIN));

  return (
    <div className="macrium-activate-account">
      <Preloader loading={loading} content="Resending email confirmation" />

      <div
        className="macrium-activate-account__back clickable"
        onClick={goBack}
      >
        <MIcon name="chevron left" className="mr1" />
        Back
      </div>

      <div>
        <h2 className="mt0 mb0">Activate your Account</h2>
        {email ? (
          <>
            <p className="mt1">
              {!hasSent && afterLoginActivation
                ? "Click below to be sent a new email to confirm your account"
                : `Verify your account by clicking the email we sent to ${email}`}
            </p>

            <div className="mt2">
              <ResendLink
                content="Resend Email"
                onClick={() => {
                  setHasSent(true);
                  dispatch(resendEmailConfirmationEmailThunk({ email }));
                }}
              />
            </div>
          </>
        ) : (
          <p className="mt1">Invalid email</p>
        )}
      </div>
    </div>
  );
};

const AfterRegister = () => {
  const { query } = useSelector(getLocation);
  const { email } = query;

  return <ActivateAccount email={email} />;
};

export default AfterRegister;
