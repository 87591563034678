import React from "react";
import PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import { last, split } from "lodash";
import { MIcon } from "@macrium/shared-components";

import "./Video.styles.scss";

const IFrame = ({ src, modalModal }) => (
  <iframe
    width={modalModal ? "100%" : "560"}
    height={modalModal ? "100%" : "315"}
    src={src}
    title="YouTube video player"
    controls={modalModal ? 1 : 0}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>
);

const Video = ({ src }) => {
  const [open, setOpen] = React.useState(false);

  const videoId = last(split(src, "/"));

  return (
    <Modal
      closeIcon
      open={open}
      trigger={
        <div className="video__modal-trigger">
          <MIcon name="play" color="cWhite" size="mediumLarge" />

          <div className="video__modal-trigger__thumbnail">
            <img
              src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
              width="130"
              height="75"
              alt="UserPortalVideo"
            />
          </div>
        </div>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="large"
      className="video__modal"
    >
      <Modal.Content>
        <IFrame src={`${src}?autoplay=1`} modalModal />
      </Modal.Content>
    </Modal>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Video;
