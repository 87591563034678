import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../app/api/authAccount";
import { IDLE, LOADING, FAILED, SUCCESS } from "../actionStatuses";
import { toast } from "react-toastify";

import * as TOAST_TYPES from "../../constants/toast";

const REDUCER_NAME = "forgotPassword";

const initialState = {
  status: IDLE,
  errorMessage: "",
};

export const requestPasswordResetThunk = createAsyncThunk(
  `${REDUCER_NAME}/requestPasswordReset`,
  async (email, thunkAPI) => {
    const response = await API.requestPasswordReset(email);

    if (!response.success) {
      toast(response.message, { type: TOAST_TYPES.ERROR, className: 'toast-large' });

      return thunkAPI.rejectWithValue(response);
    }

    return response;
  }
);

export const forgotPasswordSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestPasswordResetThunk.pending, (state) => {
        state.status = LOADING;
        state.errorMessage = "";
      })
      .addCase(requestPasswordResetThunk.fulfilled, (state) => {
        state.status = SUCCESS;
        state.errorMessage = "";
      })
      .addCase(requestPasswordResetThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.errorMessage = payload.message;
      });
  },
});

export const { clearState } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
