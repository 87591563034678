import PropTypes from "prop-types";
import "./LicenseContextMenu.styles.scss";
import ContextMenu from "../../ContextMenu/ContextMenu";

const LicenseContextMenu = ({
  openOfflineActivationModal,
  setOfflineKey,
  offlineActivationKey,
  isOfflineActivated,
}) => {
  const menuContents = isOfflineActivated
    ? [
        {
          id: 2,
          content: "View Offline Key",
          onClick: () => setOfflineKey(offlineActivationKey),
          className: "flex justify-center regular flex-center context-menu-option",
        },
      ]
    : [
        {
          id: 1,
          content: "Activate License Offline",
          onClick: openOfflineActivationModal,
          className: "flex justify-center regular flex-center context-menu-option",
        },
      ];

  return (
    <ContextMenu
      trigger={
        <div data-testid="kebab-menu-trigger" className="flex kebab_menu flex-column flex-end pr2">
          <div className="kebab-menu-dot" />
          <div className="kebab-menu-dot" />
          <div className="kebab-menu-dot" />
        </div>
      }
      alignRight
      menuContents={menuContents}
    />
  );
};

export default LicenseContextMenu;

LicenseContextMenu.propTypes = {
  openOfflineActivationModal: PropTypes.func.isRequired,
  setOfflineKey: PropTypes.func.isRequired,
  isOfflineActivated: PropTypes.bool.isRequired,
  offlineKey: PropTypes.string,
};
LicenseContextMenu.defaultProps = {
  offlineKey: null,
};
