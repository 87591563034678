import {
  AVAILABLE,
  INSTALLED,
  OFFLINE_ACTIVATED_ACTIVE,
  OFFLINE_ACTIVATED_EXPIRED,
  OFFLINE_ACTIVATED_WARNING,
} from "../../constants/licenses";

const statusValidForOfflineActivation = (statuses) =>
  statuses.find(({ id }) => id === AVAILABLE || id === OFFLINE_ACTIVATED_EXPIRED || id === INSTALLED) &&
  statuses.every(({ id }) => id !== OFFLINE_ACTIVATED_ACTIVE && id !== OFFLINE_ACTIVATED_WARNING);
export default statusValidForOfflineActivation;
