import { doPostRequest, doGetRequest } from "./requester";

const apiOnboardingUrl = "api/Download";
export const getOnboardingInfo = () => doGetRequest(`${apiOnboardingUrl}/getonboardingproductcards`);

export const updateOnboardingDetails = (data) =>
  doPostRequest(`${apiOnboardingUrl}/setonboardingproductcard?product`, data);

export const updateStepCompleted = (data) => doPostRequest(`${apiOnboardingUrl}/savethelastcompletedstep`, data);
export const createOnboarding = (data) => doPostRequest(`${apiOnboardingUrl}/productdownload`, undefined, data);
