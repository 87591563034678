import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import authReducers from "../slices/auth";
import { logout } from "../slices/auth/login";
import accountReducer from "../slices/account";
import productsReducer from "../slices/products";
import promptReducer from "../slices/prompt";
import billingReducer from "../slices/billing/billing";
import downloadsReducer from "../slices/downloads/downloads";
import unsavedChanges from "./middleware/unsavedChanges";
import initialLoad from "./middleware/initialLoad";
import trialsReducer from "../slices/products/trials";
import onFirstRender from "./middleware/onFirstRender";
import onboardingReducer from "../slices/onboarding/onboarding";
import offlineReducer from "../slices/offlineActivation/offlineActivation";

export const history = createBrowserHistory();

const appReducer = combineReducers({
  auth: authReducers,
  account: accountReducer,
  billing: billingReducer,
  downloads: downloadsReducer,
  onboarding: onboardingReducer,
  products: productsReducer,
  prompt: promptReducer,
  router: connectRouter(history),
  trials: trialsReducer,
  offline: offlineReducer,
});

const createRootReducer = (history) => (state, action) => {
  // clear state when logging out.
  // Pass down the action which may contain a payload
  if (action.type === logout.toString()) return appReducer(undefined, action, history);

  return appReducer(state, action, history);
};

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(onFirstRender, initialLoad, unsavedChanges, routerMiddleware(history)),
});
