import React from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";

const ResetSuccessMessage = ({ handleBackToLogin }) => (
  <div className="forgot-password__success-message">
    <h2 className="forgot-password__title">Recover password</h2>
    <p className="forgot-password__text">
      If an account exists, we will send you an email to your inbox on how to
      reset your password
    </p>
    <Button
      onClick={handleBackToLogin}
      content="Go Back To Login"
      size="large"
      primary
      fluid
    />
  </div>
);

ResetSuccessMessage.propTypes = {
  handleBackToLogin: PropTypes.func.isRequired,
};

export default ResetSuccessMessage;
