import {
  reduce,
  map,
  isUndefined,
  isNil,
  get,
  isEmpty,
  split,
  isFinite,
  forEach,
  cloneDeep,
  filter,
  groupBy,
  size,
  orderBy,
  toNumber,
  find,
  toString,
  findIndex,
  noop,
  some,
} from "lodash";
import { createSelector } from "reselect";
import moment from "moment";

import { getProductsData, getLicenseFilters, getOrders } from "./basics";
import {
  SUPPORT_TYPES,
  ORDER_STATUS_TEXT,
  REFUNDED,
  UPGRADED,
  SITE_MANAGER,
  KEY_TO_VERSION,
  VERSIONLESS_LICENSES,
  SITEDEPLOY,
  AVAILABLE,
  ACTIVE,
  EXPIRED,
  EXPIRED_SUPPORT_TEXT,
  BUSINESS_SUPPORT_TEXT,
  HOME_SUPPORT_TEXT,
  TECHNICIAN,
  DEPLOYMENT_KIT,
  INSTALLED,
  REFLECT_HOME,
  SITE_MANAGER_TYPES,
  QTY,
  UPGRADE_KEY,
  SITE_MANAGER_SUBSCRIPTION,
  OFFLINE_ACTIVATED_ACTIVE,
  OFFLINE_ACTIVATED_EXPIRED,
  OFFLINE_ACTIVATED,
  OFFLINE_ACTIVATED_WARNING,
} from "../constants/licenses.js";
import { PERPETUAL, SUBSCRIPTION } from "../constants/licenses-products.js";
import { UPGRADE_SKUS, VIRTUAL_SERVER_BUNDLE_SKUS } from "../constants/license-skus.js";
import { getTrialExpiresInHours } from "../utils/trials";
import sortTags from "../utils/license/sortTags.js";
import arrayToObject from "../utils/license/arrayToObject.js";
import objectToArray from "../utils/license/objectToArray.js";
import combineCounts from "../utils/license/combineCounts.js";
import checkFirstExpiry from "../utils/license/checkFirstExpiry.js";
import checkNextExpiry from "../utils/license/checkNextExpiry.js";
import checkLastExpiry from "../utils/license/checkLastExpiry.js";
import createCount from "../utils/license/createCount.js";
import addFloatingLicense from "../utils/license/addFloatingLicense.js";
import licenseExpiresSoon from "../utils/license/licenseExpiresSoon.js";

const createTrialProductKey = (description) => `${description}_Trial`;
const getCurrentStatus = (status) => (status === ACTIVE ? INSTALLED : status);

export const getTrials = createSelector([getProductsData], (products) => {
  let trials = filter(products, ({ isTrial }) => isTrial === "1");

  trials = groupBy(trials, "description");

  trials = reduce(
    trials,
    (res, trialProduct, description) => {
      let hasMultipleInstalls = size(trialProduct) > 1;

      const productsOrderedByExpireTimes = orderBy(trialProduct, ["expireTimes"], "asc");

      // probably add a different description to differentiate between trial and owned product
      res[createTrialProductKey(description)] = {
        description,
        isTrial: "1",
        hasMultipleInstalls,
        installs: map(trialProduct, ({ expireTimes, bias, ...product }) => ({
          ...product,
          expireTimes,
          expiresInHours: getTrialExpiresInHours(moment(), expireTimes, bias),
        })),
        // Having to deal with multiple installations:
        // All the following properties are related to the next trial to expire (or the single installation)
        version: get(productsOrderedByExpireTimes, "0.version"),
      };

      return res;
    },
    {}
  );

  return trials;
});

export const getLicenseGroups = createSelector([getProductsData, getOrders], (products, orders) => {
  const floatingLicenses = {};
  const duplicateKeys = {};
  const newProducts = reduce(
    products,
    (newProductGroups, product) => {
      const {
        licenseKeys,
        supportTypes,
        expireTimes,
        licenseStatuses,
        orderStatuses,
        offlineLicense,
        offlineKey,
        lastRenewal,
        needsOfflineReactivating,
        upgrades,
        v4Licenses,
        sku,
        seatsPerLicense,
        keyType,
        isTrial,
        purchasedOn,
        subsTransId,
        transId,
      } = product;

      // Licences arrive from the server in groups
      // The groups are by product type (ReflectHome or Site Manager or Whatevs) and sku
      // it is safe to assume every licence key in a product group is for the product / sku
      // however there may be multiple groups for the same product & version with either different or same skus

      // if product.description is Empty then it has not been grouped correctly
      // if the product Description is Site Manager and it is a subscription then it is a licence used to generate keys for a reseller
      // these must be hidden
      if (
        isTrial === "1" ||
        isNil(product.description) ||
        isEmpty(product.description) ||
        reduce(split(orderStatuses, ","), (allRefunded, status) => (status === REFUNDED ? allRefunded : false), true)
      ) {
        return newProductGroups;
      }

      // For the overview page cards are split by version and group type
      // The license Page combines versions of a single group type
      const productDescriptionWithVersion = `${product.description}&${product.version}`;

      // guard statement against data error
      if (
        isNil(licenseKeys) ||
        isNil(licenseStatuses) ||
        isNil(orderStatuses) ||
        isNil(upgrades) ||
        isNil(v4Licenses) ||
        isNil(supportTypes) ||
        isNil(seatsPerLicense) ||
        isNil(offlineLicense) ||
        isNil(offlineKey) ||
        isNil(lastRenewal) ||
        isNil(needsOfflineReactivating)
      ) {
        return {
          ...newProductGroups,
          [productDescriptionWithVersion]: {
            ...product,
            lastPurchasedOn: moment.unix(purchasedOn).utc(),
            activeCount: 0,
            sumCount: 0,
            error: true,
            errorMessage: "Error retrieving License Keys",
            licenses: get(newProductGroups, `${productDescriptionWithVersion}.licenses`, []),
          },
        };
      }

      // data on licenses are not sent from server as objects
      // data comes as strings seperated by commas
      // strings must be split and an object created by using the index of a loop
      const splitKeys = licenseKeys.split(",");
      const splitStatuses = licenseStatuses.split(",");
      const splitOrderStatuses = orderStatuses.split(",");
      const splitUpgrades = upgrades.split(",");
      const splitV4Licenses = v4Licenses.split(",");
      const splitSupport = supportTypes.split(",");
      const splitOfflineActivated = offlineLicense.split(",");
      const splitOfflineKey = offlineKey.split(",");
      const splitLastRenewalDate = lastRenewal.split(",");
      const splitNeedsOfflineReactivating = needsOfflineReactivating.split(",");
      const splitSeats = reduce(
        seatsPerLicense.split(","),
        (newArray, seats) => {
          const parsedSeats = parseInt(seats);
          if (isFinite(parsedSeats)) newArray.push(parsedSeats);
          return newArray;
        },
        []
      );
      const splitKeyTypes = keyType?.split(",") || [];

      // guard clause against data error

      if (
        splitKeys.length !== splitStatuses.length ||
        splitKeys.length !== splitOrderStatuses.length ||
        splitKeys.length !== splitUpgrades.length ||
        splitKeys.length !== splitV4Licenses.length ||
        splitKeys.length !== splitSupport.length ||
        splitKeys.length !== splitSeats.length ||
        splitKeys.length !== splitOfflineActivated.length ||
        splitKeys.length !== splitLastRenewalDate.length ||
        splitKeys.length !== splitOfflineKey.length ||
        splitKeys.length !== splitNeedsOfflineReactivating.length
      ) {
        return {
          ...newProductGroups,
          [productDescriptionWithVersion]: {
            ...product,
            lastPurchasedOn: moment.unix(purchasedOn).utc(),
            activeCount: 0,
            error: true,
            errorMessage: "Unable to retrieve License Keys",
            licenses: get(newProductGroups, `${productDescriptionWithVersion}.licenses`, []),
            productDescriptionWithVersion,
          },
        };
      }

      // expireTimes data is "dodgy" due to "imports"
      let splitExpiry = expireTimes;
      if (!isNil(splitExpiry)) {
        splitExpiry = expireTimes.split(",");
      }

      const isUpgradeKey = !!UPGRADE_SKUS[sku];
      let activeCount = 0;

      let firstExpiry;
      let nextExpiry;
      let lastExpiry;

      let newSeats = parseInt(product.seats);
      let newTotalLicenses = product.totalLicenses;

      let foundOrder;
      if (!isNil(subsTransId) && transId !== subsTransId)
        foundOrder = find(orders, (order) => order.m_transId === subsTransId);

      // old keys from inplace upgrades may be hidden in the data
      // as we loop through our licences put these keys to one side

      /***** ******************* *****/
      /***** LICENCE LOOP STARTS *****/
      /***** ******************* *****/

      /* for each licence we must know */
      // if subscription
      //   if the subscription active
      //     is the key Installed or available
      //     what is the renewal date
      //   else if the subscription is expire
      //     show the subscription has expired
      // if perpetual licence
      //   is the key Installed or available
      //   if this is an upgrade key
      //     if the key is unused
      //       what version will this upgrade to
      //     else
      //       what are the old keys
      //       is the latest key a higher or lower version
      //       what is the new version
      //   if this is an inplace upgrade
      //     what are the old keys
      //     was the inplace upgrade applied to an upgrade key
      //     what is the new version
      //   else
      //     what is the version
      //   if is a home product
      //     if was purchased less than a year ago
      //       show home support
      //       what is expiry date
      //     else
      //       show expired support
      //   if is a business product
      //     if is site manager or sitedeploy or multiple vm
      //       get number of seats
      //     if was purchased less than a year ago or has a expiry or renewal date that is in the past
      //       show business support
      //         what is next support renewal date
      //     else
      //       show expired support

      // unfortunately the code below does not work in such a neat order
      // but its requirements are documented

      let newLicenses = reduce(
        splitKeys,
        (newLicenses, licenseKey, i) => {
          if (duplicateKeys[licenseKey]) {
            if (isFinite(parseInt(splitSeats[i]))) newSeats = newSeats - parseInt(splitSeats[i]);
            newTotalLicenses--;

            if (!VIRTUAL_SERVER_BUNDLE_SKUS[sku] && !product.description === DEPLOYMENT_KIT) return newLicenses;
            // this is mutatey as heck and I love it
            let duplicate = find(newLicenses, ({ licenseId }) => licenseId === licenseKey);
            // hackey workaround for upgrades on multiple installs
            if (isUndefined(duplicate)) {
              const newKey = KEY_TO_VERSION[licenseKey.split("")[0]];
              const duplicateLicenses = floatingLicenses[`${product.description}&${toString(newKey)}`]?.licenses;
              //guard statement to avoid crash. If we're hitting this statement then we should panic
              if (isUndefined(duplicateLicenses)) return newLicenses;
              duplicate = find(duplicateLicenses, ({ licenseId }) => licenseId === licenseKey);
              //guard statement to avoid crash. If we're hitting this statement as well then we should panic
              if (isUndefined(duplicate)) return newLicenses;
            }

            const statusIndex = findIndex(duplicate?.statuses, ({ text }) => text.startsWith("Installs"));
            // for server bundle skus
            // the last 2 characters of the sku are the multiple install allowance
            if (
              VIRTUAL_SERVER_BUNDLE_SKUS[sku] &&
              product.licensesInUse > 0 &&
              parseInt(duplicate.statuses[statusIndex].text.substring(10)) < parseInt(sku.substring(14))
            ) {
              duplicate.statuses[statusIndex].text = `Installs: ${
                parseInt(duplicate.statuses[statusIndex].text.substring(10)) + 1
              }`;
            }

            // for deployment kit installs
            // don't show more than 5 installs as it's the maximum value
            if (product.description === DEPLOYMENT_KIT && product.licensesInUse > 0) {
              const currentInstall = parseInt(duplicate.statuses[statusIndex].text.substring(10));
              const newInstalls = currentInstall + 1;

              if (newInstalls <= 5) {
                duplicate.statuses[statusIndex].text = `Installs: ${newInstalls}`;
              }
            }
            return newLicenses;
          }
          let isVirtualBundle;
          duplicateKeys[licenseKey] = true;

          if (VIRTUAL_SERVER_BUNDLE_SKUS[sku]) {
            duplicateKeys[licenseKey] = true;
            isVirtualBundle = true;
          }
          // check it is expired, refunded or upgraded before assigning it's status
          const supportStatus = get(ORDER_STATUS_TEXT, splitOrderStatuses[i], splitStatuses[i] || EXPIRED);
          const isSubscription = product.subscription === SUBSCRIPTION;
          if (supportStatus === ACTIVE) activeCount++;

          if (splitOrderStatuses[i] === REFUNDED) {
            return newLicenses;
          }

          const newTags = [];
          const isSiteManager =
            product.description === SITE_MANAGER || product.description === SITE_MANAGER_SUBSCRIPTION;

          if (splitOfflineActivated[i] === "1") {
            const requiresReactivation = splitNeedsOfflineReactivating[i] === "1";
            if (requiresReactivation) {
              const lastRenewedDate = moment.unix(splitLastRenewalDate[i]).utc();
              const localString = moment(lastRenewedDate).local().format("L");
              newTags.push({ id: `Renewed on: ${localString}`, text: `Renewed on: ${localString}` });
            }
            const isExpiryWithinOneMonth = () => {
              if (!splitExpiry) return OFFLINE_ACTIVATED_ACTIVE;
              const expiryDate = moment.unix(splitExpiry[i]).utc();
              return licenseExpiresSoon(expiryDate) && isSubscription
                ? OFFLINE_ACTIVATED_WARNING
                : OFFLINE_ACTIVATED_ACTIVE;
            };
            // need to add check here for if licence is about to renew
            newTags.push({
              id: requiresReactivation ? OFFLINE_ACTIVATED_EXPIRED : isExpiryWithinOneMonth(),
              text: OFFLINE_ACTIVATED,
            });
          }
          // Check for Site Manager or SiteDeploy seats
          // seats are not provided on a per licence key level yet
          if (isSiteManager && SITE_MANAGER_TYPES[splitKeyTypes[i]]) {
            newTags.push({
              id: SITE_MANAGER_TYPES[splitKeyTypes[i]],
              text: SITE_MANAGER_TYPES[splitKeyTypes[i]],
            });
            newTags.push({
              id: QTY,
              text: `Seats: ${splitSeats[i]}`,
            });
          }
          if (product.description === SITEDEPLOY) {
            newTags.push({
              id: QTY,
              text: `Endpoints: ${splitSeats[i]}`,
            });
          }
          // multiple install products
          if (
            duplicateKeys[licenseKey] &&
            product.licensesInUse > 0 &&
            (VIRTUAL_SERVER_BUNDLE_SKUS[sku] || product.description === DEPLOYMENT_KIT)
          ) {
            newTags.push({
              id: QTY,
              text: "Installs: 1",
            });
          }

          const licenseVersion = `V${get(product, "version", "ersion: Unknown")}`;

          let newSupportStatus =
            product.subscription === PERPETUAL
              ? product.supportStatus === EXPIRED && supportStatus !== AVAILABLE && supportStatus !== ACTIVE
                ? EXPIRED_SUPPORT_TEXT
                : SUPPORT_TYPES[splitSupport[i]]
              : SUPPORT_TYPES[splitSupport[i]];

          const expiryString = isSubscription ? EXPIRED : EXPIRED_SUPPORT_TEXT;
          let expiryDate;
          // Trying to find the expiry date
          // legacy data may not have certain columns
          
          if (product.description === REFLECT_HOME) { // perpetual
            expiryDate = moment.unix(purchasedOn).utc();
            expiryDate = expiryDate.add(1, "years").startOf("day");
            // the expiryTimes for Reflect Home licences can be shifted up to the end of the month in the database erroneously
            // however a key may be upgraded and the expireTimes is the new expiry date based on the date of upgrade
            // check the expireTimes is older than the end of the month of an expiry date calculated from purchase date
            const oneDayExtra = cloneDeep(expiryDate).endOf("month").add(1, "days");
            if (
              !isNil(expireTimes) &&
              splitExpiry[i] &&
              moment.unix(splitExpiry[i]).utc().startOf("day").isAfter(oneDayExtra)
            ) {
              expiryDate = moment.unix(splitExpiry[i]).utc().startOf("day");
            }

            if (!isNil(foundOrder)) {
              let subExpiryDate = moment
                .unix(moment(foundOrder.m_transTime).unix())
                .utc()
                .add(1, "years")
                .startOf("day");
              expiryDate = checkLastExpiry(expiryDate, subExpiryDate);
            }

            if (moment().isAfter(moment(expiryDate))) newSupportStatus = expiryString;
          } else if (!isNil(expireTimes) && splitExpiry[i]) { // all other products including Home Subs
            expiryDate = moment.unix(splitExpiry[i]).utc();

            if (!isSubscription) { // Perpetual - expires at the start of the day
              expiryDate = expiryDate.startOf("day");
            }

            if (moment().isAfter(moment(expiryDate))) {
              newSupportStatus = expiryString;
            }
          } else {
            expiryDate = moment.unix(purchasedOn).utc();
            // TODO - this is fine for Perpetual, but seems wrong as we have different subs lengths.
            // This might be an edge case though, as the data should have been returned by the BE appropriately.
            // Something to think about when the logic is moved up to the BE.
            expiryDate = expiryDate.add(1, "years"); 

            if (moment().isAfter(moment(expiryDate))) newSupportStatus = expiryString;
          }

          firstExpiry = checkFirstExpiry(firstExpiry, expiryDate);
          nextExpiry = checkNextExpiry(nextExpiry, expiryDate);
          lastExpiry = checkLastExpiry(lastExpiry, expiryDate);

          const localString = moment(expiryDate).local().format("L");

          // subscription keys can get off the logic early as they will never be upgraded
          // unfortunately technician's kits are perpetual products treated like subscription and will need to be handled later
          if (isSubscription && product.description !== TECHNICIAN && product.description !== DEPLOYMENT_KIT) {
            if (newSupportStatus === EXPIRED) {
              if (product.description === SITEDEPLOY) {
                return [
                  ...newLicenses,
                  {
                    licenseId: licenseKey,
                    statuses: [{ id: QTY, text: `Endpoints: ${splitSeats[i]}` }],
                  },
                ];
              }

              return [
                ...newLicenses,
                {
                  licenseId: licenseKey,
                  statuses: sortTags([{ id: EXPIRED, text: EXPIRED }, ...newTags]),
                  offlineActivationKey: splitOfflineKey[i],
                },
              ];
            }
            if (product.description === SITEDEPLOY) {
              return [
                ...newLicenses,
                {
                  licenseId: licenseKey,
                  statuses: sortTags([
                    {
                      id: getCurrentStatus(supportStatus),
                      text: getCurrentStatus(supportStatus),
                    },
                    {
                      id: QTY,
                      text: `Endpoints: ${splitSeats[i]}`,
                    },
                    {
                      id: `Renewal: ${localString}`,
                      text: `Renewal: ${localString}`,
                    },
                  ]),
                  offlineActivationKey: splitOfflineKey[i],
                },
              ];
            }
            return [
              ...newLicenses,
              {
                licenseId: licenseKey,
                statuses: sortTags([
                  {
                    id: getCurrentStatus(supportStatus),
                    text: getCurrentStatus(supportStatus),
                  },
                  {
                    id: `Renewal: ${localString}`,
                    text: `Renewal: ${localString}`,
                  },
                  ...newTags,
                ]),
                offlineActivationKey: splitOfflineKey[i],
              },
            ];
          }
          if (
            (product.description === TECHNICIAN || product.description === DEPLOYMENT_KIT) &&
            newSupportStatus === EXPIRED
          ) {
            newTags.push({ id: EXPIRED, text: EXPIRED });
          }

          if (newSupportStatus === BUSINESS_SUPPORT_TEXT)
            newTags.push({
              id: `Support Renewal: ${localString}`,
              text: `Support Renewal: ${localString}`,
            });
          if (newSupportStatus === HOME_SUPPORT_TEXT)
            newTags.push({
              id: `Support Expiry: ${localString}`,
              text: `Support Expiry: ${localString}`,
            });

          if (splitOrderStatuses[i] === UPGRADED) {
            // keys with an upgraded order status are old keys that have been upgraded
            // these keys are retired and a new key exists

            return newLicenses;
          }
          if (newSupportStatus === EXPIRED_SUPPORT_TEXT) {
            newTags.push({
              id: newSupportStatus,
              text: newSupportStatus,
            });
          }

          if (!isEmpty(splitV4Licenses[i]) && isUpgradeKey) {
            // this is an upgrade key that has had an inplace upgrade applied to it
            const newKey = KEY_TO_VERSION[licenseKey.split("")[0]];
            const newLicenseVersion = `V${newKey}`;
            const isDowngraded = newKey < KEY_TO_VERSION[splitV4Licenses[i].split("")[0]];

            const statuses = sortTags([
              {
                id: getCurrentStatus(supportStatus),
                text: getCurrentStatus(supportStatus),
              },
              { id: newLicenseVersion, text: newLicenseVersion },
              ...newTags,
            ]);
            const pairedKey = [splitV4Licenses[i]];
            if (!isEmpty(splitUpgrades[i]) && splitUpgrades[i].length === 34) pairedKey.push(splitUpgrades[i].length);
            if (newKey !== product.version) {
              if (supportStatus === ACTIVE) activeCount--;
              addFloatingLicense(
                toString(newKey),
                {
                  licenseId: licenseKey,
                  statuses,
                  pairedKey,
                  isDowngraded,
                  isVirtualBundle,
                  offlineActivationKey: splitOfflineKey[i],
                },
                product,
                supportStatus === ACTIVE,
                expiryDate,
                !isNil(foundOrder) && moment(foundOrder.m_transTime).isAfter(moment.unix(purchasedOn))
                  ? moment.unix(moment(foundOrder.m_transTime).unix()).utc()
                  : moment.unix(purchasedOn).utc(),
                isFinite(parseInt(splitSeats[i])) ? splitSeats[i] : 0,
                floatingLicenses
              );
              return newLicenses;
            }

            return [
              ...newLicenses,
              {
                licenseId: licenseKey,
                statuses,
                pairedKey: [splitV4Licenses[i], splitUpgrades[i]],
                isDowngraded,
                isVirtualBundle,
              },
            ];
          }

          if (isUpgradeKey) {
            // this is an upgrade key as opposed to a key subjected to an inplace upgrade
            // an inplace upgrade will change the original key and should be handled differently

            // no key has been paired against this upgrade key in the database
            // ergo it is unused
            const isSplitUpgradesEmpty = isEmpty(splitUpgrades[i]);
            const statuses = isSplitUpgradesEmpty
              ? [
                  { id: AVAILABLE, text: AVAILABLE },
                  {
                    id: licenseVersion,
                    text: licenseVersion,
                  },
                  {
                    id: UPGRADE_KEY,
                    text: UPGRADE_KEY,
                  },
                ]
              : [
                  {
                    id: getCurrentStatus(supportStatus),
                    text: getCurrentStatus(supportStatus),
                  },
                  { id: licenseVersion, text: licenseVersion },
                  ...newTags,
                ];
            if (isSplitUpgradesEmpty && isSiteManager && SITE_MANAGER_TYPES[splitKeyTypes[i]]) {
              statuses.push({
                id: SITE_MANAGER_TYPES[splitKeyTypes[i]],
                text: SITE_MANAGER_TYPES[splitKeyTypes[i]],
              });
            }
            return [
              ...newLicenses,
              {
                licenseId: licenseKey,
                statuses: sortTags(statuses),
                offlineActivationKey: splitOfflineKey[i],
                pairedKey: !isSplitUpgradesEmpty && splitUpgrades[i].length === 34 ? [splitUpgrades[i]] : noop(),
                isVirtualBundle,
              },
            ];
          }

          if (!isEmpty(splitV4Licenses[i])) {
            // this in a inplace upgrade
            const newKey = KEY_TO_VERSION[licenseKey.split("")[0]];
            const newLicenseVersion = `V${newKey}`;
            const isDowngraded = newKey < KEY_TO_VERSION[splitV4Licenses[i].split("")[0]];

            const statuses = [
              {
                id: getCurrentStatus(supportStatus),
                text: getCurrentStatus(supportStatus),
              },
              { id: newLicenseVersion, text: newLicenseVersion },
              ...newTags,
            ];

            if (newKey !== product.version) {
              if (supportStatus === ACTIVE) activeCount--;
              addFloatingLicense(
                toString(newKey),
                {
                  licenseId: licenseKey,
                  statuses: sortTags(statuses),
                  offlineActivationKey: splitOfflineKey[i],
                  pairedKey: [splitV4Licenses[i]],
                  isDowngraded,
                  isVirtualBundle,
                },
                product,
                supportStatus === ACTIVE,
                expiryDate,
                moment.unix(purchasedOn).utc(),
                isFinite(parseInt(splitSeats[i])) ? splitSeats[i] : 0,
                floatingLicenses
              );
              return newLicenses;
            }

            return [
              ...newLicenses,
              {
                licenseId: licenseKey,
                statuses: sortTags(statuses),
                offlineActivationKey: splitOfflineKey[i],
                pairedKey: [splitV4Licenses[i]],
                isDowngraded,
                isVirtualBundle,
              },
            ];
          }

          if (isUndefined(VERSIONLESS_LICENSES[product.description]))
            newTags.unshift({ id: licenseVersion, text: licenseVersion });

          const statuses = sortTags([
            {
              id: getCurrentStatus(supportStatus),
              text: getCurrentStatus(supportStatus),
            },
            ...newTags,
          ]);
          return [
            ...newLicenses,
            {
              licenseId: licenseKey,
              statuses,
              offlineActivationKey: splitOfflineKey[i],
              isVirtualBundle,
            },
          ];
        },
        []
      );
      /***** ****************** *****/
      /***** LICENCE LOOP STOPS *****/
      /***** ****************** *****/

      // If there are no valid licences to display then this product information is not needed
      if (isEmpty(newLicenses)) return newProductGroups;
      // check that this product name and version combination has already been encountered
      // if so - then edit existing object
      if (newProductGroups[productDescriptionWithVersion]) {
        return {
          ...newProductGroups,
          [productDescriptionWithVersion]: {
            ...newProductGroups[productDescriptionWithVersion],
            activeCount: newProductGroups[productDescriptionWithVersion].activeCount + activeCount,
            licenses: [...newProductGroups[productDescriptionWithVersion].licenses, ...newLicenses],
            seats: newProductGroups[productDescriptionWithVersion].seats + newSeats,
            totalLicenses: newTotalLicenses + newProductGroups[productDescriptionWithVersion].totalLicenses,
            firstExpiry: checkFirstExpiry(newProductGroups[productDescriptionWithVersion].firstExpiry, firstExpiry),
            nextExpiry: checkNextExpiry(newProductGroups[productDescriptionWithVersion].nextExpiry, nextExpiry),
            lastExpiry: checkLastExpiry(newProductGroups[productDescriptionWithVersion].lastExpiry, lastExpiry),
            lastPurchasedOn: checkLastExpiry(
              newProductGroups[productDescriptionWithVersion].lastPurchasedOn,
              moment.unix(purchasedOn).utc()
            ),
          },
        };
      }
      // else create new key on newProductGroups
      return {
        ...newProductGroups,
        [productDescriptionWithVersion]: {
          ...product,
          productDescriptionWithVersion: productDescriptionWithVersion,
          activeCount,
          licenses: newLicenses,
          seats: newSeats,
          totalLicenses: newTotalLicenses,
          firstExpiry,
          nextExpiry,
          lastExpiry,
          lastPurchasedOn: moment.unix(purchasedOn).utc(),
        },
      };
    },
    {}
  );

  // upgraded licence keys need to be moved next to each other
  // below we loop through products
  // to loop through their amalgmated licences and pair up upgraded/retired keys with their parent
  forEach(Object.keys(floatingLicenses), (key) => {
    if (newProducts[key]) {
      newProducts[key].licenses = [...newProducts[key].licenses, ...floatingLicenses[key].licenses];
      newProducts[key].seats = newProducts[key].seats + parseInt(floatingLicenses[key].seats);
      newProducts[key].activeCount = newProducts[key].activeCount + floatingLicenses[key].activeCount;

      newProducts[key].firstExpiry = checkFirstExpiry(newProducts[key].firstExpiry, floatingLicenses[key].firstExpiry);
      newProducts[key].nextExpiry = checkNextExpiry(newProducts[key].nextExpiry, floatingLicenses[key].nextExpiry);
      newProducts[key].lastExpiry = checkLastExpiry(newProducts[key].lastExpiry, floatingLicenses[key].lastExpiry);
      newProducts[key].lastPurchasedOn = checkLastExpiry(
        newProducts[key].lastPurchasedOn,
        floatingLicenses[key].lastPurchasedOn
      );
    } else {
      newProducts[key] = floatingLicenses[key];
    }
  });

  return reduce(
    newProducts,
    (newNewProducts, { licenses, ...product }) => {
      const count = createCount(licenses);
      return {
        ...newNewProducts,
        [product.productDescriptionWithVersion]: {
          ...product,
          licenses,
          count,
          sumCount: licenses.length,
          expiredCount: count[EXPIRED] ? count[EXPIRED] : 0,
        },
      };
    },
    {}
  );
});

// Merge Trials and Products so we have more control over them
export const getCurrentProducts = createSelector([getTrials, getLicenseGroups], (trials, products) => {
  const orderedTrials = orderBy(trials, "expireTimes");
  const orderedProducts = Object.values(products).sort(({ version }, { version: versionB }) => {
    if (toNumber(version) === toNumber(versionB)) return 0;

    return toNumber(version) > toNumber(versionB) ? -1 : 1;
  });

  return [...orderedTrials, ...orderedProducts];
});

/*
 * Takes the list of products (by version) and converts it into a list of products (ignoring the version)
 */

export const getLicensesForLicensesPage = createSelector([getLicenseGroups, getLicenseFilters], (products, filters) =>
  reduce(
    products,
    (newProductGroups, product, key) => {
      const [productName] = split(key, "&");
      const currentProductData = newProductGroups[productName] || {};
      const {
        totalLicenses = 0,
        licenses = [],
        activeCount,
        count: currentCount,
        sumCount: currentSumCount = 0,
        firstExpiry: currentFirstExpiry,
        nextExpiry: currentNextExpiry,
        lastExpiry: currentLastExpiry,
      } = currentProductData;
      const newTotalLicenses = product.totalLicenses + totalLicenses;
      const newCount = isUndefined(currentCount) ? product.count : combineCounts(currentCount, product.count);

      const firstExpiry = checkFirstExpiry(currentFirstExpiry, product.firstExpiry);
      const nextExpiry = checkNextExpiry(currentNextExpiry, product.nextExpiry);
      const lastExpiry = checkLastExpiry(currentLastExpiry, product.lastExpiry);
      // loop through each licences tags to find if all the renewal / expiry dates are the same
      // or is everything expired
      // or what is the next expiry/renewal

      const allRenewalSame = moment(nextExpiry).isSame(moment(lastExpiry));
      const allExpired = moment().isAfter(moment(lastExpiry));
      const countMatchesLength = reduce(
        newCount,
        (countMatches, value, k) => (value === newTotalLicenses ? countMatches : false),
        true
      );

      // each product card has a filter object
      // pseudo code: const filters = { "Reflect Workstation": {}, "Site Manager": {} };
      const activeFilter = filters[productName];

      const filteredLicenses = isEmpty(activeFilter)
        ? product.licenses
        : filter(product.licenses, ({ statuses }) => {
            // if a status a licence has exists in the activeFilter object and is truthy
            // then it is allowed through the filter
            return reduce(
              activeFilter,
              (statusesHasAllFilters, filterStr) =>
                find(statuses, ({ id, text }) => id === filterStr || text === filterStr)
                  ? statusesHasAllFilters
                  : false,
              true
            );
          });
      let filteredCount = {};
      const combinedLicences = [...licenses, ...filteredLicenses];
      if (!isEmpty(activeFilter)) {
        filteredCount = createCount(combinedLicences, activeFilter);
      }
      return {
        ...newProductGroups,
        [productName]: {
          ...product,
          totalLicenses: newTotalLicenses,
          licenses: combinedLicences,
          activeCount:
            isUndefined(activeCount) && isUndefined(product)
              ? 0
              : isUndefined(activeCount)
              ? product.activeCount
              : isUndefined(product)
              ? activeCount
              : activeCount + product.activeCount,
          count: arrayToObject(sortTags(objectToArray(newCount))),
          filteredCount,
          sumCount: currentSumCount + product.sumCount,
          expiredCount: newCount[EXPIRED] ? newCount[EXPIRED] : 0,
          tagSystemNeeded: !countMatchesLength,
          allRenewalSame,
          firstExpiry,
          nextExpiry,
          lastExpiry,
          allExpired,
        },
      };
    },
    {}
  )
);

export const getLicencesCount = createSelector([getLicenseGroups], (products) =>
  reduce(products, (count, { licenses }) => count + licenses.length, 0)
);

export const filterArrayToObject = createSelector([getLicenseFilters], (filters) =>
  reduce(
    filters,
    (newFilters, arr, key) => ({
      ...newFilters,
      [key]: reduce(
        arr,
        (newObj, el) => {
          newObj[el] = true;
          return newObj;
        },
        {}
      ),
    }),
    {}
  )
);

/**
 * Calculates if user has any active support or any active subscription (which includes support)
 */
export const hasActiveSupport = createSelector([getLicenseGroups], (licenseGroups) =>
  some(licenseGroups, (product) => moment().isBefore(moment(product.lastExpiry)))
);
