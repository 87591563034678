import React from "react";
import { MIcon } from "@macrium/shared-components";

/* To be changed to an actual Icon */
const PortalLogo = () => (
  <div className="bgGrey1 bold auth-layout-container__logo">
    <MIcon
      name="macrium logo"
      color="cWhite"
      size="large"
      className="mr1"
      viewBox="0 0 192 30"
      width="13rem"
      height="2rem"
    />
  </div>
);

export default PortalLogo;
