import { isMobile, isTablet, isIOS } from "react-device-detect";
import { Divider } from "semantic-ui-react";
import { EDITION_TO_PRODUCT_DESCRIPTION } from "../../constants/licenses-products";
import CreateAnAccount from "./CreateAnAccount";

export const TrialMessage = ({ trialDetails }) => {
  const { edition } = trialDetails;
  const productName = EDITION_TO_PRODUCT_DESCRIPTION[edition];

  const isMobileDevice = isMobile || isTablet || isIOS;

  return (
    <>
      <CreateAnAccount
        header={`Download ${productName} Trial`}
        subHeader="Create an account or login to start your free trial now"
        hideForMobile={isMobileDevice}
      />
      <Divider />
    </>
  );
};
