import { PRIVACY_POLICY } from "../../../constants/urls";
import "./PrivacyPolicy.styles.scss";

const PrivacyPolicy = () => {
  return (
    <div data-testid="privacy-policy" className="policy">
      By registering, you are agreeing to have your personal information managed in accordance with the terms of
      Macrium&apos;s{" "}
      <a className="link" href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
        Privacy Notice.
      </a>
    </div>
  );
};

export default PrivacyPolicy;
