import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { get } from 'lodash';

import { IDLE, LOADING, FAILED, SUCCESS } from '../actionStatuses';
import * as API from '../../app/api/authAccount';
import ROUTES from '../../app/routes';

const REDUCER_NAME = 'confirmEmail';

const initialState = {
  status: IDLE,
  errorMessage: '',
  alreadyActivated: false
};

export const confirmEmailAsync = createAsyncThunk(
  `${REDUCER_NAME}`,
  async (data, thunkAPI) => {
    const response = await API.confirmEmail(data);

    if(!response.success) return thunkAPI.rejectWithValue(response);

    thunkAPI.dispatch(push(ROUTES.CONFIRM_EMAIL));

    return response;
  }
);

export const confirmEmailReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState
  },

  extraReducers: builder => {
    builder
      .addCase(confirmEmailAsync.pending, state => {
        state.status = LOADING;
        state.errorMessage = '';
        state.alreadyActivated = false;
      })
      .addCase(confirmEmailAsync.fulfilled, (state, { payload }) => {
        state.status = SUCCESS;
        state.errorMessage = '';
        state.alreadyActivated = get(payload, 'data.alreadyActivated');
      })
      .addCase(confirmEmailAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.errorMessage = payload.message;
        state.alreadyActivated = false;
      })
  }
});

export const { clearState } = confirmEmailReducer.actions;

export default confirmEmailReducer.reducer;
