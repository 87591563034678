import { combineReducers } from '@reduxjs/toolkit';

import login from './login';
import register from './register';
import confirmEmail from './confirmEmail';
import tfa from './2fa';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';

export default combineReducers({
  login,
  register,
  confirmEmail,
  tfa,
  forgotPassword,
  resetPassword,
});