import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Input } from "semantic-ui-react";

import { setIsBlocking } from "../../../slices/prompt/prompt";

/**
 * This generic Edit Input input takes care of showing the "unsaved changes" popup
 */
const EditInput = ({ value, name, onChange, autoFocus, ...props }) => {
  const dispatch = useDispatch();

  /* Clear state when input is unmounted (user saves or cancels the form) */
  React.useEffect(() => {
    return () => dispatch(setIsBlocking(false));
  }, [dispatch]);

  return (
    <Input
      value={value || ""}
      onChange={(e) => {
        dispatch(setIsBlocking(true));
        onChange(e, name);
      }}
      className={`edit-input ${props.className}`}
      autoFocus={autoFocus}
      {...props}
    />
  );
};

EditInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

EditInput.defaultProps = {
  value: "",
  name: "",
  autoFocus: false,
};

export default EditInput;
