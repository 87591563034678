import React from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";
import { map } from "lodash";

import ProductCard from "../../generic/ProductCard/ProductCard";

import "./YourProducts.styles.scss";
import { useSelector } from "react-redux";
import { isEnterpriseUser } from "../../../selectors/basics";

const YourProducts = ({ licenses, error, extended, smallWidth, title, overViewPage }) => {
  const isEnterprise = useSelector(isEnterpriseUser);
  return (
    <div className="mb3">
      <h2>{isEnterprise ? "Enterprise Licensing" : title}</h2>

      {error ? (
        <Message negative>{error || "Unable to fetch products"}</Message>
      ) : (
        <div className={`product-list ${extended ? "extended" : ""}`}>
          {map(licenses, (license, index) => (
            <ProductCard
              key={index}
              data={license}
              extended={extended}
              smallWidth={smallWidth}
              overViewPage={overViewPage}
            />
          ))}
        </div>
      )}
    </div>
  );
};

YourProducts.propTypes = {
  licenses: PropTypes.array,
  error: PropTypes.string,
  extended: PropTypes.bool,
  title: PropTypes.string,
  overViewPage: PropTypes.bool,
};

YourProducts.defaultProps = {
  licenses: {},
  error: "",
  extended: true,
  title: "Your Products",
  overViewPage: false,
};

export default YourProducts;
