import {
  DEPLOYMENT_KIT,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  REFLECT_HOME_SUBSCRIPTION,
  SITEDEPLOY,
  SITE_MANAGER,
  SITE_MANAGER_SUBSCRIPTION,
  TECHNICIAN,
  TECHNICIAN_SUBSCRIPTION,
} from "../../constants/licenses";

const excludedProducts = [
  REFLECT_HOME_SUBSCRIPTION,
  SITEDEPLOY,
  SITE_MANAGER,
  SITE_MANAGER_SUBSCRIPTION,
  DEPLOYMENT_KIT,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  TECHNICIAN,
  TECHNICIAN_SUBSCRIPTION,
];
const showOfflineActivationOptions = (product) => !excludedProducts.includes(product);

export default showOfflineActivationOptions;
