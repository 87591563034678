import { isEmpty, get } from "lodash";
import { createSelector } from "reselect";

import { LOADING, SUCCESS, FAILED } from "../slices/actionStatuses";
import { parseAuthToken } from "../utils/auth";

// Auth
export const getAuthToken = (state) => get(state, "auth.login.authToken");
export const hasAuthTokenExpired = createSelector(
  [getAuthToken],
  (authToken) => !get(parseAuthToken(authToken), "exp")
);

// Login
export const getLoginState = (state) => get(state, "auth.login");
export const isLoadingLogin = (state) => get(state, "auth.login.status") === LOADING;
export const isLoggedIn = (state) => !isEmpty(get(state, "auth.login.authToken"));
export const getLoginForm = (state) => get(state, "auth.login.form");
export const needsActivationAfterLogin = (state) => get(state, "auth.login.needsActivationAfterLogin");
export const hasDoneInitialLoad = (state) => get(state, "auth.login.hasDoneInitialLoad");

// Login 2FA
export const tfaState = (state) => get(state, "auth.tfa");
export const isLogin2FALoading = (state) => get(state, "auth.tfa.status") === LOADING;
export const get2FACode = (state) => get(state, "auth.tfa.code");

// Register
export const getRegisterState = (state) => get(state, "auth.register");
export const isLoadingRegister = (state) => get(state, "auth.register.status") === LOADING;
export const isResendingEmailConfirmationEmail = (state) => get(state, "auth.register.resendStatus") === LOADING;
export const hasRegistered = (state) => get(state, "auth.register.status") === SUCCESS;
export const registerType = (state) => get(state, "auth.register.registerType");

// Confirm Email
export const isConfirmEmailSuccessful = (state) => get(state, "auth.confirmEmail.status") === SUCCESS;
export const isLoadingConfirmEmail = (state) => get(state, "auth.confirmEmail.status") === LOADING;
export const hasConfirmEmailFailed = (state) => get(state, "auth.confirmEmail.status") === FAILED;
export const alreadyActivated = (state) => get(state, "auth.confirmEmail.alreadyActivated");

// Forgot Password
export const getForgotPasswordState = (state) => get(state, "auth.forgotPassword");
export const getResetPasswordStatus = (state) => get(state, "auth.resetPassword.status");
export const getResetPasswordErrorMessage = (state) => get(state, "auth.resetPassword.errorMessage");
export const getCheckResetCodeStatus = (state) => get(state, "auth.resetPassword.status");
export const isResetPasswordSuccessful = (state) => get(state, "auth.resetPassword.status") === SUCCESS;

// Account Details
export const getAccountDetails = (state) => get(state, "account.details.data");
export const hasAcceptedCookies = (state) => get(state, "account.details.data.cookiesAccepted");
export const isLoadingDetails = (state) => get(state, "account.details.status") === LOADING;
export const isUpdatingEmail = (state) => get(state, "account.details.updatingEmailStatus") === LOADING;
export const isEmailChangePending = (state) => get(state, "account.details.data.emailUpdatePending", false);
export const getAccountEmail = (state) => get(state, "account.details.data.email");
export const isDetailsUpdating = (state) => get(state, "account.details.updatingAccountDetails") === LOADING;
export const isUserBusiness = (state) => get(state, "account.details.data.isBusiness");
export const needsBusinessProfiling = (state) => get(state, "account.details.data.needsBusinessProfiling");
export const getPrivacyPolicy = (state) => get(state, "account.details.data.policyAccepted");
export const isEnterpriseUser = (state) => get(state, "account.details.data.enterprise", false);
// Account Keys
export const getUpdateKeysErrorMessage = (state) => get(state, "account.keys.errorMessage");
export const isUpdatingKeys = (state) => get(state, "account.keys.status") === LOADING;
// Router
export const getSelectedRoute = (state) => get(state, "router.location.pathname");
export const getPreviousRoute = (state) => get(state, "router.location.state.from.pathname");
export const getSelectedRouteState = (state) => get(state, "router.location.state");

// Products
export const getProductsData = (state) => get(state, "products.products.data");
export const isFetchingProducts = (state) => get(state, "products.products.status") === LOADING;
export const getProductsError = (state) => get(state, "products.products.error");
export const isProductsLoaded = (state) => get(state, "products.products.loaded");
export const getLicenseFilters = (state) => get(state, "products.products.licensesFilter");

// Products - Product Range
export const getProductsRange = (state) => get(state, "products.productsRange.data");
export const isFetchingProductRange = (state) => get(state, "products.productsRange.status") === LOADING;

// Unassigned Licenses
export const getUnassignedLicensesData = (state) => get(state, "products.products.unassigned");
export const isFetchingUnassignedLicenses = (state) => get(state, "products.status") === LOADING;
export const getUnassignedLicensesError = (state) => get(state, "products.error");

// Unsaved Changes / Prompt
export const hasUnsavedChanges = (state) => get(state, "prompt.isBlocking");
export const isBlockingModalOpen = (state) => get(state, "prompt.open");
export const getRequestedPage = (state) => get(state, "prompt.requestedPage");

// Billing
export const getOrders = (state) => get(state, "billing.orders");
export const getBillingErrorMessage = (state) => get(state, "billing.error");
export const isFetchingBilling = (state) => get(state, "billing.status") === LOADING;

// Download
export const isDownloadingTrial = (state) => get(state, "downloads.status") === LOADING;

// Trials
export const getTrialProduct = (state) => get(state, "trials.product");
export const getIsProfiling = (state) => get(state, "trials.isProfiling");

// Onboarding
export const isOnboardingLoading = (state) => get(state, "onboarding.status") === LOADING;
export const getOnboardingProductCards = (state) => get(state, "onboarding.onboardingProductCards");
export const getLastDownloadedProduct = (state) => get(state, "onboarding.lastDownloaded");
export const getLastActiveProduct = (state) => get(state, "onboarding.lastActiveProduct");

//offline activation
export const isOfflineModalOpen = (state) => get(state, "offline.isModalOpen");
export const getOfflineProductDetails = (state) => get(state, "offline.product");
export const isOfflineCodeActivating = (state) => get(state, "offline.status") === LOADING;
export const getOfflineKey = (state) => get(state, "offline.offlineKey");
export const getCollectedCookieData = (state) => get(state, "offline.collectedCookieData");
export const getSelectedLicenseKey = (state) => get(state, "offline.selectedLicenseKey");
export const getOfflineError = (state) => get(state, "offline.error");
export const getOEMCompanyName = (state) => get(state, "offline.oemCompanyName");
export const getOEMFormFields = (state) => get(state, "offline.oemFormFields");
export const getOEMLogoUrl = (state) => get(state, "offline.oemCompanyLogoUrl");
export const getOEMFormPreamble = (state) => get(state, "offline.oemFormPreamble");
export const getActivationCode = (state) => get(state, "offline.activationCode");
