import { Button, Input } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import PropTypes from "prop-types";

const OEMOfflineForm = ({
  activationCode,
  formValue,
  setFormValue,
  handleOEMActivation,
  isActivating,
  companyLogo,
  oemFormPreamble,
  error,
}) => {
  return (
    <div>
      <div className=" bold my2">
        <div>Activation Code:</div>

        <div className="flex flex-center mt1">
          <span className=" pbhalf">{activationCode}</span>
        </div>
      </div>
      {oemFormPreamble && <p>{oemFormPreamble}</p>}
      {formValue.map((formField, index) => (
        <div key={formField.name} className=" bold mt mb3">
          <div>{formField.name}</div>

          <Input
            style={{ width: "100%" }}
            autoFocus={index === 0}
            placeholder={`Enter ${formField.name}...`}
            value={formField.value}
            onChange={(e) => {
              const newValue = formValue.map((field) => {
                if (field.name === formField.name) {
                  return { ...field, value: e.target.value };
                }
                return field;
              });
              setFormValue(newValue);
            }}
          />
        </div>
      ))}
      {error && <FormFieldError className="py1 red" error={error} />}
      <Button
        className="mt3 mb4"
        primary
        disabled={formValue.some((field) => !field.value) || isActivating}
        onClick={handleOEMActivation}
        loading={isActivating}
        content="Complete Activation"
      />
      {companyLogo && <img src={companyLogo} alt="Sysmex Logo" />}
    </div>
  );
};

export default OEMOfflineForm;

OEMOfflineForm.propTypes = {
  activationCode: PropTypes.string.isRequired,
  companyLogo: PropTypes.string,
  formValue: PropTypes.array.isRequired,
  setFormValue: PropTypes.func.isRequired,
  handleOEMActivation: PropTypes.func.isRequired,
  oemFormPreamble: PropTypes.string,
  isActivating: PropTypes.bool.isRequired,
  error: PropTypes.string,
};
OEMOfflineForm.defaultProps = {
  companyLogo: null,
  error: null,
  oemFormPreamble: null,
};
