import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "@macrium/shared-components";
import { push } from "connected-react-router";
import {
  isResetPasswordSuccessful,
  isFetchingProducts,
  isProductsLoaded,
  getProductsError,
  hasDoneInitialLoad,
  getAccountEmail,
  getAccountDetails,
} from "../../selectors/basics";
import { clearState as clearStateResetPassword } from "../../slices/auth/resetPassword";
import GettingStartedCard from "./GettingStarted/GettingStarted.jsx";
import SupportCard from "./SupportCard/SupportCard.jsx";
import YourProducts from "./YourProducts/YourProducts.jsx";
import * as TOAST_TYPES from "../../constants/toast";
import Page from "../generic/Page";
import { getCurrentProducts, hasActiveSupport } from "../../selectors/licenses";
import ProductsRange from "./ProductsRange.jsx";
import { Segment } from "semantic-ui-react";
import CopyToClipboard from "../generic/CopyToClipboard";
import ROUTES from "../../app/routes";
import OnboardingContainer from "../onboarding/OnboardingContainer.jsx";
import { getFullOfflineLicenseDetails } from "../../selectors/offlineActivation.js";
import { openModal, selectLicenseKey } from "../../slices/offlineActivation/offlineActivation.js";

const Overview = () => {
  const dispatch = useDispatch();
  const initialLoad = useSelector(hasDoneInitialLoad);
  const email = useSelector(getAccountEmail);
  const { firstName, urc, supportKey, showSupportPhoneNumber } = useSelector(getAccountDetails);
  const resetPasswordSuccessful = useSelector(isResetPasswordSuccessful);

  const licenses = useSelector(getCurrentProducts);

  const isLoading = useSelector(isFetchingProducts);
  const hasLoadedProducts = useSelector(isProductsLoaded);
  const error = useSelector(getProductsError);
  const hasSupport = useSelector(hasActiveSupport);
  const offlineActivationLicenseDetails = useSelector(getFullOfflineLicenseDetails);
  const goToProfile = () => dispatch(push(ROUTES.ACCOUNT_SETTINGS));

  useEffect(() => {
    if (resetPasswordSuccessful) {
      toast("Password has been reset successfully.", {
        type: TOAST_TYPES.SUCCESS,
      });

      dispatch(clearStateResetPassword());
    }
  }, [initialLoad, resetPasswordSuccessful, dispatch, email]);
  useEffect(() => {
    if (offlineActivationLicenseDetails?.selectedKey) {
      setTimeout(() => {
        dispatch(selectLicenseKey(offlineActivationLicenseDetails.selectedKey));
        dispatch(openModal(offlineActivationLicenseDetails));
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offlineActivationLicenseDetails]);

  return (
    <Page title="Overview">
      <Preloader loading={isLoading || !hasLoadedProducts} size="massive" />
      <OnboardingContainer />
      {isEmpty(licenses) && (
        <Segment raised>
          {firstName && <h3>Hi {firstName}, welcome to your Macrium Account</h3>}
          <p>
            Here, you are able to see all the trials associated with this account as well as all the products you own.
          </p>
          <div>
            If you are trying to install a Trial, copy the Registration Code from below or from the{" "}
            <span className="underline clickable" onClick={goToProfile}>
              Profile & Settings
            </span>{" "}
            page.
          </div>
          <p className="bold mt2">Registration Code:</p>
          <div className="flex">
            <CopyToClipboard textToCopy={urc} />
            {urc}
          </div>
        </Segment>
      )}

      {!isEmpty(licenses) && <YourProducts licenses={licenses} error={error} overViewPage />}

      <ProductsRange />

      <GettingStartedCard />

      {!isEmpty(licenses) && (
        <SupportCard hasSupport={hasSupport} supportKey={supportKey} showSupportPhoneNumber={showSupportPhoneNumber} />
      )}
    </Page>
  );
};

export default Overview;
