export const PARTNER_PORTAL = "https://portal.macrium.com";
export const ARTICLES_ALL = "https://knowledgebase.macrium.com/";
export const OFF_ACTIVATION_KB = "https://go.macrium.com/offlineactivationreflectkb";
export const USER_FORUM = "https://forum.macrium.com/";
export const GET_SUPPORT = "https://portal.macrium.com/renewalwizard1";
export const SUBMIT_TICKET = "https://help.macrium.com/support/tickets/new";
export const PRODUCT_SUPPORT_POLICY = "https://www.macrium.com/product-support-policy";
export const PRIVACY_POLICY = "https://www.macrium.com/terms-and-privacy-policy-full";
export const EULA_POLICY = "https://www.macrium.com/eula";
export const COOKIE_POLICY = "https://www.macrium.com/terms-and-privacy-policy-full";
export const YOUTUBE_LINK = "//www.youtube.com/macrium";
export const OFF_ACTIVATION_VIDEO_URL = "https://www.youtube.com/embed/Uhx0M5-Ello"
export const REFLECT_RELEASE_NOTES = "https://updates.macrium.com/reflect/v8/latest_release_notes.asp";
export const SITE_MANAGER_RELEASE_NOTES = "https://knowledgebase.macrium.com/display/MSM8/Release+Notes";

export const OLD_REFLECT_OFFLINE_ACTIVATION = "https://reflect.macrium.com/keyregistration.aspx";
