import React, { useState, useEffect } from "react";
import { getLocation } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Button } from "semantic-ui-react";
import { Preloader } from "@macrium/shared-components";

import { resetPasswordThunk, clearState, checkResetCodeThunk } from "../../../slices/auth/resetPassword";
import ROUTES from "../../../app/routes";
import {
  getResetPasswordStatus,
  getCheckResetCodeStatus,
  getResetPasswordErrorMessage,
} from "../../../selectors/basics";

import { LOADING, SUCCESS } from "../../../slices/actionStatuses";
import PasswordRequirements from "../../generic/PasswordRequirements";
import { checkPasswordRequirements } from "../../../utils/requirements";
import { GENRIC_ERROR_RESPONSE } from "../../../constants/error-codes";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const resetPasswordStatus = useSelector(getResetPasswordStatus);
  const resetPasswordError = useSelector(getResetPasswordErrorMessage);
  const checkResetCodeStatus = useSelector(getCheckResetCodeStatus);
  const { email, code } = useSelector(getLocation)?.query;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [passwordMismatchMessage, setPasswordMismatchMessage] = useState("");
  const history = useHistory();
  const [isCodeValid, setIsCodeValid] = useState(true);

  const { requirementsMetResults, allRequirementsMetResult, passwordsMatch, passwordStartEndsEmpty } =
    checkPasswordRequirements(password, confirmPassword);

  useEffect(() => {
    dispatch(checkResetCodeThunk({ code })).then((result) => {
      if (result.payload.success) {
        setIsCodeValid(true);
      } else {
        setIsCodeValid(false);
      }
    });
    return () => {
      dispatch(clearState());
    };
  }, [code, dispatch]);

  const handleSubmit = async () => {
    if (allRequirementsMetResult && passwordsMatch && !passwordStartEndsEmpty) {
      dispatch(
        resetPasswordThunk({
          password: password,
          email: email,
          code: code,
        })
      );
    } else {
      setShowValidation(true);

      if (!passwordsMatch) {
        setPasswordMismatchMessage("Passwords do not match");
      } else if (passwordStartEndsEmpty) {
        setPasswordMismatchMessage("Password cannot start or end with empty spaces");
      } else {
        setPasswordMismatchMessage("");
      }
    }
  };

  const redirectToLogin = () => {
    history.push(ROUTES.LOGIN);
  };
  const hasCodeRelatedError = resetPasswordError && resetPasswordError !== GENRIC_ERROR_RESPONSE;
  if (!isCodeValid || hasCodeRelatedError) {
    return (
      <div>
        <p>Password reset code invalid, please request a new one.</p>
        <Button onClick={redirectToLogin}>Back to Login</Button>
      </div>
    );
  }

  return (
    <div>
      <Preloader loading={resetPasswordStatus === SUCCESS} size="large" />

      <PasswordRequirements
        header="Create A New Secure Password"
        requirementsMetResults={requirementsMetResults}
        showValidation={showValidation}
      />

      <div>
        <p className="reset-password__label">New Password</p>
        <Input
          className="reset-password__input mb2"
          type="password"
          value={password}
          onChange={(e) => {
            setPasswordMismatchMessage("");
            setPassword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
            return;
          }}
        />
      </div>
      <div>
        <p className="reset-password__label">Re-enter Your Password</p>
        <Input
          className="reset-password__input"
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setPasswordMismatchMessage("");
            setConfirmPassword(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              return handleSubmit();
            }
            return;
          }}
        />
      </div>
      <div className="reset-password__error">{passwordMismatchMessage}</div>
      <Button
        loading={checkResetCodeStatus === LOADING}
        onClick={handleSubmit}
        content="Change Password"
        size="large"
        primary
        fluid
      />
    </div>
  );
};

export default ResetPassword;
