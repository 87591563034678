import React, { createContext, useState, useEffect, useContext } from "react";

const viewPortContext = createContext(window.innerWidth);

// A more efficient Provider would return a object of { breakpoint1: false, breakpoint2: true, breakpoint3: false}
// its state would only change once a breakpoint threshold had changed and reduce updates of components when resizing browser window

export const ViewPortProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleSetWidth = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleSetWidth);
    return () => window.removeEventListener("resize", handleSetWidth);
  }, []);

  return <viewPortContext.Provider value={width}>{children}</viewPortContext.Provider>;
};

export const useViewPort = () => useContext(viewPortContext);
