import React from "react";
import PropTypes from "prop-types";
import SubmitTicketButton from "../../generic/SubmitTicketButton";
import FieldWrapper from "../FieldWrapper/FieldWrapper";

const SupportNumber = ({ supportKey }) => {
  return (
    <FieldWrapper header="Support">
      <div className="account-details__section__right__item">
        <div className="account-details__section__right__item__wrapper">
          <div>
            <label className="bold">Support Number</label>
          </div>
          {supportKey}
        </div>
        <div className="account-details__btn-wrap">
          <SubmitTicketButton />
        </div>
      </div>
    </FieldWrapper>
  );
};

SupportNumber.propTypes = {
  supportKey: PropTypes.string.isRequired,
};

export default SupportNumber;
