import { createSelector } from 'reselect';

import { getLoginState, isLoadingLogin, isLoadingRegister } from './basics';

export const can2FA = createSelector(
  [getLoginState],
  ({ code1, form }) => !!(code1 && form)
);

export const disabledAuthTabs = createSelector(
  [isLoadingLogin, isLoadingRegister],
  (loadingLogin, loadingRegister) => loadingLogin && loadingRegister
);
