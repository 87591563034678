import React from "react";
import PropTypes from "prop-types";
import { map, isEmpty } from "lodash";

import { Segment, Divider } from "semantic-ui-react";

import ProductIcon from "../ProductIcon.jsx";
import LicenseCTAs from "./LicenseCTAs.jsx";
import LicenseDescription from "./LicenseDescription.jsx";
import LicenseExtraInfo from "./LicenseExtraInfo.jsx";
import LicenseHeader from "./LicenseHeader.jsx";
import LicenseUsage from "./LicenseUsage.jsx";
import TrialMachine from "./TrialMachine.jsx";

import { PERPETUAL, SUBSCRIPTION } from "../../../constants/licenses-products.js";

import "./ProductCard.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { LICENSES_DESCRIPTIVE_STRING } from "../../../constants/licenses.js";
import { getAccountDetails, getProductsRange, isDownloadingTrial } from "../../../selectors/basics";
import SubscriptionLabel from "../../licence/SubscriptionLabel/SubscriptionLabel.jsx";

const ProductCard = ({ data, extended, productRange, smallWidth, overViewPage }) => {
  const dispatch = useDispatch();
  const products = useSelector(getProductsRange);
  const isLoading = useSelector(isDownloadingTrial);
  const goTo = (value, state) => dispatch(push(value, state));

  const accountDetails = useSelector(getAccountDetails);

  return (
    <Segment raised className={`product-card ${extended ? "extended" : ""} ${smallWidth ? "small" : ""}`}>
      {data.isTrial === "1" && !extended && !productRange && <SubscriptionLabel isTrial />}

      <div className="product-card__header">
        <div>
          <div className="product-card__top">
            <div className="product-card__top__icon">
              <ProductIcon productName={data.description} />
            </div>
            <div className="product-card__top__name">{LICENSES_DESCRIPTIVE_STRING[data.description]}</div>
          </div>
          <Divider />
        </div>
        <LicenseDescription data={data} extended={extended} />
      </div>

      {extended && (
        <div className="product-card__extended">
          <Divider className="extended-divider" />

          <div className="product-card__product__extended">
            <LicenseHeader data={data} account={accountDetails} />
            <Divider />

            <div className="product-card__product__extended__middle">
              {data.isTrial === "1" && !isEmpty(data.installs) && (
                <>
                  {map(data.installs, (install, i) => (
                    <TrialMachine key={install.machineId} install={install} index={i} />
                  ))}
                </>
              )}

              {data.isTrial !== "1" && <LicenseUsage data={data} goTo={goTo} />}
            </div>
            <Divider />
            <LicenseExtraInfo data={data} />
          </div>
        </div>
      )}

      <div className="product-card__bottom">
        <LicenseCTAs
          data={data}
          productRange={productRange}
          overViewPage={overViewPage}
          products={products}
          isLoading={isLoading}
        />
      </div>
    </Segment>
  );
};

ProductCard.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    version: PropTypes.string,
    totalLicenses: PropTypes.number,
    licensesInUse: PropTypes.number,
    subscription: PropTypes.oneOf(["", SUBSCRIPTION, PERPETUAL]),
  }),
  extended: PropTypes.bool,
  productRange: PropTypes.bool,
  overViewPage: PropTypes.bool,
};

ProductCard.defaultProps = {
  data: {},
  extended: false,
  productRange: false,
  overViewPage: false,
};

export default ProductCard;
