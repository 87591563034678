import React from "react";
import PropTypes from "prop-types";

import EditInput from "./EditInput";

const EmailInput = ({ placeholder, value, onChange, disabled }) => (
  <EditInput
    placeholder={placeholder}
    autoFocus
    value={value}
    onChange={onChange}
    icon="envelope"
    iconPosition="left"
    className="email-form__input"
    disabled={disabled}
  />
);

EmailInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

EmailInput.defaultProps = {
  placeholder: "",
  value: "",
  disabled: false,
};

export default EmailInput;
