
import { push } from "connected-react-router";
import { getInitialAuthTokenForStore } from "../../utils/auth";
import { getHasLoggedInCookie } from "../../utils/getHasLoggedInCookie";
import { getTrialFromCookie } from "../../utils/getTrialFromCookie";
import ROUTES from "../routes";

/**
 * This middleware is to make any necessary redirects (or any data fetch when the page loads for the first time)
 */
const onFirstRender = store => next => action => {  
  try {
    if (action.type === "@@router/LOCATION_CHANGE" && action.payload.isFirstRendering && action.payload.location.pathname === ROUTES.OVERVIEW) {
      const sessionAuthToken = getInitialAuthTokenForStore();

      // if user is logged in automatically, continue as normal
      if (sessionAuthToken) return next(action);

      const trialProductDetails = getTrialFromCookie();
      
      // If user is trying to download a trial
      if (trialProductDetails) {        
        const hasLoggedIn = getHasLoggedInCookie();
        
        // Cookie comes from Cookies has a string
        let redirectTo = hasLoggedIn === "true" ? ROUTES.LOGIN : ROUTES.REGISTER;

        store.dispatch(push(redirectTo))
      }
    }

    return next(action);
  } catch (err) {
    console.log("Error on first render middleware ", err);

    return next(action);
  }
}

export default onFirstRender;
