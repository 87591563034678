import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Button, Popup } from "semantic-ui-react";
import * as DeviceDetect from "react-device-detect";
import { MIcon } from "@macrium/shared-components";

import { ARCH64, ARCH86, LEGACY_ARCH86_SM8, SUBSCRIPTION } from "../../../../constants/licenses-products";
import "./DownloadButton.styles.scss";
import { SITE_MANAGER, SITE_MANAGER_SUBSCRIPTION } from "../../../../constants/licenses";

const DownloadButton = ({ download, className, only64Bit, data, text, isLoading }) => {
  const isMobileOrTablet = DeviceDetect.isMobile || DeviceDetect.isTablet || DeviceDetect.isIOS;

  const options = React.useMemo(() => {
    const isSiteManager = data.description === SITE_MANAGER || data.description === SITE_MANAGER_SUBSCRIPTION;
    const isSiteManagerV8 = isSiteManager && (data.version === "8" || data.subscription === SUBSCRIPTION);

    const optns = [{ key: ARCH64, text: "64-bit", value: ARCH64, onClick: () => download(ARCH64) }];

    if (!only64Bit && !isSiteManagerV8)
      optns.push({ key: ARCH86, text: "32-bit", value: ARCH86, onClick: () => download(ARCH86) });

    if (isSiteManagerV8) {
      optns.push({
        key: LEGACY_ARCH86_SM8,
        text: (
          <div>
            <Popup
              className="fix-popup-alignment"
              trigger={
                <div className="flex">
                  32-bit <MIcon className="ml2" name="warning sign" size="small" color="cOrange" />
                </div>
              }
              content={
                <div>
                  <p className="bold">You are about to download an outdated version of Site Manager (8.0).</p>
                  <p>Site Manager version 8.1 doesn't support 32-bit architecture.</p>
                </div>
              }
              wide
              hoverable
              position="top center"
              up
            ></Popup>
          </div>
        ),
        value: LEGACY_ARCH86_SM8,
        onClick: () => download(LEGACY_ARCH86_SM8),
      });
    }

    return optns;
  }, [download, only64Bit, data]);
  return isMobileOrTablet ? null : (
    <div className={className}>
      <Button.Group>
        <Dropdown
          className="flex button basic primary dropdown-arch"
          options={options}
          trigger={<div className="dropdown-arch__content">{text}</div>}
          disabled={isLoading}
        />
      </Button.Group>
    </div>
  );
};

DownloadButton.propTypes = {
  text: PropTypes.any,
  download: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  only64Bit: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

DownloadButton.defaultProps = {
  text: "",
  disabled: false,
  className: "",
  only64Bit: false,
};

export default DownloadButton;
