import React from "react";
import PropTypes from "prop-types";
import { MIcon, Link } from "@macrium/shared-components";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import { replace } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";

import { downloadTrialAsync } from "../../../slices/downloads/downloads";
import { addOnboardingCardAsync } from "../../../slices/onboarding/onboarding";
import DownloadButton from "./DownloadButton/DownloadButton";
import {
  DEPLOYMENT_KIT,
  REFLECT_HOME,
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_SERVER,
  SITEDEPLOY,
  SITE_MANAGER,
  TECHNICIAN,
} from "../../../constants/licenses";
import {
  ARCH64,
  LEGACY_ARCH86_SM8,
  PRODUCTS_BUY_NOW,
  SITE_MANAGER_V8_x86_DOWNLOAD_URL,
  SUBSCRIPTION,
} from "../../../constants/licenses-products";
import { PRODUCT_SUPPORT_POLICY, SUBMIT_TICKET } from "../../../constants/urls";
import { getDownloadURLsForV8 } from "../../../selectors/products";
import { SITE_TYPE_USER_PORTAL } from "../../../utils/gtm/downloads";
import getDownloadUrl from "../../../utils/getDownloadUrl";
import BuyNowButton from "./BuyNowButton";
import { isLegacy } from "../../../utils/products";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import { CLICKED_DOWNLOAD_BUTTON, hotjarEvents } from "../../../constants/hotjarEvents";

const LicenseCTAs = ({ data, productRange, overViewPage, products, isLoading }) => {
  const dispatch = useDispatch();
  const downloadUrlsV8 = useSelector(getDownloadURLsForV8);
  const downloadTrial = async (arch) => {
    const args = {
      architecture: arch,
      edition: data.edition,
      productName: data.description,
    };
    if (data.description === REFLECT_SERVER || data.description === DEPLOYMENT_KIT || data.description === TECHNICIAN)
      args.description = data.description;
    dispatch(downloadTrialAsync(args, { siteType: SITE_TYPE_USER_PORTAL }));
  };
  const downloadUrl = getDownloadUrl(data, downloadUrlsV8);
  const isTechProduct = data.description === TECHNICIAN || data.description === DEPLOYMENT_KIT;
  const isNotEligibleForTrial = isTechProduct && (data.trialExpired || data.trialInstalled);
  const downloadProduct = (arch) => {
    if (window.hj) {
      window.hj("event", hotjarEvents[data.description] || CLICKED_DOWNLOAD_BUTTON);
    }
    const validOnboardingProducts = [REFLECT_HOME, REFLECT_HOME_SUBSCRIPTION];
    if (validOnboardingProducts.includes(data.description)) {
      const productEdition = products.find((prod) => prod.description === data.description)?.edition || null;
      const productName = REFLECT_HOME;
      dispatch(addOnboardingCardAsync({ edition: productEdition, description: productName }));
    }
    let url = "";
    if (arch === LEGACY_ARCH86_SM8) {
      url = SITE_MANAGER_V8_x86_DOWNLOAD_URL;
    } else {
      url = replace(downloadUrl, ARCH64, arch);
    }

    if (url) window.location.href = url;
  };

  const isLegacyProduct = isLegacy(data.version);
  if (isLegacyProduct && data.isTrial !== "1")
    return (
      <div className="product-card__bottom__buttons">
        <UpgradeButton pageHeader={false} />
        <DownloadButton
          isLoading={isLoading}
          download={downloadProduct}
          only64Bit={data.description === SITEDEPLOY}
          data={data}
          text={`Download${overViewPage ? "" : ` v${data.version}`}`}
        />
      </div>
    );
  return (
    <div className="product-card__bottom__buttons">
      {productRange && (
        <div className="flex flex-column">
          <div>
            {isNotEligibleForTrial ? (
              <div className="flex items-start mb2 ">
                <MIcon name="info circle" size="big" color="cGrey4" className="mr1" />
                <div>
                  To download another trial, please contact{" "}
                  <a
                    style={{ display: "inline-flex" }}
                    className="inline"
                    href={SUBMIT_TICKET}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Link>Support</Link>
                  </a>
                </div>
              </div>
            ) : (
              <DownloadButton
                download={downloadTrial}
                isLoading={isLoading}
                className="mb2"
                data={data}
                text="Download Trial"
                only64Bit={data.description === SITEDEPLOY || data.description === SITE_MANAGER}
              />
            )}
          </div>

          {PRODUCTS_BUY_NOW[data.description] && <BuyNowButton data={data} />}
        </div>
      )}
      {!productRange && (
        <>
          {data.isTrial === "1" ? (
            <BuyNowButton data={data} />
          ) : (
            <>
              {(data.subscription === SUBSCRIPTION || data.version >= "6") && !isMobile && !isTablet && !isIOS && (
                <div className="flex justify-between flex-end">
                  {downloadUrl && (
                    <DownloadButton
                      isLoading={isLoading}
                      download={downloadProduct}
                      only64Bit={data.description === SITEDEPLOY}
                      data={data}
                      text="Download"
                    />
                  )}
                </div>
              )}
              {data.subscription !== SUBSCRIPTION && data.version !== "10" && data.version < "6" && (
                <Button
                  as="a"
                  primary
                  basic
                  content="End of Life"
                  href={PRODUCT_SUPPORT_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

LicenseCTAs.propTypes = {
  data: PropTypes.object,
  productRange: PropTypes.bool,
  products: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};
LicenseCTAs.defaultProps = {
  data: {},
  productRange: false,
  products: [],
  isLoading: false,
};

export default LicenseCTAs;
