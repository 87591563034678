import { DEPLOYMENT_KIT, TECHNICIAN } from "../constants/licenses";

export const mapProductName = (productName) => {
  if (!productName) return undefined;
  if (productName.match(/Reflect \d+ Home/)) return "Reflect Home";
  if (productName.match(/Reflect \d+ Workstation/))
    return "Reflect Workstation";
  if (
    productName.includes("MAL Workstation") ||
    productName.includes("MAL Server")
  )
    return "SiteManager";
  if (productName.includes("Reflect Deployment Kit")) return "Deployment Kit";
  if (productName.match(/Reflect \d+ Server/)) return "Reflect Server";

  return productName;
};

/**
 * 
 * @param {string} description - name of the product
 * @param {number} sumCount - count of licenses
 * @param {number} expiredCount - count of expired licenses
 * @returns {number} user count
 */
export const getLicenseUserCount = (description, sumCount, expiredCount) => {
  if (description !== TECHNICIAN && description !== DEPLOYMENT_KIT) return 0;

  const active = sumCount - expiredCount;

  return description === TECHNICIAN ? active : (active * 5);
};