import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import * as TOAST_TYPES from "../../../constants/toast";
import CardMediaBox from "../CardMediaBox/CardMediaBox";
import CardProgressBox from "../CardProgressBox/CardProgressBox";
import "./FullOnBoardContainer.styles.scss";
import { saveLastStepCompletedAsync } from "../../../slices/onboarding/onboarding";
import { finalStage } from "../progressStageData";
import { REJECTED } from "../../../constants/redux-action-statuses";
import { isOnboardingLoading } from "../../../selectors/basics";

const findActiveStage = (stages) => {
  const nextStage = stages.find((stage) => !stage.isComplete);
  return nextStage ? nextStage : { ...finalStage, number: stages.length + 1 };
};

const FullOnBoardContainer = ({ currentProduct, handleClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isOnboardingLoading);
  const stageProgress = currentProduct.stages;
  const [currentStage, setCurrentStage] = useState(findActiveStage(currentProduct.stages));
  useEffect(() => {
    setCurrentStage(findActiveStage(currentProduct.stages));
  }, [currentProduct]);
  const handleIsComplete = async () => {
    const res = await dispatch(
      saveLastStepCompletedAsync({ id: currentProduct.id, lastCompletedStep: currentStage.number })
    );
    if (get(res, "meta.requestStatus") === REJECTED) {
      return toast("An error has occured", { type: TOAST_TYPES.ERROR });
    }
  };
  const allStagesAreComplete = !currentStage.videoStages;
  return (
    <div id={`${currentProduct.product}-onboarding`} className="onboardContainer">
      <CardProgressBox stageProgress={stageProgress} currentStage={currentStage} setCurrentStage={setCurrentStage} />
      <CardMediaBox
        videoUrl={currentProduct.videoUrl}
        isSaving={isLoading}
        stage={currentStage}
        handleIsComplete={handleIsComplete}
        productName={currentProduct.product}
        isCurrentStep={currentStage?.isActive || false}
        isFinished={allStagesAreComplete}
        handleClose={handleClose}
      />
    </div>
  );
};

export default FullOnBoardContainer;

FullOnBoardContainer.propTypes = {
  currentProduct: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.string,
    isComplete: PropTypes.bool,
    isActive: PropTypes.bool,
    videoUrl: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
};
