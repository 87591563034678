import React, { useState } from "react";
import PropTypes from "prop-types";
import { Segment, Label, Popup, Divider, Message } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import { get, isEmpty } from "lodash";
import plur from "plur";
import { LICENSES_DESCRIPTIVE_STRING } from "../../../constants/licenses.js";

import LicenseRows from "../LicenseRows/LicenseRows.jsx";
import ProductIcon from "../../generic/ProductIcon.jsx";
import TechnicianCount from "../TechnicianCount.jsx";
import SubscriptionLabel from "../SubscriptionLabel/SubscriptionLabel.jsx";
import SupportExpiry from "../SupportExpiry.jsx";
import TagFilter from "../TagFilter/TagFilter.jsx";

import "../LicensePage.styles.scss";
import "./ProductLicensesCard.styles.scss";
import { getLicenseUserCount } from "../../../utils/licenses.js";
import { SUBSCRIPTION } from "../../../constants/licenses-products.js";
import OfflineLicenseButton from "../../OfflineActivation/OfflineLicenseButton.jsx";
import showOfflineActivationOptions from "../../../utils/license/showOfflineActivationOptions.js";

const ProductLicensesCard = ({
  description,
  productNameToScroll,
  product,
  filters,
  isTechniciansLicense,
  isEnterprise,
}) => {
  const {
    licenses,
    error,
    activeCount,
    errorMessage,
    count: c,
    sumCount,
    filteredCount,
    tagSystemNeeded,
    allRenewalSame,
    allExpired,
    nextExpiry,
    lastExpiry,
    expiredCount,
    subscription,
  } = product;
  const activeFilters = get(filters, description);
  const { totalCount, ...count } = c;
  // Expand the accessories panel if product card is being filtered
  const [open, toggleOpen] = useState(!isEmpty(activeFilters) || !isTechniciansLicense);
  return (
    <Segment className={`mb3 ${description === productNameToScroll && "animation-highlight"}`} id={description}>
      <SubscriptionLabel isPerpetual={subscription !== SUBSCRIPTION} isExpired={allExpired} />
      <div className="licenses-container">
        <div className="licence-card__header-title licence-card__header-item">
          <ProductIcon productName={description} />
          <h3 className="m0 pr3">{LICENSES_DESCRIPTIVE_STRING[description]}</h3>
        </div>
        {isEnterprise && !isTechniciansLicense && (
          <div className="license-card__header-text-container licence-card__header-item">
            <p className="m0 license-card__large-text">Enterprise License</p>
          </div>
        )}
        {!isEnterprise && !isTechniciansLicense && (
          <div className="license-card__header-text-container licence-card__header-item">
            <p className="m0 license-card__small-text">License Keys</p>
            <p className="m0 license-card__medium-text">
              You have <b>{sumCount}</b> license {plur("key", sumCount)} with <b>{activeCount}</b> installed
            </p>
            {showOfflineActivationOptions(description) && <OfflineLicenseButton data={product} />}
          </div>
        )}
        {isTechniciansLicense && (
          <TechnicianCount sumCount={sumCount} userCount={getLicenseUserCount(description, sumCount, expiredCount)} />
        )}
        <SupportExpiry
          description={description}
          allRenewalSame={allRenewalSame}
          allExpired={allExpired}
          nextExpiry={nextExpiry}
          lastExpiry={lastExpiry}
          subscription={subscription}
        />
      </div>
      {open && (
        <>
          <Divider className="m0 pb3" />
          <TagFilter
            tagSystemNeeded={tagSystemNeeded}
            count={count}
            totalCount={totalCount}
            filteredCount={filteredCount}
            allRenewalSame={allRenewalSame}
            description={description}
            filters={filters}
            allExpired={allExpired}
          />
          {error && <Message className="m0" negative content={errorMessage} />}
          {isEmpty(licenses) && !isEmpty(filters[description]) && (
            <Message className="m0" info content={"No licenses available"} />
          )}
          <LicenseRows
            i={description}
            data={licenses}
            count={count}
            description={description}
            allRenewalSame={allRenewalSame}
            allExpired={allExpired}
          />
        </>
      )}
      {isTechniciansLicense && (
        <div data-testid="techPopUp" className="flex flex-center" style={{ justifyContent: "end" }}>
          <span>{`${open ? "Hide license keys" : "Show license keys"}`}</span>
          <Popup
            className="fix-popup-alignment"
            trigger={
              <div>
                <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
              </div>
            }
            content={
              <div>
                <p className="m0">
                  Each technician licence comes with a complementary Reflect Server license key
                  <br />
                  <br />
                  While a technician subscription is active the license key can be used to create the technician's
                  portable application
                </p>
              </div>
            }
            wide
            position="bottom right"
          />
        </div>
      )}
      {isTechniciansLicense && (
        <Label
          circular
          attached="bottom right"
          className="bgWhite chevron-container"
          as="a"
          onClick={() => toggleOpen(!open)}
          data-testid="licenseToggle"
        >
          <MIcon name={open ? "chevron up" : "chevron down"} color="cGrey5" />
        </Label>
      )}
    </Segment>
  );
};

ProductLicensesCard.propTypes = {
  description: PropTypes.string,
  productNameToScroll: PropTypes.string,
  product: PropTypes.shape({
    sumCount: PropTypes.number,
    allRenewalSame: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    nextExpiry: PropTypes.object,
    lastExpiry: PropTypes.object,
    allExpired: PropTypes.bool,
    count: PropTypes.object,
    totalCount: PropTypes.number,
    activeCount: PropTypes.number,
    expiredCount: PropTypes.number,
    licenses: PropTypes.array,
    tagSystemNeeded: PropTypes.bool,
    filteredCount: PropTypes.object,
    subscription: PropTypes.string,
  }),
  filters: PropTypes.object,
  isTechniciansLicense: PropTypes.bool.isRequired,
  isEnterprise: PropTypes.bool,
};

ProductLicensesCard.defaultProps = {
  description: "",
  productNameToScroll: "",
  filters: {},
  isEnterprise: false,
};

export default ProductLicensesCard;
