import { Button, Modal } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import PropTypes from "prop-types";
import OfflineKeyActions from "../../../OfflineActivation/OfflineActivationSteps/OfflineKeyActions";
import OfflineHeader from "../../../OfflineActivation/OfflineActivationModal/OfflineHeader";
import SubmitTicketButton from "../../../generic/SubmitTicketButton";

const OfflineKeyModal = ({ offlineKey, close, showWarning }) => {
  return (
    <Modal open={true} onClose={close}>
      <Modal.Header>
        <OfflineHeader closeModal={close} />
      </Modal.Header>
      <Modal.Content>
        {offlineKey === "0" ? (
          <div className="my2">
            <p>Sorry, we are unable to find the Offline Key for this License.</p>
            <p>Please contact support by clicking 'Submit A Ticket'.</p>
          </div>
        ) : (
          <OfflineKeyActions offlineKey={offlineKey} previouslyOfflined />
        )}
        {showWarning && (
          <div data-testid="offline-expiry-warning">
            <FormFieldError
              className="red py1"
              error="Your licence will need re-activating soon, once renewed you will be able to re-activate the device using the offline key below."
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        {offlineKey === "0" && <SubmitTicketButton size="medium" />}
        <Button primary content="Close" onClick={close} />
      </Modal.Actions>
    </Modal>
  );
};

export default OfflineKeyModal;
OfflineKeyModal.propTypes = {
  offlineKey: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  showWarning: PropTypes.bool,
};
OfflineKeyModal.defaultProps = {
  showWarning: false,
};
