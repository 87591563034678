import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Message } from "semantic-ui-react";

import { getLoginState, getSelectedRoute } from "../../selectors/basics";
import ROUTES from "../../app/routes";
import TandCPolicy from "./TandCPolicy/TandCPolicy";

const AuthContainer = ({ children, className }) => {
  const { universalMessage } = useSelector(getLoginState);
  const currentRoute = useSelector(getSelectedRoute);

  return (
    <div id="macrium-auth-segment" className={`relative ${className}`}>
      <div className="macrium-auth-segment__container ">
        <div className="macrium-auth-segment__content">
          {universalMessage && currentRoute === ROUTES.LOGIN && (
            <div className="macrium-auth-universal-warning fullwidth">
              <Message icon="circle info" content={universalMessage} />
            </div>
          )}
          {children}
        </div>
      </div>
      <div className="macrium-auth-segment__container pb2">
        <TandCPolicy loggedIn={false} />
      </div>
    </div>
  );
};

AuthContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

AuthContainer.defaultProps = {
  children: null,
  className: "",
};

export default AuthContainer;
