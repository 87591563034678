import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../app/api/authAccount";
import { IDLE, LOADING, FAILED, SUCCESS } from "../actionStatuses";
import { loginAfterPasswordResetAsync } from "./login";
import { toast } from "react-toastify";
import * as TOAST_TYPES from "../../constants/toast";
import { GENRIC_ERROR_RESPONSE } from "../../constants/error-codes";

const REDUCER_NAME = "resetPassword";

const initialState = {
  status: IDLE,
  errorMessage: "",
  success: false,
};

export const resetPasswordThunk = createAsyncThunk(`${REDUCER_NAME}/resetPassword`, async (data, thunkAPI) => {
  const response = await API.resetPassword(data);

  if (!response.success) {
    if (response.message === GENRIC_ERROR_RESPONSE) {
      toast("Password reset failed. Please try again.", { type: TOAST_TYPES.ERROR });
    }
    return thunkAPI.rejectWithValue(response);
  }
  thunkAPI.dispatch(loginAfterPasswordResetAsync(data));
  return response;
});

export const checkResetCodeThunk = createAsyncThunk(`${REDUCER_NAME}/checkResetCode`, async ({ code }, thunkAPI) => {
  const response = await API.checkResetCode({ code });

  if (!response.success) return thunkAPI.rejectWithValue(response);
  return response;
});

export const resetPasswordSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordThunk.pending, (state) => {
        state.status = LOADING;
        state.errorMessage = "";
        state.success = false;
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.status = SUCCESS;
        state.errorMessage = "";
        state.success = true;
      })
      .addCase(resetPasswordThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.errorMessage = payload.message;
        state.success = false;
      })
      .addCase(checkResetCodeThunk.fulfilled, (state) => {
        state.status = IDLE;
        state.errorMessage = "";
        state.success = true;
      })
      .addCase(checkResetCodeThunk.rejected, (state) => {
        state.status = FAILED;
        state.errorMessage = "Password reset code invalid, please request a new one.";
        state.success = false;
      });
  },
});

export const { clearState } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
