import React from "react";
import { MIcon } from "@macrium/shared-components";
import { Segment, Header } from "semantic-ui-react";
import YouTubeImg from "./YouTube.png";
import { USER_FORUM, ARTICLES_ALL, YOUTUBE_LINK } from "../../../constants/urls.js";
import ExternalLink from "../ExternalLink.jsx";

const GettingStarted = () => (
  <Segment raised className="mb3">
    <div className="flex justify-between getting-started-container">
      <div style={{ maxWidth: "40em" }} className="getting-started-container__body">
        <Header className="mt0 mb1" as="h3" content="Need Help Getting Started?" />
        <p className="mb3">
          Have a read through our most read Knowledgebase documentation to quickly get you on your way, or take a look
          at our extensive user forum
        </p>
        <div className="getting-started-container__ctas">
          <ExternalLink content="See All Articles" url={ARTICLES_ALL} />
          <ExternalLink content="User Forum" url={USER_FORUM} />
          <ExternalLink
            hasOwnLogo
            content={
              <div className="flex flex-center">
                <p className="bold m0 flex-grow center mr1">How-To-Guides</p>{" "}
                <img src={YouTubeImg} style={{ height: "23px" }} alt="youTube" />
              </div>
            }
            url={YOUTUBE_LINK}
          />
        </div>
      </div>
      <MIcon name="help image" viewBox="0 0 194 166" className="getting-started-container__img" />
    </div>
  </Segment>
);

export default GettingStarted;
