import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOADING, IDLE, FAILED } from "../actionStatuses.js";
import { get } from "lodash";
import { getOfflineCode, postOEMActivation } from "../../app/api/offlineActivation.js";

const REDUCER_NAME = "offline";

const initialState = {
  status: IDLE,
  error: "",
  isModalOpen: false,
  product: null,
  offlineKey: null,
  collectedCookieData: null,
  selectedLicenseKey: null,
  oemFormFields: null,
  oemCompanyName: null,
  oemCompanyLogoUrl: null,
  oemFormPreamble: null,
  activationCode: null,
};
export const getOfflineKeyAsync = createAsyncThunk(`${REDUCER_NAME}/validateOfflineCode`, async (data, thunkAPI) => {
  const res = await getOfflineCode(data);
  if (!res.success) return thunkAPI.rejectWithValue(res);
  return res;
});
export const activateOEMLicense = createAsyncThunk(`${REDUCER_NAME}/activateOEMLicense`, async (data, thunkAPI) => {
  const res = await postOEMActivation(data);
  if (!res.success) return thunkAPI.rejectWithValue(res);
  return res;
});
export const offlineSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isModalOpen = false;
      state.product = null;
      state.error = "";
      state.selectedLicenseKey = null;
      state.collectedCookieData = null;
      state.offlineKey = null;
      state.oemFormPreamble = null;
      state.activationCode = null;
    },
    openModal: (state, { payload: product }) => {
      state.isModalOpen = true;
      state.product = product;
    },
    clearOfflineKey: (state) => {
      state.offlineKey = null;
      state.oemFormFields = null;
      state.oemCompanyName = null;
      state.oemCompanyLogoUrl = null;
      state.oemFormPreamble = null;
      state.error = "";
      state.activationCode = null;
    },
    selectActivationCode: (state, { payload }) => {
      state.activationCode = payload;
    },
    selectLicenseKey: (state, { payload }) => {
      state.selectedLicenseKey = payload;
      state.error = "";
    },
    addCookieData: (state, { payload }) => {
      state.collectedCookieData = payload;
    },
    removeCookieData: (state) => {
      state.collectedCookieData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfflineKeyAsync.pending, (state) => {
        state.status = LOADING;
        state.offlineKey = null;
        state.error = "";
      })
      .addCase(getOfflineKeyAsync.fulfilled, (state, { payload }) => {
        const response = get(payload, "summary");
        state.status = IDLE;
        state.offlineKey = response?.key || null;
        state.error = "";
        state.oemFormFields = response?.inputFields ? JSON.parse(response?.inputFields) : null;
        state.oemCompanyName = response?.companyName || null;
        state.oemCompanyLogoUrl = response?.companyLogo || null;
        state.oemFormPreamble = response?.frontEndPreamble || null;
      })
      .addCase(getOfflineKeyAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.message;
        state.offlineKey = null;
      })
      .addCase(activateOEMLicense.pending, (state) => {
        state.status = LOADING;
        state.error = "";
      })
      .addCase(activateOEMLicense.fulfilled, (state) => {
        state.status = IDLE;
        state.error = "";
      })
      .addCase(activateOEMLicense.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.message;
      });
  },
});
export const {
  openModal,
  closeModal,
  clearOfflineKey,
  addCookieData,
  removeCookieData,
  selectLicenseKey,
  selectActivationCode,
} = offlineSlice.actions;
export default offlineSlice.reducer;
