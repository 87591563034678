import React from "react";

import "./ToastWithHeader.styles.scss";

export const createToastWithHeader = (header, content) => (
  <div className="toast-with-header">
    <div className={`${!content ? "mb0" : ""} toast-with-header__header regular`}>{header}</div>
    {content && <div className="toast-with-header__content regular mt0">{content}</div>}
  </div>
);
