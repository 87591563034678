import { EDITION_TO_PRODUCT_DESCRIPTION } from "../../constants/licenses-products";

export const SITE_TYPE_USER_PORTAL = 'portal';
export const SITE_TYPE_WEB = 'web';

const COMPLETE_STATUS = "complete";

const gtmPushTrialDownload = (status) => ({ arch, edition, siteType }) => {
  window.dataLayer.push({
    event: `download_trial${status === COMPLETE_STATUS ? "_complete" : ""}`,
    arch,
    edition,
    product_name: EDITION_TO_PRODUCT_DESCRIPTION[edition],
    site_type: siteType
  });
}

export const gtmPushTrialDownloadStart = gtmPushTrialDownload();
export const gtmPushTrialDownloadComplete = gtmPushTrialDownload(COMPLETE_STATUS);
