import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import { noop } from "lodash";

import EditInput from "../../generic/Inputs/EditInput";
import FormFieldController from "../../generic/FormFieldController";
import PhoneInput from "../../generic/Inputs/PhoneInput";

const FormField = ({ label, value, name, rules, onSave, isPhone, editMode, errors, control }) => {
  return (
    <Form.Field error={rules ? !!errors[name] : false}>
      <div className="mb1 bold">{label}</div>

      {editMode ? (
        <div>
          <FormFieldController
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ref, ...field } }) =>
              isPhone ? (
                <PhoneInput name="phone" showIcon={false} placeholder="" onSave={onSave} {...field} />
              ) : (
                <EditInput {...field} name={name} autoFocus={name === "firstName"} />
              )
            }
          />
          <FormFieldError className="py1" error={errors[name]?.message} />
        </div>
      ) : (
        <div>{value || "-"}</div>
      )}
    </Form.Field>
  );
};
FormField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  editMode: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  onSave: PropTypes.func,
};

FormField.defaultProps = {
  label: "",
  value: "",
  rules: undefined,
  isPhone: false,
  errors: {},
  onSave: noop,
};

export default FormField;
