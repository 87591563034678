import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./ProgressBar.styles.scss";

const ProgressBar = ({ progress }) => {
  const [currentProgress, setCurrentProgress] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setCurrentProgress(progress);
    }, 300);
  }, [progress]);
  return (
    <div className="progressContainer">
      <div className="basicProgressBar">
        <div style={{ width: `${currentProgress}%` }} className="progressBar" />
      </div>
      <div className="bold ml2">{progress}%</div>
    </div>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};
