import React, { useEffect, useState } from 'react';
import { getLocation, push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from '@macrium/shared-components';
import { Icon } from 'semantic-ui-react';

import {
  isConfirmEmailSuccessful, isLoadingConfirmEmail, hasConfirmEmailFailed, alreadyActivated
} from '../../selectors/basics';
import { confirmEmailAsync, clearState } from '../../slices/auth/confirmEmail';
import ResendLink from '../generic/resendLink.jsx';
import { resendEmailConfirmationEmailThunk } from '../../slices/auth/register';
import ROUTES from '../../app/routes';

const initialCountDown = 5;
  
const ConfirmEmail = () => {  
  const dispatch = useDispatch();
  const [count, setCount] = useState(initialCountDown);

  const accountAlreadyActivated = useSelector(alreadyActivated);
  const { email, code } = useSelector(getLocation)?.query;

  const canActivate = !!(email && code) && !accountAlreadyActivated;

  const successful = useSelector(isConfirmEmailSuccessful);
  const loading = useSelector(isLoadingConfirmEmail);
  const failed = useSelector(hasConfirmEmailFailed);
  const confirmEmail = data => dispatch(confirmEmailAsync(data));

  const redirectUserAndClear = React.useCallback(() => {
    dispatch(clearState());

    return dispatch(push(ROUTES.LOGIN));
  }, [dispatch]);
    
  useEffect(() => {
    if(!successful) return;

    if (count === 0) return redirectUserAndClear();

    const intervalId = setInterval(() => {
      setCount(count - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [successful, count, setCount, redirectUserAndClear]);

  useEffect(()=> {
    confirmEmail({ email, code });

    // componentDidMount
    // eslint-disable-next-line
  }, []);

  return (
    <div className="macrium-activate-account">
      <Preloader loading={loading} content="Activating Your Account" />
      
      {successful && <Icon name="close" className="macrium-activate-account-close mr0 clickable" onClick={redirectUserAndClear} />}
      
      <div>
        <h2 className="mb0">{successful ? `Account ${accountAlreadyActivated ? 'Already' : ''} Activated` : 'Activation Error'}</h2>

        <p>{successful ? `We'll redirect you to the log in page in ${count} seconds.` : `Sorry something went wrong activating your account. ${canActivate ? 'Please try again by clicking the link below.' : ''}`}</p>

        {(canActivate && failed) && (
          <ResendLink
            content="Resend Email"
            onClick={() => dispatch(resendEmailConfirmationEmailThunk({ email }))}
          />
        )}
      </div>
    </div>
  );
}

export default ConfirmEmail;
