import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import CopyToClipboard from "../../generic/CopyToClipboard";
import { Link, FormFieldError } from "@macrium/shared-components";
import OfflineStepOne from "../../OfflineActivation/OfflineActivationSteps/OfflineStepOne";
import { useCallback, useEffect, useState } from "react";
import OfflineStepTwo from "../../OfflineActivation/OfflineActivationSteps/OfflineStepTwo";
import {
  activateOEMLicense,
  clearOfflineKey,
  getOfflineKeyAsync,
  removeCookieData,
  selectActivationCode,
  selectLicenseKey,
} from "../../../slices/offlineActivation/offlineActivation";
import { REJECTED } from "../../../constants/redux-action-statuses";
import {
  getCollectedCookieData,
  getOEMFormFields,
  getOEMFormPreamble,
  getOEMLogoUrl,
  getOfflineError,
  getOfflineKey,
  getActivationCode,
  isOfflineCodeActivating,
  getSelectedLicenseKey,
} from "../../../selectors/basics";
import "../../OfflineActivation/OfflineActivationModal/OfflineActivationModal.styles.scss";
import OfflineHeader from "../../OfflineActivation/OfflineActivationModal/OfflineHeader";
import { Input } from "semantic-ui-react";
import ROUTES from "../../../app/routes";
import { ACTIVATION_CODE, KEY } from "../../../constants/params";
import OEMOfflineForm from "../OEMOfflineForm/OEMOfflineForm";
import OEMCompleted from "../OEMCompleted/OEMCompleted";
import OEMWelcomPage from "../OEMWelcomPage/OEMWelcomPage";
import { INVALID_LICENSE_KEY, NOT_VALID_OEM_LICENSE } from "../../../constants/error-codes";
import { useLocation } from "react-router-dom";

const arrayToObject = (res) => {
  const resultObject = {};
  res.forEach((item) => {
    resultObject[item.name] = item.value;
  });
  return resultObject;
};

const OfflineActivationBasic = () => {
  const dispatch = useDispatch();
  const isActivating = useSelector(isOfflineCodeActivating);
  const challengeCode = useSelector(getOfflineKey);
  const formFields = useSelector(getOEMFormFields);
  const companyLogo = useSelector(getOEMLogoUrl);
  const offlineData = useSelector(getCollectedCookieData);
  const error = useSelector(getOfflineError);
  const oemFormPreamble = useSelector(getOEMFormPreamble);
  const storedActivationCode = useSelector(getActivationCode);
  const licenseKey = useSelector(getSelectedLicenseKey);
  const isOemLocationURL = useLocation()?.pathname === ROUTES.OEM_ACTIVATION;
  const isOem = isOemLocationURL || formFields?.length > 0;

  const key = get(offlineData, KEY);
  const activationCode = get(offlineData, ACTIVATION_CODE);
  const [updatedActivationCode, setUpdatedActivationCode] = useState(activationCode || "");
  const [formValue, setFormValue] = useState();
  const [stepNumber, setStepNumber] = useState(1);
  useEffect(() => {
    if (formFields?.length) {
      setFormValue(formFields.map((field) => ({ name: field.name, value: "" })));
    }
  }, [formFields]);
  useEffect(() => {
    if (key) {
      dispatch(selectLicenseKey(key));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);
  const goTo = useCallback((value) => dispatch(push(value)), [dispatch]);
  const handleActivation = async () => {
    dispatch(selectActivationCode(updatedActivationCode));

    const res = await dispatch(
      getOfflineKeyAsync({
        activationCode: updatedActivationCode,
        license: licenseKey,
        isOEM: !!isOemLocationURL,
      })
    );
    if (get(res, "meta.requestStatus") === REJECTED) {
      return res;
    }
    if (!isOemLocationURL && formFields?.length > 0) {
      setStepNumber(2);
      goTo(ROUTES.OEM_ACTIVATION);
    }
    setStepNumber(2);
  };
  const handleFinish = () => {
    const userHasCompletedSteps = stepNumber === 3;
    if (userHasCompletedSteps) {
      dispatch(removeCookieData());
    }
    goTo(ROUTES.LOGIN);
  };
  const handleOEMActivation = async () => {
    const res = await dispatch(
      activateOEMLicense({
        inputFields: JSON.stringify(arrayToObject(formValue)),
        activationCode: updatedActivationCode,
        license: licenseKey,
      })
    );
    if (get(res, "meta.requestStatus") === REJECTED) {
      return res;
    }
    setStepNumber(5);
    dispatch(removeCookieData());
  };
  const handleReset = () => {
    dispatch(clearOfflineKey());
    goTo(`${ROUTES.OEM_ACTIVATION}?key=${licenseKey}`);
    setStepNumber(1);
    setUpdatedActivationCode("");
    setFormValue();
  };
  const hasLicenseError = !!error && (error === INVALID_LICENSE_KEY || error === NOT_VALID_OEM_LICENSE);
  return (
    <div>
      <OfflineHeader stepNumber={null} closeModal={() => {}} isOem={isOem} />
      <div className=" bold my2">
        <div>Selected License Key:</div>
        {stepNumber === 1 ? (
          <Input
            fluid
            autoFocus={licenseKey === ""}
            placeholder="Enter License Key..."
            value={licenseKey}
            error={hasLicenseError}
            onChange={(e) => dispatch(selectLicenseKey(e.target.value))}
          />
        ) : (
          <div className="flex flex-center mt1">
            <span className=" pbhalf prhalf">{licenseKey}</span>
            <CopyToClipboard textToCopy={licenseKey} />
          </div>
        )}

        {hasLicenseError && <FormFieldError className="py1 red" error={error} />}
      </div>
      {/* basic offline activation steps */}
      {stepNumber === 1 && !isOem && (
        <OfflineStepOne
          notLoggedIn={true}
          selectedLicenseKey={licenseKey}
          activationCode={updatedActivationCode}
          setActivationCode={setUpdatedActivationCode}
          handleActivation={handleActivation}
          error={error}
          isActivating={isActivating}
        />
      )}
      {stepNumber === 2 && !formValue && (
        <OfflineStepTwo handleNextStep={() => setStepNumber(3)} offlineKey={challengeCode} />
      )}
      {stepNumber === 3 && (
        <div>
          <div className="my3">License key - {licenseKey} - was successfully offline activated.</div>
        </div>
      )}
      <div className="mt2 prhalf">
        {!isActivating && !isOem && <Link secondary fluid onClick={handleFinish} content="Return to login page" />}
      </div>
      {/* oem license activation steps */}
      {isOem && !formValue && stepNumber !== 5 && (
        <OEMWelcomPage
          activationCode={updatedActivationCode}
          setActivationCode={setUpdatedActivationCode}
          handleActivation={handleActivation}
          error={error}
          isActivating={isActivating}
          selectedLicenseKey={licenseKey}
        />
      )}

      {isOem && formValue && stepNumber !== 5 && (
        <OEMOfflineForm
          activationCode={storedActivationCode}
          formValue={formValue}
          setFormValue={setFormValue}
          handleOEMActivation={handleOEMActivation}
          isActivating={isActivating}
          companyLogo={companyLogo}
          oemFormPreamble={oemFormPreamble}
          error={error}
        />
      )}
      {stepNumber === 5 && <OEMCompleted handleReset={handleReset} />}
    </div>
  );
};

export default OfflineActivationBasic;
