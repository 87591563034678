import { SERVER, SITEDEPLOY, SITE_MANAGER, SITE_MANAGER_SUBSCRIPTION, VIRTUAL_SERVER, WORKSTATION } from "../../constants/licenses";


export const isProductSiteManager = productDescription => productDescription === SITE_MANAGER || productDescription === SITE_MANAGER_SUBSCRIPTION;
export const isProductSiteDeploy = productDescription => productDescription === SITEDEPLOY;

export const isProductSiteManagerOrSiteDeploy = productDescription => isProductSiteManager(productDescription) || isProductSiteDeploy(productDescription);

export const isLicenseSiteManagerType = (id) => id === VIRTUAL_SERVER || id === SERVER || id === WORKSTATION;

