import { memo } from "react";
import PropTypes from "prop-types";

const VideoContainer = memo(({ videoUrl }) => {
  return (
    <iframe
      data-testid="video-container"
      style={{
        aspectRatio: "16 / 9",
        width: "100%",
        border: "none",
      }}
      src={videoUrl}
      title="YouTube video player"
      controls="1"
      allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
});
VideoContainer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};
export default VideoContainer;
