import React from "react";
import PropTypes from "prop-types";
import { TagLabel } from "@macrium/shared-components";
import { EXPIRED } from "../../../constants/licenses";
import plur from "plur";

const hasTrialExpired = (expiresInHours) => expiresInHours <= 0;
const expiresInLess1Day = (expiresInHours) => expiresInHours < 24;

const TrialExpirationLabel = ({ expiresInHours, attached, includeTrialText, isExtendedTrial }) => {
  const hasExpired = hasTrialExpired(expiresInHours);
  const lessThan1DayToExpire = expiresInLess1Day(expiresInHours);

  let expirationText;

  if (hasExpired) {
    expirationText = EXPIRED;
  } else if (lessThan1DayToExpire) {
    expirationText = `${expiresInHours} ${plur("hour", expiresInHours)} left`;
  } else {
    const expiresInDays = Math.ceil(expiresInHours / 24);
    expirationText = `${expiresInDays} ${plur("day", expiresInDays)} left`;
  }

  if (includeTrialText)
    expirationText = `${includeTrialText ? `${isExtendedTrial ? "Extended" : ""} Trial - ` : ""}${expirationText}`;

  return (
    <TagLabel
      text={expirationText}
      className={hasExpired ? "borderPaleRed bgPaleRed cRed" : "borderPaleAmber bgPaleAmber cAmber"}
      attached={attached ? "top right" : null}
    />
  );
};

TrialExpirationLabel.propTypes = {
  expiresInHours: PropTypes.number,
  attached: PropTypes.bool,
  includeTrialText: PropTypes.bool,
  isExtendedTrial: PropTypes.bool,
};

TrialExpirationLabel.defaultProps = {
  expiresInHours: 0,
  attached: false,
  includeTrialText: true,
  isExtendedTrial: false,
};

export default TrialExpirationLabel;
