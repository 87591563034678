import React, { useState } from "react";
import { map } from "lodash";
import { Label } from "semantic-ui-react";
import {
  STATUS_LABEL_COLOR,
  BLUE_LABEL,
  OFFLINE_ACTIVATED_EXPIRED,
  OFFLINE_ACTIVATED_ACTIVE,
  RENEWED_ON,
  ORANGE_LABEL,
  RENEWAL,
  OFFLINE_ACTIVATED_WARNING,
} from "../../../constants/licenses.js";
import "./LicenseRows.styles.scss";
import CopyToClipboard from "../../generic/CopyToClipboard.jsx";
import TagDisplay from "../TagDisplay.jsx";
import { useDispatch, useSelector } from "react-redux";
import { isEnterpriseUser } from "../../../selectors/basics.js";
import doNotShowTag from "../../../utils/license/doNotShowTag.js";
import { openModal, selectLicenseKey } from "../../../slices/offlineActivation/offlineActivation.js";
import LicenseContextMenu from "../LicenseContextMenu/LicenseContextMenu.jsx";
import statusValidForOfflineActivation from "../../../utils/license/statusValidForOfflineActivation.js";
import showOfflineActivationOptions from "../../../utils/license/showOfflineActivationOptions.js";
import OfflineKeyModal from "./OfflineKeyModal/OfflineKeyModal.jsx";

const LicenseRows = ({ data, description, allRenewalSame, style, allExpired }) => {
  const [offlineKey, setOfflineKey] = useState(null);
  const dispatch = useDispatch();
  const isEnterprise = useSelector(isEnterpriseUser);
  const openOfflineActivationModal = (licenseId) => {
    dispatch(selectLicenseKey(licenseId));
    dispatch(openModal({ description: description, licenses: data }));
  };
  const showOfflineActivation = showOfflineActivationOptions(description);
  return (
    <div className="license-row__container" style={style}>
      {map(
        data,
        ({
          licenseId,
          statuses,
          refunded,
          upgraded,
          pairedKey,
          isDowngraded,
          offlineActivationKey,
          isVirtualBundle,
        }) => {
          const offlineActivated = statuses.find(
            ({ id }) => id === OFFLINE_ACTIVATED_ACTIVE || id === OFFLINE_ACTIVATED_WARNING
          );
          const offlineReactivationRequired = statuses.find(({ id }) => id === OFFLINE_ACTIVATED_EXPIRED);
          const keyIsAvailableForOfflineUse =
            (statusValidForOfflineActivation(statuses) || offlineActivated) && !isVirtualBundle;
          return (
            <div className="license-row" key={licenseId}>
              <div className="licence-row__incl-offline">
                <div className="licence-row_code-tag-container">
                  <div className="licence-row__key-container">
                    <CopyToClipboard textToCopy={licenseId} />
                    <p className={`m0 pr2 ${refunded || upgraded ? "cGrey4" : ""} monospace`}>{licenseId}</p>
                  </div>
                  <div className="licence-row__label-wrapper">
                    {map(statuses, ({ id, text }, i) => {
                      if (
                        doNotShowTag({
                          tagName: id,
                          allRenewalSame: allRenewalSame,
                          allExpired: allExpired,
                          isEnterprise: isEnterprise,
                          isOfflineActivated:
                            (offlineReactivationRequired && (id.startsWith(RENEWED_ON) || id.startsWith("Sup"))) ||
                            (offlineActivated && (id.startsWith(RENEWAL) || id.startsWith("Sup"))),
                        })
                      )
                        return null;

                      return (
                        <Label
                          data-testid="Status-label"
                          key={licenseId + i}
                          content={
                            <TagDisplay
                              description={description}
                              pairedKey={pairedKey}
                              isDowngraded={isDowngraded}
                              isVirtualBundle={isVirtualBundle}
                              text={text}
                              id={id}
                              statuses={statuses}
                            />
                          }
                          basic={false}
                          className={id.startsWith(RENEWED_ON) ? ORANGE_LABEL : STATUS_LABEL_COLOR[id] || BLUE_LABEL}
                        />
                      );
                    })}
                  </div>
                </div>
                {offlineReactivationRequired && !isEnterprise && (
                  <div
                    as="button"
                    className="offline-activation-link"
                    onClick={() => openOfflineActivationModal(licenseId)}
                  >
                    You need to generate a new Offline key
                  </div>
                )}
              </div>

              {!offlineReactivationRequired &&
                keyIsAvailableForOfflineUse &&
                !isEnterprise &&
                showOfflineActivation && (
                  <LicenseContextMenu
                    openOfflineActivationModal={() => openOfflineActivationModal(licenseId)}
                    setOfflineKey={setOfflineKey}
                    isOfflineActivated={offlineActivated}
                    offlineActivationKey={offlineActivationKey}
                  />
                )}
            </div>
          );
        }
      )}
      {offlineKey && <OfflineKeyModal offlineKey={offlineKey} close={() => setOfflineKey(null)} />}
    </div>
  );
};

export default LicenseRows;
