import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";

const StageCompletedContent = ({ stage }) => {
  return (
    <>
      <div className="flex centreOnMobile bold flex-center h3 pb2">
        <div className="mediaHeader-step mr2">
          Part <span>{stage.number}</span>
        </div>
        <div>{stage.title}</div>
      </div>
      <div className="bottomMediaBox">
        <div className="boxSize pb3">
          <div className="flex centreOnMobile">You've already completed this part.</div>
        </div>
        <div className="flex justify-center boxSize flex-center ">
          <MIcon name="check circle" size="massive" color="green" />
        </div>
      </div>
    </>
  );
};

export default StageCompletedContent;

StageCompletedContent.propTypes = {
  stage: PropTypes.shape({
    isActive: PropTypes.bool,
    isComplete: PropTypes.bool,
    url: PropTypes.string,
    videoStages: PropTypes.array,
    number: PropTypes.number,
    title: PropTypes.string,
  }).isRequired,
};
