import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { copyToClipboard } from "../../../utils/generic";

const handleTextFile = (offlineKey) => {
  const blob = new Blob([offlineKey], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "offline-key.txt";
  link.href = url;
  link.click();
};

const OfflineKeyActions = ({ offlineKey, previouslyOfflined }) => {
  return (
    <div className="flex offline-step-content flex-column">
      <div>
        Take the &apos;Offline Key&apos; below and enter into the offline device.{" "}
        {previouslyOfflined && <span>The Key is only valid for use on the device it was generated for.</span>}
      </div>
      <div className="mt3 mb1">Offline Key:</div>
      <div className="bold ">{offlineKey}</div>
      <div className="flex flex-wrap my2 offline-step-button-container">
        <Button secondary onClick={() => copyToClipboard(offlineKey)} content="Copy Code" />
        <Button secondary content="Download Txt File" onClick={() => handleTextFile(offlineKey)} />
      </div>
    </div>
  );
};

export default OfflineKeyActions;

OfflineKeyActions.propTypes = {
  offlineKey: PropTypes.string.isRequired,
  previouslyOfflined: PropTypes.bool,
};
OfflineKeyActions.defaultProps = {
  previouslyOfflined: false,
};
