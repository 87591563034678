/**
 * Toast notification types.
 * https://www.npmjs.com/package/react-toastify
 */

export const DEFAULT = 'default';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const INFO = 'info';
export const WARNING = 'warning';
