const objectToArray = (obj) => {
  const result = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result.push({ id: key, value: obj[key] });
    }
  }

  return result;
};
export default objectToArray;
