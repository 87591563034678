import moment from "moment";
import { isUndefined } from "lodash";
const checkNextExpiry = (originalDate, newDate) => {
  if (isUndefined(originalDate)) return newDate;
  // swapped to originalDate as returning newDate if undefined doesnt look right
  if (isUndefined(newDate)) return originalDate;
  if (
    moment(newDate).isAfter(moment()) &&
    (moment(newDate).isBefore(moment(originalDate)) || moment(originalDate).isBefore(moment()))
  )
    return newDate;
  return originalDate;
};
export default checkNextExpiry;
