import React from "react";
import { isNil, isEmpty } from "lodash";
import "./SupportCard.styles.scss";
import { Segment, Header, Button } from "semantic-ui-react";
import { MIcon } from "@macrium/shared-components";
import { GET_SUPPORT } from "../../../constants/urls.js";
import { openInNewTab } from "../../../utils/generic.js";
import SubmitTicketButton from "../../generic/SubmitTicketButton.jsx";
import PhoneNumbers from "../../generic/PhoneNumbers/PhoneNumbers.jsx";

const SupportCard = ({ hasSupport, showSupportPhoneNumber, supportKey }) => (
  <Segment raised>
    <Header className="mt0 mb1" content="Have a Specific Question?" />
    {hasSupport ? (
      <>
        <p className="mb2">
          Our dedicated Support Team are ready to help with any technical issues or problems, no matter how big or
          small, with any of our products if they can&apos;t be resolved with our above documentation
        </p>

        <div className="flex flex-center support-contact-container ">
          <div className="support-contact-container__submit">
            <div className="flex flex-center" style={{ maxWidth: "18em" }}>
              <MIcon name="feedback" className="mr2" size="big" style={{ minWidth: "1.5em" }} />
              {isNil(supportKey) || isEmpty(supportKey) ? (
                <p className="m0">Submit a ticket to be prioritised by our team:</p>
              ) : (
                <>
                  {showSupportPhoneNumber && (
                    <p className="m0 lato">
                      <span className="support-number-title">Your Support Number</span> <br />
                      <span className="support-number cLogoBlue">{supportKey}</span>
                    </p>
                  )}
                </>
              )}
            </div>
            <SubmitTicketButton />
          </div>
          {showSupportPhoneNumber && <PhoneNumbers />}
        </div>
      </>
    ) : (
      <>
        <p className="mb2">
          Our dedicated Support Team are ready to help with any technical issues or problems, no matter how big or small
          with any of our products. Save time and upgrade now to access our expertise
        </p>
        <Button primary content="Get Support Now" onClick={() => openInNewTab(GET_SUPPORT)} />
      </>
    )}
  </Segment>
);

export default SupportCard;
