import { reduce, orderBy, find } from "lodash";

export const sortAndRemoveDuplicates = (orders) => {
  const noDuplicateBills = reduce(
    orders,
    (cul, cur) => {
      if (!find(cul, (bill) => bill.m_transId === cur.m_transId)) {
        cul.push(cur);
      }
      return cul;
    },
    []
  );
  const sortedOrders = orderBy(noDuplicateBills, ["m_transTime"], ["desc"]);
  return sortedOrders;
};
