import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { toast } from "react-toastify";
import { MIcon } from "@macrium/shared-components";
import HeaderWithContent from "./HeaderWithContent";

const CLIPBOARD_TOAST_CONFIG = {
  pauseOnFocusLoss: false,
  autoClose: 2000,
};

const RegistrationCode = ({ account }) => {
  const urc = get(account, "urc", "N/A");

  const handleCopy = () => {
    navigator.clipboard.writeText(urc).then(
      () => {
        toast("Copied to the clipboard", {
          type: "info",
          ...CLIPBOARD_TOAST_CONFIG,
        });
      },
      () => {
        toast("An error has occurred", {
          type: "error",
          ...CLIPBOARD_TOAST_CONFIG,
        });
      }
    );
  };

  return (
    <HeaderWithContent title="Registration Code:">
      <p className="urc-usage-info">
        <span className="urc-usage-info--bold">{urc}</span>
        <span
          className="ml1"
          style={{ cursor: "pointer" }}
          onClick={handleCopy}
        >
          <MIcon name="copy" size="small" />
        </span>
      </p>
    </HeaderWithContent>
  );
};

RegistrationCode.propTypes = {
  account: PropTypes.shape({
    urc: PropTypes.string.isRequired,
  }),
};

export default RegistrationCode;
