import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, isUndefined } from "lodash";
import { Header, Segment, Message } from "semantic-ui-react";
import { Preloader } from "@macrium/shared-components";

import { getAccountDetails, getAccountEmail, isFetchingBilling, getBillingErrorMessage } from "../../selectors/basics";
import { getAccountDetailsAsync } from "../../slices/account/details";
import { fetchBillingAsync } from "../../slices/billing/billing";
import "./BillingPage.styles.scss";

import Page from "../generic/Page.jsx";
import BillingContainer from "./BillingContainer.jsx";
import { getSortedOrders } from "../../selectors/billing.js";

const Billing = () => {
  const dispatch = useDispatch();
  const email = useSelector(getAccountEmail);
  const orders = useSelector(getSortedOrders);
  const isLoading = useSelector(isFetchingBilling);
  const isError = useSelector(getBillingErrorMessage);
  const accountDetails = useSelector(getAccountDetails);

  useEffect(() => {
    if (email) {
      dispatch(getAccountDetailsAsync());
      dispatch(fetchBillingAsync(email));
    }
  }, [dispatch, email]);

  const pageHasErrors = !isEmpty(isError) && !isUndefined(isError);
  return (
    <Page title="Billing">
      <Preloader loading={isLoading} size="massive" />
      <Segment raised className="mb3">
        <p>
          <span>This page shows any orders associated with your primary email account </span>
          <b className="email-address">{accountDetails.email}</b>
          <span>. Your billing email used is </span>
          <b className="email-address">{accountDetails.billingEmail}</b>.
        </p>
        {/* <p>
          To make any changes to any of the above please click{" "}
          <span onClick={goToAccounts} className="clickable">
            <b>
              <u>here</u>
            </b>
          </span>
          .
        </p> */}
      </Segment>

      {!isEmpty(orders) && <Header as="h2" content="Billing & Invoices" className="mb3 mt0" />}
      {pageHasErrors && <Message negative content={isError} />}
      {isEmpty(orders) && !pageHasErrors && <Message content={"There are no invoices"} />}
      {!isEmpty(orders) && !pageHasErrors && <BillingContainer orders={orders} />}
    </Page>
  );
};

export default Billing;
