import React from "react";
import { Button } from "semantic-ui-react";

import "./BlockResellerMessage.styles.scss";
import { SUBMIT_TICKET } from "../../constants/urls";
import ROUTES from "../../app/routes";

export const BlockOEMMessage = () => (
  <>
    <p>Your email address is not valid for registration. Please use a personal email address.</p>
    <p>If you are trying to activate an OEM License Key, click below.</p>

    <p className="flex">
      <Button primary as="a" href={ROUTES.OEM_ACTIVATION}>
        OEM License Activation
      </Button>
      <Button secondary className="ml2" as="a" href={SUBMIT_TICKET} target="_blank" rel="noopener noreferrer">
        Contact Support
      </Button>
    </p>
  </>
);
