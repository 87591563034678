import { doGetRequest, doPostRequest } from "./requester";

const apiAccountUrl = "api/account";

export const login = (data) => doPostRequest(`${apiAccountUrl}/login`, data);
export const googleLogin = (data) => doPostRequest(`${apiAccountUrl}/googlelogin`, data);
export const googleTryRegister = (data) => doPostRequest(`${apiAccountUrl}/googledetails`, data);
export const googleRegister = (data) => doPostRequest(`${apiAccountUrl}/googleregister`, data);
export const microsoftLogin = (data) => doPostRequest(`${apiAccountUrl}/microsoftlogin`, data);
export const microsoftTryRegister = (data) => doPostRequest(`${apiAccountUrl}/microsoftdetails`, data);
export const microsoftRegister = (data) => doPostRequest(`${apiAccountUrl}/microsoftregister`, data);
export const register = (data) => doPostRequest(`${apiAccountUrl}/register`, data);
export const acceptPrivacyPolicy = () => doPostRequest(`${apiAccountUrl}/acceptpolicy`);
export const confirmEmail = (data) => doPostRequest(`${apiAccountUrl}/confirmemail`, data);
export const requestConfirmEmail = (data) =>
  doPostRequest(`${apiAccountUrl}/requestconfirmemail/${encodeURIComponent(data.email)}`, undefined);
export const login2FA = (data) => doPostRequest(`${apiAccountUrl}/login2fa`, data);
export const resend2FACode = (data) => doPostRequest(`${apiAccountUrl}/resend2fa`, data);

export const requestPasswordReset = (email) =>
  doPostRequest(`${apiAccountUrl}/requestpasswordreset/${encodeURIComponent(email)}`);
export const checkResetCode = (data) => doPostRequest(`${apiAccountUrl}/checkresetpasswordcode/${data.code}`);
export const resetPassword = (data) => doPostRequest(`${apiAccountUrl}/resetpassword`, data);

export const refreshAuthToken = () => doPostRequest(`${apiAccountUrl}/refreshtoken`);

export const getAccountDetails = () => doGetRequest(`${apiAccountUrl}/mydetails`);
export const updateAccountDetails = (data) => doPostRequest(`${apiAccountUrl}/updatemydetails`, data);
export const requestEmailChange = (data) =>
  doPostRequest(`${apiAccountUrl}/requestemailchange/${encodeURIComponent(data)}`);
export const changeEmail = (data) => doPostRequest(`${apiAccountUrl}/updateemail`, data);
export const cancelEmailUpdate = () => doPostRequest(`${apiAccountUrl}/cancelemailupdate`);
export const changePassword = (data) => doPostRequest(`${apiAccountUrl}/updatepassword`, data);
export const acceptCookies = () => doPostRequest(`${apiAccountUrl}/acceptcookies`);
export const invalidateToken = () => doPostRequest(`${apiAccountUrl}/invalidatetoken`);

export const checkIsRegistered = (email) =>
  doGetRequest(`${apiAccountUrl}/isregistereduser?email=${encodeURIComponent(email)}`);
export const postKeyRequest = (data) => doPostRequest(`${apiAccountUrl}/createfreshdeskticket`, data);
