import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import { IDLE, LOADING, FAILED, SUCCESS } from "../actionStatuses";
import * as API from "../../app/api/authAccount";
import ROUTES from "../../app/routes";
import { getRegisterState } from "../../selectors/basics";
import * as TOAST_TYPES from "../../constants/toast";
import { REGISTER_TYPES } from "../../constants/auth";
import { createToastWithHeader } from "../../components/generic/ToastWithHeader";
import { handleLoginResponse } from "./helpers";
import { REGISTER_OEM_ERROR, REGISTER_RESELLER_ERROR } from "../../constants/error-codes";
import { BlockResellerMessage } from "../../components/auth/BlockResellerMessage";
import { BlockOEMMessage } from "../../components/auth/BlockOEMMessage";

const REDUCER_NAME = "register";

const initialState = {
  status: IDLE,
  resendStatus: IDLE,
  registerType: REGISTER_TYPES.EMAIL,
  ssoCode: "",
};

const showRegisterThunkErrorToast = ({ message, errorCode }) => {
  if (errorCode === REGISTER_RESELLER_ERROR) {
    toast(createToastWithHeader("", <BlockResellerMessage />), {
      type: TOAST_TYPES.WARNING,
      toastId: "auth-block-reseller-message",
      autoClose: false,
      closeOnClick: false,
    });
  }
  if (errorCode === REGISTER_OEM_ERROR) {
    toast(createToastWithHeader("", <BlockOEMMessage />), {
      type: TOAST_TYPES.WARNING,
      //uses this to set the styling which we want to be the same as above- saves dupe css
      toastId: "auth-block-reseller-message",
      autoClose: false,
      closeOnClick: false,
    });
  } else {
    toast(message, { type: TOAST_TYPES.ERROR, className: "toast-large" });
  }
};

/**
 * Async Actions
 */
const registerThunk = createAsyncThunk(`${REDUCER_NAME}/registerRequest`, async (form, thunkAPI) => {
  const response = await API.register(form);

  if (!response.success) {
    showRegisterThunkErrorToast(response);
    return thunkAPI.rejectWithValue(response);
  }

  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const registerAsync = (form) => async (dispatch) => {
  const { payload } = await dispatch(registerThunk({ ...form, subscribe: !!form.subscribe }));

  if (payload.success) dispatch(push(`${ROUTES.AFTER_REGISTER}?email=${form.email}`));
};

export const resendEmailConfirmationEmailThunk = createAsyncThunk(
  `${REDUCER_NAME}/resendEmailConfirmationEmail`,
  async (data, thunkAPI) => {
    const response = await API.requestConfirmEmail({ email: data.email });

    if (!response.success) {
      toast("Could not resend email.", { type: TOAST_TYPES.ERROR });

      return thunkAPI.rejectWithValue(response);
    }

    toast("A confirmation email has been sent to your email address.", { type: TOAST_TYPES.SUCCESS });

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

/**
 * Google Register (SSO)
 */
export const googleTryRegisterThunk = createAsyncThunk(
  `${REDUCER_NAME}/googleTryRegisterRequest`,
  async (data, thunkAPI) => {
    const response = await API.googleTryRegister(data);

    if (!response.success) {
      showRegisterThunkErrorToast(response);

      return thunkAPI.rejectWithValue(response);
    }

    return response;
  }
);

const canHandleAutoRegistrationLogin = (payload) => payload.data.token || payload.data.needs2FA;

export const googleTryRegisterAsync = (data) => async (dispatch) => {
  const action = await dispatch(googleTryRegisterThunk(data));

  const { payload } = action;

  if (payload.success && canHandleAutoRegistrationLogin(payload)) {
    return await handleLoginResponse(dispatch, payload, payload.data.email);
  }

  dispatch(push(ROUTES.REGISTER));

  return action;
};

export const googleRegisterThunk = createAsyncThunk(`${REDUCER_NAME}/googleRegisterRequest`, async (data, thunkAPI) => {
  const response = await API.googleRegister(data);

  if (!response.success) {
    showRegisterThunkErrorToast(response);

    return thunkAPI.rejectWithValue(response);
  }

  toast(createToastWithHeader("Registered Successfully", "Your account has successfully been created"), {
    type: TOAST_TYPES.SUCCESS,
  });

  return response;
});

export const googleRegisterAndRedirectAsync = (form) => async (dispatch, getStore) => {
  const { ssoCode: code } = getRegisterState(getStore());

  const action = await dispatch(googleRegisterThunk({ ...form, subscribe: !!form.subscribe, code }));

  const { payload } = action;

  if (payload.success) {
    await handleLoginResponse(dispatch, payload, form.email);

    // Redirected automatically by the custom route
  }

  return action;
};

/**
 * Microsoft Register (SSO)
 */
export const microsoftTryRegisterThunk = createAsyncThunk(
  `${REDUCER_NAME}/microsoftTryRegisterRequest`,
  async (data, thunkAPI) => {
    const response = await API.microsoftTryRegister(data);

    if (!response.success) {
      showRegisterThunkErrorToast(response);

      return thunkAPI.rejectWithValue(response);
    }

    return response;
  }
);

export const microsoftTryRegisterAsync = (data) => async (dispatch) => {
  const action = await dispatch(microsoftTryRegisterThunk(data));

  const { payload } = action;

  if (payload.success && canHandleAutoRegistrationLogin(payload)) {
    return await handleLoginResponse(dispatch, payload, payload.data.email);
  }

  dispatch(push(ROUTES.REGISTER));

  return action;
};

export const microsoftRegisterThunk = createAsyncThunk(
  `${REDUCER_NAME}/microsoftRegisterRequest`,
  async (data, thunkAPI) => {
    const response = await API.microsoftRegister(data);

    if (!response.success) {
      showRegisterThunkErrorToast(response);

      return thunkAPI.rejectWithValue(response);
    }

    toast(createToastWithHeader("Registered Successfully", "Your account has successfully been created"), {
      type: TOAST_TYPES.SUCCESS,
    });

    return response;
  }
);

export const microsoftRegisterAndRedirectAsync = (form) => async (dispatch, getStore) => {
  const { ssoCode: code } = getRegisterState(getStore());

  const action = await dispatch(microsoftRegisterThunk({ ...form, subscribe: !!form.subscribe, code }));

  const { payload } = action;

  if (payload.success) {
    await handleLoginResponse(dispatch, payload, form.email);

    // Redirected automatically by the custom route
  }

  return action;
};

/**
 * Reducer
 */
export const registerSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerThunk.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(registerThunk.fulfilled, (state) => {
        state.status = SUCCESS;
      })
      .addCase(registerThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
      })

      .addCase(resendEmailConfirmationEmailThunk.pending, (state) => {
        state.resendStatus = LOADING;
      })
      .addCase(resendEmailConfirmationEmailThunk.rejected, (state) => {
        state.resendStatus = IDLE;
      })
      .addCase(resendEmailConfirmationEmailThunk.fulfilled, (state) => {
        state.resendStatus = IDLE;
      })

      .addCase(googleTryRegisterThunk.pending, (state) => {
        state.status = LOADING;
        state.ssoCode = "";
      })
      .addCase(googleTryRegisterThunk.rejected, (state) => {
        state.status = IDLE;
      })
      .addCase(googleTryRegisterThunk.fulfilled, (state, { payload }) => {
        if (payload.success && canHandleAutoRegistrationLogin(payload)) {
          state.status = IDLE;

          return;
        }

        state.status = IDLE;
        state.registerType = REGISTER_TYPES.GOOGLE;
        state.ssoCode = payload.data.code;
      })

      .addCase(googleRegisterThunk.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(googleRegisterThunk.rejected, (state, { payload }) => {
        state.status = IDLE;
      })
      .addCase(googleRegisterThunk.fulfilled, (state, { payload }) => ({
        ...state,
        status: SUCCESS,
        registerType: REGISTER_TYPES.EMAIL,
        ssoCode: "",
      }))

      .addCase(microsoftTryRegisterThunk.pending, (state) => {
        state.status = LOADING;
        state.ssoCode = "";
      })
      .addCase(microsoftTryRegisterThunk.rejected, (state) => {
        state.status = IDLE;
      })
      .addCase(microsoftTryRegisterThunk.fulfilled, (state, { payload }) => {
        if (payload.success && canHandleAutoRegistrationLogin(payload)) {
          state.status = IDLE;

          return;
        }

        state.status = IDLE;
        state.registerType = REGISTER_TYPES.MICROSOFT;
        state.ssoCode = payload.data.code;
      })

      .addCase(microsoftRegisterThunk.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(microsoftRegisterThunk.rejected, (state, { payload }) => {
        state.status = IDLE;
      })
      .addCase(microsoftRegisterThunk.fulfilled, (state, { payload }) => ({
        ...state,
        status: SUCCESS,
        registerType: REGISTER_TYPES.EMAIL,
        ssoCode: "",
      }));
  },
});

/**
 * Actions
 */
export const { clearState } = registerSlice.actions;

export default registerSlice.reducer;
