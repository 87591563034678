import React from "react";
import PropTypes from "prop-types";

import "./HeaderWithContent.styles.scss";

const HeaderWithContent = ({ title, children, className }) =>
  children ? (
    <div className={`header-with-content ${className}`}>
      <p className="header-with-content__title">{title}</p>
      <div className="header-with-content__content">{children}</div>
    </div>
  ) : null;

HeaderWithContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
};

HeaderWithContent.defaultProps = {
  className: "",
};

export default HeaderWithContent;
