import { BroadcastChannel } from 'broadcast-channel';

import { store } from './store';
import { setAuthToken } from '../slices/auth/login';

const BChannel = new BroadcastChannel('bc-macrium-user-portal');

const loginMessage = 'login';
const logoutMessage = 'logout';
const setAuthTokenMessage = 'setAuthToken';

BChannel.onmessage = (message) => {
  const { data, eventName } = message;
  
  switch(eventName) {
    case loginMessage:
    case logoutMessage: {
      window.location.reload();

      break;
    }

    case setAuthTokenMessage: {
      const { authToken } = data;

      store.dispatch(setAuthToken({ authToken }));

      break;
    }

    default:
      break
  }
};

const broadcast = (eventName, data = {}) => BChannel.postMessage({ eventName, data });

export const broadcastLogin = () => broadcast(loginMessage);
export const broadcastLogout = () => broadcast(logoutMessage);
export const broadcastSetAuthToken = ({ authToken }) => broadcast(setAuthTokenMessage, { authToken });

export default BChannel;
