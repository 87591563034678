import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

function GTMPageView() {
  const history = useHistory();
  const { location } = useSelector((state) => state.router);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    const dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: "pageview",
      page: {
        path: pagePath,
        url: window.location.href,
      },
    });
  }, [history, location]);

  return null;
}

export default GTMPageView;
