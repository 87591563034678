// Eventually this should be moved to the server

/*const B7CLNMIP0001M = "B-7CLNM-IP000-1M";
const B7SPLAIMP2400 = "B-7SPLA-IMP24-00";
const B7SRVAENT3YGM = "B-7SRVA-ENT3Y-GM";
const B7SRVAIMP2400 = "B-7SRVA-IMP24-00";
const B7WSNAENT3YGM = "B-7WSNA-ENT3Y-GM";
const B7WSNAIMP2400 = "B-7WSNA-IMP24-00";
const B8SPLAIMP2400 = "B-8SPLA-IMP24-00";
const B8SRVAIMP2400 = "B-8SRVA-IMP24-00";
const B8SRVAIP00001 = "B-8SRVA-IP000-01";
const B8SRVAIPP2401 = "B-8SRVA-IPP24-01";
const B8SRVAIPP241M = "B-8SRVA-IPP24-1M";
const B8WSNAIMP2400 = "B-8WSNA-IMP24-00";
const B8WSNAIP0001M = "B-8WSNA-IP000-1M";
const BMINPF0000000 = "B-MINPF-00000-00";
const E8SRVAIP00001 = "E-8SRVA-IP000-01";
const E8SRVAIPP2401 = "E-8SRVA-IPP24-01";
const B7WSNMIP00000 = "B-7WSNM-IP000-00";
const B7WSNMIPP2400 = "B-7WSNM-IPP24-00";
const B7WSNSIP00000 = "B-7WSNS-IP000-00";
const B7WSNSIPP2400 = "B-7WSNS-IPP24-00";
const B7WSNSIY1MR00 = "B-7WSNS-IY1MR-00";
const B7WSNSIY1PP00 = "B-7WSNS-IY1PP-00";
const B7WSNSIY1YP00 = "B-7WSNS-IY1YP-00";
const B7WSNSIYPMR00 = "B-7WSNS-IYPMR-00";
const E7WSNSIP00000 = "E-7WSNS-IP000-00";
const E7WSNSIPP2400 = "E-7WSNS-IPP24-00";
const B7SRVMIP00000 = "B-7SRVM-IP000-00";
const B7SRVMIPP2400 = "B-7SRVM-IPP24-00";
const B7SRVSIP00000 = "B-7SRVS-IP000-00";
const B7SRVSIPP2400 = "B-7SRVS-IPP24-00";
const B7SRVSIY1MR00 = "B-7SRVS-IY1MR-00";
const B7SRVSIY1PP00 = "B-7SRVS-IY1PP-00";
const B7SRVSIY1YP00 = "B-7SRVS-IY1YP-00";
const B7SRVSIYPMR00 = "B-7SRVS-IYPMR-00";
const E7SRVSIP00000 = "E-7SRVS-IP000-00";
const E7SRVSIPP2400 = "E-7SRVS-IPP24-00";
const B7SPLMIP00000 = "B-7SPLM-IP000-00";
const B7SPLMIPP2400 = "B-7SPLM-IPP24-00";
const B7SPLSIP00000 = "B-7SPLS-IP000-00";
const B7SPLSIPP2400 = "B-7SPLS-IPP24-00";
const B7SPLSIY1MR00 = "B-7SPLS-IY1MR-00";
const B7SPLSIY1PP00 = "B-7SPLS-IY1PP-00";
const B7SPLSIY1YP00 = "B-7SPLS-IY1YP-00";
const B7SPLSIYPMR00 = "B-7SPLS-IYPMR-00";
const E7SPLSIP00000 = "E-7SPLS-IP000-00";
const E7SPLSIPP2400 = "E-7SPLS-IPP24-00";
const B7ENTSIY1YP00 = "B-7ENTS-IY1YP-00";
const B7ENTSIYPMR00 = "B-7ENTS-IYPMR-00";
const E7ENTSIY1YP00 = "E-7ENTS-IY1YP-00";
const B7DKTSIY00000 = "B-7DKTS-IY000-00";
const B7DKTSIY00003 = "B-7DKTS-IY000-03";
const B7DKTSIYPMR00 = "B-7DKTS-IYPMR-00";
const E7DKTSIY00000 = "E-7DKTS-IY000-00";
const B7DEPMIP00000 = "B-7DEPM-IP000-00";
const B7CMCAIP000SP = "B-7CMCA-IP000-SP";
const B7CMCAIPP24SP = "B-7CMCA-IPP24-SP";
const B7SRVAIY1MR01 = "B-7SRVA-IY1MR-01";
const E7CMCAIP000SP = "E-7CMCA-IP000-SP";
const B7WSNAIP0001C = "B-7WSNA-IP000-1C";
const B7WSNAIP0001L = "B-7WSNA-IP000-1L";
const B7WSNAIP0001V = "B-7WSNA-IP000-1V";
const B7WSNAIP0001X = "B-7WSNA-IP000-1X";
const B7WSNAIP0003C = "B-7WSNA-IP000-3C";
const B7WSNAIPP241C = "B-7WSNA-IPP24-1C";
const B7WSNAIPP241L = "B-7WSNA-IPP24-1L";
const B7WSNAIPP241M = "B-7WSNA-IPP24-1M";
const B7WSNAIPP241V = "B-7WSNA-IPP24-1V";
const B7WSNAIPP241X = "B-7WSNA-IPP24-1X";
const B7WSNAIPP243C = "B-7WSNA-IPP24-3C";
const B7WSNAIY1MR1C = "B-7WSNA-IY1MR-1C";
const B7WSNAIY1MR1L = "B-7WSNA-IY1MR-1L";
const B7WSNAIY1MR1V = "B-7WSNA-IY1MR-1V";
const B7WSNAIY1MR1X = "B-7WSNA-IY1MR-1X";
const B7WSNAIY1MR3C = "B-7WSNA-IY1MR-3C";
const B7WSNAIYPMR1C = "B-7WSNA-IYPMR-1C";
const B7WSNAIYPMR1L = "B-7WSNA-IYPMR-1L";
const B7WSNAIYPMR1V = "B-7WSNA-IYPMR-1V";
const B7WSNAIYPMR1X = "B-7WSNA-IYPMR-1X";
const B7WSNAIYPMR3C = "B-7WSNA-IYPMR-3C";
const B7WSNMIP0001M = "B-7WSNM-IP000-1M";
const E7WSNAIP0001C = "E-7WSNA-IP000-1C";
const E7WSNAIP0001L = "E-7WSNA-IP000-1L";
const E7WSNAIP0001V = "E-7WSNA-IP000-1V";
const E7WSNAIP0001X = "E-7WSNA-IP000-1X";
const E7WSNAIP0003C = "E-7WSNA-IP000-3C";
const E7WSNAIPP241C = "E-7WSNA-IPP24-1C";
const E7WSNAIPP241L = "E-7WSNA-IPP24-1L";
const E7WSNAIPP241V = "E-7WSNA-IPP24-1V";
const E7WSNAIPP241X = "E-7WSNA-IPP24-1X";
const E7WSNAIPP243C = "E-7WSNA-IPP24-3C";
const B7SRVAIP0001V = "B-7SRVA-IP000-1V";
const B7SRVAIP0001X = "B-7SRVA-IP000-1X";
const B7SRVAIPP241M = "B-7SRVA-IPP24-1M";
const B7SRVAIPP241V = "B-7SRVA-IPP24-1V";
const B7SRVAIPP241X = "B-7SRVA-IPP24-1X";
const B7SRVAIY1MR1M = "B-7SRVA-IY1MR-1M";
const B7SRVAIY1MR1V = "B-7SRVA-IY1MR-1V";
const B7SRVAIY1MR1X = "B-7SRVA-IY1MR-1X";
const B7SRVAIYPMR1V = "B-7SRVA-IYPMR-1V";
const B7SRVAIYPMR1X = "B-7SRVA-IYPMR-1X";
const E7SRVAIP0001V = "E-7SRVA-IP000-1V";
const E7SRVAIP0001X = "E-7SRVA-IP000-1X";
const E7SRVAIPP241V = "E-7SRVA-IPP24-1V";
const E7SRVAIPP241X = "E-7SRVA-IPP24-1X";
const B7SRVAVP0001V = "B-7SRVA-VP000-1V";
const B7SRVAVP0001X = "B-7SRVA-VP000-1X";
const B7SRVAVPP241V = "B-7SRVA-VPP24-1V";
const B7SRVAVPP241X = "B-7SRVA-VPP24-1X";
const B7SRVAVY1MR1V = "B-7SRVA-VY1MR-1V";
const B7SRVAVY1MR1X = "B-7SRVA-VY1MR-1X";
const B7SRVAVYPMR1V = "B-7SRVA-VYPMR-1V";
const B7SRVAVYPMR1X = "B-7SRVA-VYPMR-1X";
const E7SRVAVP0001V = "E-7SRVA-VP000-1V";
const E7SRVAVP0001X = "E-7SRVA-VP000-1X";
const E7SRVAVPP241V = "E-7SRVA-VPP24-1V";
const E7SRVAVPP241X = "E-7SRVA-VPP24-1X";
const B7SPLVIYPMR05 = "B-7SPLV-IYPMR-05";
const B7SPLVIYPMR10 = "B-7SPLV-IYPMR-10";*/
const B7WSNMIU00000 = "B-7WSNM-IU000-00";
const B7WSNMIUP2400 = "B-7WSNM-IUP24-00";
const B7WSNSIU00000 = "B-7WSNS-IU000-00";
const B7WSNSIUP2400 = "B-7WSNS-IUP24-00";
const B7SRVMIU00000 = "B-7SRVM-IU000-00";
const B7SRVMIUP2400 = "B-7SRVM-IUP24-00";
const B7SRVSIU00000 = "B-7SRVS-IU000-00";
const B7SRVSIUP2400 = "B-7SRVS-IUP24-00";
const B7SPLMIU00000 = "B-7SPLM-IU000-00";
const B7SPLMIU00001 = "B-7SPLM-IU000-01";
const B7SPLMIUP2400 = "B-7SPLM-IUP24-00";
const B7SPLMIUP2401 = "B-7SPLM-IUP24-01";
const B7SPLSIU00000 = "B-7SPLS-IU000-00";
const B7SPLSIU00001 = "B-7SPLS-IU000-01";
const B7SPLSIUP2400 = "B-7SPLS-IUP24-00";
const B7SPLSIUP2401 = "B-7SPLS-IUP24-01";
const B7SPLVIUP2405 = "B-7SPLV-IUP24-05";
const B7SPLVIUP2410 = "B-7SPLV-IUP24-10";
const BRWSNMIU00000 = "B-RWSNM-IU000-00";
const BRWSNMIU00001 = "B-RWSNM-IU000-01";
const BRWSNMIUP2400 = "B-RWSNM-IUP24-00";
const BRWSNMIUP2401 = "B-RWSNM-IUP24-01";
const BRWSNSIU00000 = "B-RWSNS-IU000-00";
const BRWSNSIU00001 = "B-RWSNS-IU000-01";
const BRWSNSIUP2400 = "B-RWSNS-IUP24-00";
const BRWSNSIUP2401 = "B-RWSNS-IUP24-01";
const BRSRVMIU00000 = "B-RSRVM-IU000-00";
const BRSRVMIUP2400 = "B-RSRVM-IUP24-00";
const BRSRVSIU00000 = "B-RSRVS-IU000-00";
const BRSRVSIUP2400 = "B-RSRVS-IUP24-00";
const BRSPLMIU00000 = "B-RSPLM-IU000-00";
const BRSPLMIU00001 = "B-RSPLM-IU000-01";
const BRSPLMIUP2400 = "B-RSPLM-IUP24-00";
const BRSPLMIUP2401 = "B-RSPLM-IUP24-01";
const BRSPLSIU00000 = "B-RSPLS-IU000-00";
const BRSPLSIU00001 = "B-RSPLS-IU000-01";
const BRSPLSIUP2400 = "B-RSPLS-IUP24-00";
const BRSPLSIUP2401 = "B-RSPLS-IUP24-01";
/*const H7HEDMIP00000 = "H-7HEDM-IP000-00";*/
const H7HEDMIU00000 = "H-7HEDM-IU000-00";
/*const H7HEDSIP00000 = "H-7HEDS-IP000-00";*/
const H7HEDSIU00000 = "H-7HEDS-IU000-00";
const HRHEDMIU00000 = "H-RHEDM-IU000-00";
const HRHEDSIU00000 = "H-RHEDS-IU000-00";
/*const B7TECSIY00000 = "B-7TECS-IY000-00";
const B7TECSIYPMR00 = "B-7TECS-IYPMR-00";
const E7TECSIY00000 = "E-7TECS-IY000-00";
const B7SRVAIP00001 = "B-7SRVA-IP000-01";
const B7SRVAIPP2401 = "B-7SRVA-IPP24-01";
const B7SRVAIYPMR01 = "B-7SRVA-IYPMR-01";
const E7SRVAIP00001 = "E-7SRVA-IP000-01";
const E7SRVAIPP2401 = "E-7SRVA-IPP24-01";
const E7CMCAIPP24SP = "E-7CMCA-IPP24-SP";
const BXMSPAIP00000 = "B-XMSPA-IP000-00";
const BXSPLAIP00000 = "B-XSPLA-IP000-00";
const B8WSNMIP00000 = "B-8WSNM-IP000-00";
const B8WSNMIPP2400 = "B-8WSNM-IPP24-00";
const B8WSNSIP00000 = "B-8WSNS-IP000-00";
const B8WSNSIPP2400 = "B-8WSNS-IPP24-00";
const E8WSNSIP00000 = "E-8WSNS-IP000-00";
const E8WSNSIPP2400 = "E-8WSNS-IPP24-00";
const B8SRVMIP00000 = "B-8SRVM-IP000-00";
const B8SRVMIPP2400 = "B-8SRVM-IPP24-00";
const B8SRVSIP00000 = "B-8SRVS-IP000-00";
const B8SRVSIPP2400 = "B-8SRVS-IPP24-00";
const E8SRVSIP00000 = "E-8SRVS-IP000-00";
const E8SRVSIPP2400 = "E-8SRVS-IPP24-00";
const B8SPLMIP00000 = "B-8SPLM-IP000-00";
const B8SPLMIPP2400 = "B-8SPLM-IPP24-00";
const B8SPLSIP00000 = "B-8SPLS-IP000-00";
const B8SPLSIPP2400 = "B-8SPLS-IPP24-00";
const E8SPLSIP00000 = "E-8SPLS-IP000-00";
const E8SPLSIPP2400 = "E-8SPLS-IPP24-00";
const B8ENTSIY1YP00 = "B-8ENTS-IY1YP-00";
const E8ENTSIY1YP00 = "E-8ENTS-IY1YP-00";
const B8DKTSIY00000 = "B-8DKTS-IY000-00";
const E8DKTSIY00000 = "E-8DKTS-IY000-00";
const B8CMCAIP000SP = "B-8CMCA-IP000-SP";
const B8CMCAIPP24SP = "B-8CMCA-IPP24-SP";
const E8CMCAIP000SP = "E-8CMCA-IP000-SP";
const E8CMCAIPP24SP = "E-8CMCA-IPP24-SP";
const B8WSNAIP0001C = "B-8WSNA-IP000-1C";
const B8WSNAIP0001L = "B-8WSNA-IP000-1L";
const B8WSNAIP0001V = "B-8WSNA-IP000-1V";
const B8WSNAIP0001X = "B-8WSNA-IP000-1X";
const B8WSNAIP0003C = "B-8WSNA-IP000-3C";
const B8WSNAIPP241C = "B-8WSNA-IPP24-1C";
const B8WSNAIPP241L = "B-8WSNA-IPP24-1L";
const B8WSNAIPP241M = "B-8WSNA-IPP24-1M";
const B8WSNAIPP241V = "B-8WSNA-IPP24-1V";
const B8WSNAIPP241X = "B-8WSNA-IPP24-1X";
const B8WSNAIPP243C = "B-8WSNA-IPP24-3C";
const E8WSNAIP0001C = "E-8WSNA-IP000-1C";
const E8WSNAIP0001L = "E-8WSNA-IP000-1L";
const E8WSNAIP0001V = "E-8WSNA-IP000-1V";
const E8WSNAIP0001X = "E-8WSNA-IP000-1X";
const E8WSNAIP0003C = "E-8WSNA-IP000-3C";
const E8WSNAIPP241C = "E-8WSNA-IPP24-1C";
const E8WSNAIPP241L = "E-8WSNA-IPP24-1L";
const E8WSNAIPP241V = "E-8WSNA-IPP24-1V";
const E8WSNAIPP241X = "E-8WSNA-IPP24-1X";
const E8WSNAIPP243C = "E-8WSNA-IPP24-3C";
const B8SRVAIP0001V = "B-8SRVA-IP000-1V";
const B8SRVAIP0001X = "B-8SRVA-IP000-1X";
const B8SRVAIPP241V = "B-8SRVA-IPP24-1V";
const B8SRVAIPP241X = "B-8SRVA-IPP24-1X";
const E8SRVAIP0001V = "E-8SRVA-IP000-1V";
const E8SRVAIP0001X = "E-8SRVA-IP000-1X";
const E8SRVAIPP241V = "E-8SRVA-IPP24-1V";
const E8SRVAIPP241X = "E-8SRVA-IPP24-1X";
const B8SRVAVP0001V = "B-8SRVA-VP000-1V";
const B8SRVAVP0001X = "B-8SRVA-VP000-1X";
const B8SRVAVPP241V = "B-8SRVA-VPP24-1V";
const B8SRVAVPP241X = "B-8SRVA-VPP24-1X";
const E8SRVAVP0001V = "E-8SRVA-VP000-1V";
const E8SRVAVP0001X = "E-8SRVA-VP000-1X";
const E8SRVAVPP241V = "E-8SRVA-VPP24-1V";
const E8SRVAVPP241X = "E-8SRVA-VPP24-1X";
const H8HEDMIP00000 = "H-8HEDM-IP000-00";*/
const H8HEDMIU00000 = "H-8HEDM-IU000-00";
/*const H8HEDSIP00000 = "H-8HEDS-IP000-00";*/
const H8HEDSIU00000 = "H-8HEDS-IU000-00";
const HRHEDMIU00001 = "H-RHEDM-IU000-01";
const HRHEDSIU00001 = "H-RHEDS-IU000-01";
/*const BNDKTSIMPMR00 = "B-NDKTS-IMPMR-00";
const BNDKTSIYPMR00 = "B-NDKTS-IYPMR-00";
const BNENTSIMPMR00 = "B-NENTS-IMPMR-00";
const BNENTSIYPMR00 = "B-NENTS-IYPMR-00";
const BNSPLSIM1MR00 = "B-NSPLS-IM1MR-00";
const BNSPLSIMPMR00 = "B-NSPLS-IMPMR-00";
const BNSPLSIY1MR00 = "B-NSPLS-IY1MR-00";
const BNSPLSIYPMR00 = "B-NSPLS-IYPMR-00";
const BNSPLVIMPMR05 = "B-NSPLV-IMPMR-05";
const BNSPLVIMPMR10 = "B-NSPLV-IMPMR-10";
const BNSPLVIYPMR05 = "B-NSPLV-IYPMR-05";
const BNSPLVIYPMR10 = "B-NSPLV-IYPMR-10";
const BNSRVAIM1MR01 = "B-NSRVA-IM1MR-01";
const BNSRVAIM1MR1V = "B-NSRVA-IM1MR-1V";
const BNSRVAIM1MR1X = "B-NSRVA-IM1MR-1X";
const BNSRVAIMPMR01 = "B-NSRVA-IMPMR-01";
const BNSRVAIMPMR1V = "B-NSRVA-IMPMR-1V";
const BNSRVAIMPMR1X = "B-NSRVA-IMPMR-1X";
const BNSRVAIY1MR01 = "B-NSRVA-IY1MR-01";
const BNSRVAIY1MR1V = "B-NSRVA-IY1MR-1V";
const BNSRVAIY1MR1X = "B-NSRVA-IY1MR-1X";
const BNSRVAIYPMR01 = "B-NSRVA-IYPMR-01";
const BNSRVAIYPMR1V = "B-NSRVA-IYPMR-1V";
const BNSRVAIYPMR1X = "B-NSRVA-IYPMR-1X";
const BNSRVAVM1MR1V = "B-NSRVA-VM1MR-1V";
const BNSRVAVM1MR1X = "B-NSRVA-VM1MR-1X";
const BNSRVAVMPMR1V = "B-NSRVA-VMPMR-1V";
const BNSRVAVMPMR1X = "B-NSRVA-VMPMR-1X";
const BNSRVAVY1MR1V = "B-NSRVA-VY1MR-1V";
const BNSRVAVY1MR1X = "B-NSRVA-VY1MR-1X";
const BNSRVAVYPMR1V = "B-NSRVA-VYPMR-1V";
const BNSRVAVYPMR1X = "B-NSRVA-VYPMR-1X";
const BNSRVSIM1MR00 = "B-NSRVS-IM1MR-00";
const BNSRVSIMPMR00 = "B-NSRVS-IMPMR-00";
const BNSRVSIY1MR00 = "B-NSRVS-IY1MR-00";
const BNSRVSIYPMR00 = "B-NSRVS-IYPMR-00";
const BNWSNAIM1MR1C = "B-NWSNA-IM1MR-1C";
const BNWSNAIM1MR1L = "B-NWSNA-IM1MR-1L";
const BNWSNAIM1MR1M = "B-NWSNA-IM1MR-1M";
const BNWSNAIM1MR1V = "B-NWSNA-IM1MR-1V";
const BNWSNAIM1MR1X = "B-NWSNA-IM1MR-1X";
const BNWSNAIM1MR3C = "B-NWSNA-IM1MR-3C";
const BNWSNAIMPMR1C = "B-NWSNA-IMPMR-1C";
const BNWSNAIMPMR1L = "B-NWSNA-IMPMR-1L";
const BNWSNAIMPMR1M = "B-NWSNA-IMPMR-1M";
const BNWSNAIMPMR1V = "B-NWSNA-IMPMR-1V";
const BNWSNAIMPMR1X = "B-NWSNA-IMPMR-1X";
const BNWSNAIMPMR3C = "B-NWSNA-IMPMR-3C";
const BNWSNAIY1MR1C = "B-NWSNA-IY1MR-1C";
const BNWSNAIY1MR1L = "B-NWSNA-IY1MR-1L";
const BNWSNAIY1MR1M = "B-NWSNA-IY1MR-1M";
const BNWSNAIY1MR1V = "B-NWSNA-IY1MR-1V";
const BNWSNAIY1MR1X = "B-NWSNA-IY1MR-1X";
const BNWSNAIY1MR3C = "B-NWSNA-IY1MR-3C";
const BNWSNAIYPMR1C = "B-NWSNA-IYPMR-1C";
const BNWSNAIYPMR1L = "B-NWSNA-IYPMR-1L";
const BNWSNAIYPMR1M = "B-NWSNA-IYPMR-1M";
const BNWSNAIYPMR1V = "B-NWSNA-IYPMR-1V";
const BNWSNAIYPMR1X = "B-NWSNA-IYPMR-1X";
const BNWSNAIYPMR3C = "B-NWSNA-IYPMR-3C";
const BNWSNSIM1MR00 = "B-NWSNS-IM1MR-00";
const BNWSNSIMPMR00 = "B-NWSNS-IMPMR-00";
const BNWSNSIY1MR00 = "B-NWSNS-IY1MR-00";
const BNWSNSIYPMR00 = "B-NWSNS-IYPMR-00";
const BRSRVAIP00001 = "B-RSRVA-IP000-01";
const BRWSNAIP0001V = "B-RWSNA-IP000-1V";
const ERSRVAIP00001 = "E-RSRVA-IP000-01";
const ERWSNAIP0001V = "E-RWSNA-IP000-1V";
const ERWSNAIP0001X = "E-RWSNA-IP000-1X";
const BRSRVAVY1MR1V = "B-RSRVA-VY1MR-1V";
const BRSRVAVY1MR1X = "B-RSRVA-VY1MR-1X";
const BRSRVAVYPMR1V = "B-RSRVA-VYPMR-1V";
const BRSRVAVYPMR1X = "B-RSRVA-VYPMR-1X";
const BRWSNMIP00000 = "B-RWSNM-IP000-00";
const BRWSNMIPP2400 = "B-RWSNM-IPP24-00";
const BRWSNSIP00000 = "B-RWSNS-IP000-00";
const BRWSNSIPP2400 = "B-RWSNS-IPP24-00";
const BRWSNSIY1MR00 = "B-RWSNS-IY1MR-00";
const BRWSNSIYPMR00 = "B-RWSNS-IYPMR-00";
const ERWSNSIP00000 = "E-RWSNS-IP000-00";
const ERWSNSIPP2400 = "E-RWSNS-IPP24-00";
const BRENTSIYPMR00 = "B-RENTS-IYPMR-00";
const BRSRVMIP00000 = "B-RSRVM-IP000-00";
const BRSRVMIPP2400 = "B-RSRVM-IPP24-00";
const BRSRVSIP00000 = "B-RSRVS-IP000-00";
const BRSRVSIPP2400 = "B-RSRVS-IPP24-00";
const BRSRVSIY1MR00 = "B-RSRVS-IY1MR-00";
const BRSRVSIY24U00 = "B-RSRVS-IY24U-00";
const BRSRVSIYPMR00 = "B-RSRVS-IYPMR-00";
const ERSRVSIP00000 = "E-RSRVS-IP000-00";
const BRSPLMIP00000 = "B-RSPLM-IP000-00";
const BRSPLMIPP2400 = "B-RSPLM-IPP24-00";
const BRSPLSIP00000 = "B-RSPLS-IP000-00";
const BRSPLSIPP2400 = "B-RSPLS-IPP24-00";
const BRSPLSIY1MR00 = "B-RSPLS-IY1MR-00";
const BRSPLSIYPMR00 = "B-RSPLS-IYPMR-00";
const ERSPLSIP00000 = "E-RSPLS-IP000-00";
const BRDKTSIY00000 = "B-RDKTS-IY000-00";
const BRDKTSIYPMR00 = "B-RDKTS-IYPMR-00";
const BRENTSIY1YP00 = "B-RENTS-IY1YP-00";
const BRENTSIY24U00 = "B-RENTS-IY24U-00";
const BRTECSIY00000 = "B-RTECS-IY000-00";
const BRTECSIYPMR00 = "B-RTECS-IYPMR-00";
const ERDKTSIY00000 = "E-RDKTS-IY000-00";
const ERENTSIY1YP00 = "E-RENTS-IY1YP-00";
const ERTECSIY00000 = "E-RTECS-IY000-00";
const BRDEPMIP00000 = "B-RDEPM-IP000-00";*/
const BRSPLVIPP2405 = "B-RSPLV-IPP24-05";
const BRSPLVIPP2410 = "B-RSPLV-IPP24-10";
/*const BRSPLVIYPMR05 = "B-RSPLV-IYPMR-05";
const BRSPLVIYPMR10 = "B-RSPLV-IYPMR-10";
const BRSRVAIP0001V = "B-RSRVA-IP000-1V";
const BRSRVAIP0001X = "B-RSRVA-IP000-1X";
const BRSRVAIPP241V = "B-RSRVA-IPP24-1V";
const BRSRVAIPP241X = "B-RSRVA-IPP24-1X";
const BRWSNAIP0001C = "B-RWSNA-IP000-1C";
const BRWSNAIP0001L = "B-RWSNA-IP000-1L";
const BRWSNAIP0001M = "B-RWSNA-IP000-1M";
const BRWSNAIP0001X = "B-RWSNA-IP000-1X";
const BRWSNAIP0003C = "B-RWSNA-IP000-3C";
const BRWSNAIPP241C = "B-RWSNA-IPP24-1C";
const BRWSNAIPP241L = "B-RWSNA-IPP24-1L";
const BRWSNAIPP241M = "B-RWSNA-IPP24-1M";
const BRWSNAIPP241X = "B-RWSNA-IPP24-1X";
const BRWSNAIPP243C = "B-RWSNA-IPP24-3C";
const BRSRVAVP0001V = "B-RSRVA-VP000-1V";
const BRSRVAVP0001X = "B-RSRVA-VP000-1X";
const BRSRVAVPP241V = "B-RSRVA-VPP24-1V";
const BRSRVAVPP241X = "B-RSRVA-VPP24-1X";
const ERSRVAVP0001V = "E-RSRVA-VP000-1V";
const ERSRVAVP0001X = "E-RSRVA-VP000-1X";
const ERSRVAVPP241V = "E-RSRVA-VPP24-1V";
const ERSRVAVPP241X = "E-RSRVA-VPP24-1X";
const BRCMCAIP000SP = "B-RCMCA-IP000-SP";
const ERCMCAIP000SP = "E-RCMCA-IP000-SP";
const HRHEDMIP00000 = "H-RHEDM-IP000-00";
const HRHEDSIP00000 = "H-RHEDS-IP000-00";
const B8SRVAVMP2400 = "B-8SRVA-VMP24-00";
const BNSDEPIMRMR1C = "B-NSDEP-IMRMR-1C";
const BNSDEPIMRMR1L = "B-NSDEP-IMRMR-1L";
const ENSDEPIMRMR1C = "E-NSDEP-IMRMR-1C";
const ENSDEPIMRMR1L = "E-NSDEP-IMRMR-1L";
const BNSDEPIYRMR1C = "B-NSDEP-IYRMR-1C";
const BNSDEPIYRMR1L = "B-NSDEP-IYRMR-1L";
const BSSDEPIY0001C = "B-SSDEP-IY000-1C";
const BSSDEPIY0001L = "B-SSDEP-IY000-1L";
const ENSDEPIYRMR1C = "E-NSDEP-IYRMR-1C";
const ENSDEPIYRMR1L = "E-NSDEP-IYRMR-1L";
const ESSDEPIY0001C = "E-SSDEP-IY000-1C";
const ESSDEPIY0001L = "E-SSDEP-IY000-1L";
const BNSDSBDPC001M = "B-NSDSB-DPC00-1M";
const BNSDSBDPC005C = "B-NSDSB-DPC00-5C";
const BNSDSBDPC00XM = "B-NSDSB-DPC00-XM";
const BNSDSBIM1MR00 = "B-NSDSB-IM1MR-00";
const BNSDSBIY1MR00 = "B-NSDSB-IY1MR-00";
const BSSDSBIY00000 = "B-SSDSB-IY000-00";*/

export const UPGRADE_SKUS = {
  [B7WSNMIU00000]: B7WSNMIU00000,
  [B7WSNMIUP2400]: B7WSNMIUP2400,
  [B7WSNSIU00000]: B7WSNSIU00000,
  [B7WSNSIUP2400]: B7WSNSIUP2400,
  [B7SRVMIU00000]: B7SRVMIU00000,
  [B7SRVMIUP2400]: B7SRVMIUP2400,
  [B7SRVSIU00000]: B7SRVSIU00000,
  [B7SRVSIUP2400]: B7SRVSIUP2400,
  [B7SPLMIU00000]: B7SPLMIU00000,
  [B7SPLMIU00001]: B7SPLMIU00001,
  [B7SPLMIUP2400]: B7SPLMIUP2400,
  [B7SPLMIUP2401]: B7SPLMIUP2401,
  [B7SPLSIU00000]: B7SPLSIU00000,
  [B7SPLSIU00001]: B7SPLSIU00001,
  [B7SPLSIUP2400]: B7SPLSIUP2400,
  [B7SPLSIUP2401]: B7SPLSIUP2401,
  [B7SPLVIUP2405]: B7SPLVIUP2405,
  [B7SPLVIUP2410]: B7SPLVIUP2410,
  [H7HEDMIU00000]: H7HEDMIU00000,
  [H7HEDSIU00000]: H7HEDSIU00000,
  [H8HEDMIU00000]: H8HEDMIU00000,
  [H8HEDSIU00000]: H8HEDSIU00000,
  [BRWSNMIU00000]: BRWSNMIU00000,
  [BRWSNMIU00001]: BRWSNMIU00001,
  [BRWSNMIUP2400]: BRWSNMIUP2400,
  [BRWSNMIUP2401]: BRWSNMIUP2401,
  [BRWSNSIU00000]: BRWSNSIU00000,
  [BRWSNSIU00001]: BRWSNSIU00001,
  [BRWSNSIUP2400]: BRWSNSIUP2400,
  [BRWSNSIUP2401]: BRWSNSIUP2401,
  [BRSRVMIU00000]: BRSRVMIU00000,
  [BRSRVMIUP2400]: BRSRVMIUP2400,
  [BRSRVSIU00000]: BRSRVSIU00000,
  [BRSRVSIUP2400]: BRSRVSIUP2400,
  [BRSPLMIU00000]: BRSPLMIU00000,
  [BRSPLMIU00001]: BRSPLMIU00001,
  [BRSPLMIUP2400]: BRSPLMIUP2400,
  [BRSPLMIUP2401]: BRSPLMIUP2401,
  [BRSPLSIU00000]: BRSPLSIU00000,
  [BRSPLSIU00001]: BRSPLSIU00001,
  [BRSPLSIUP2400]: BRSPLSIUP2400,
  [BRSPLSIUP2401]: BRSPLSIUP2401,
  [H7HEDMIU00000]: H7HEDMIU00000,
  [H7HEDSIU00000]: H7HEDSIU00000,
  [HRHEDMIU00000]: HRHEDMIU00000,
  [HRHEDSIU00000]: HRHEDSIU00000,
  [HRHEDMIU00001]: HRHEDMIU00001,
  [HRHEDSIU00001]: HRHEDSIU00001
};

export const VIRTUAL_SERVER_BUNDLE_SKUS = {
  [BRSPLVIPP2405]: BRSPLVIPP2405,
  [BRSPLVIPP2410]: BRSPLVIPP2410,
  [B7SPLVIUP2405]: B7SPLVIUP2405,
  [B7SPLVIUP2410]: B7SPLVIUP2410
};
