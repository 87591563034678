import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { find, get } from "lodash";

import { COUNTRIES_LIST, COUNTRIES_NAMES, COUNTRIES_SHORT_NAMES } from "../../../utils/countries";

/**
 * Value can be a short name (PT) or the name of the country (Portugal)
 */
const CountrySelector = ({ value, onChange }) => {
  let finalValue = value;

  // If it was passed down the full name of the country
  const country = find(COUNTRIES_LIST, { text: value });

  if (country) {
    // get the short name (id)
    finalValue = get(country, "id");
  }

  return <Dropdown options={COUNTRIES_LIST} search selection value={finalValue} onChange={onChange} />;
};

CountrySelector.propTypes = {
  value: PropTypes.oneOf(["", ...COUNTRIES_SHORT_NAMES, ...COUNTRIES_NAMES]),
  onChange: PropTypes.func.isRequired,
};

CountrySelector.defaultProps = {
  value: "",
};

export default CountrySelector;
