import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "semantic-ui-react";

import { setIsBlocking, setOpen } from "../../slices/prompt/prompt";
import { getRequestedPage, getSelectedRoute } from "../../selectors/basics";
import ROUTES from "../../app/routes";
import { isBlockingModalOpen } from "../../selectors/basics";
import { push } from "connected-react-router";
import { logoutThunk } from "../../slices/auth/login";

const CustomPrompt = () => {
  const isOpen = useSelector(isBlockingModalOpen);
  const selectedRoute = useSelector(getSelectedRoute);
  const requestedPage = useSelector(getRequestedPage);
  const dispatch = useDispatch();

  /* Clear state when user changes pages */
  React.useEffect(() => {
    dispatch(setIsBlocking(false));
  }, [selectedRoute, dispatch]);

  return (
    <Modal open={isOpen} closeOnDimmerClick={false} size="mini">
      <Modal.Header>You Have Unsaved Changes</Modal.Header>
      <Modal.Content>
        <p className="h4">
          Are you sure you want to{" "}
          {requestedPage === ROUTES.LOGOUT ? "logout" : "leave this page"}?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Leave"
          secondary
          onClick={() => {
            dispatch(setIsBlocking(false));
            dispatch(setOpen({ open: false }));

            if (requestedPage === ROUTES.LOGOUT) {
              return dispatch(logoutThunk());
            } else {
              return dispatch(push(requestedPage));
            }
          }}
        />
        <Button
          content="Stay"
          onClick={() => dispatch(setOpen({ open: false }))}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CustomPrompt;
