import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@macrium/shared-components';

import { RESEND_TIMEOUT } from '../../constants/auth';

const ResendLink = ({ content, onClick }) => {
  const [showResend, setShowResend] = React.useState(true);

  const resend= () => {
    setShowResend(false);

    onClick();

    setTimeout(() => setShowResend(true), RESEND_TIMEOUT);
  };

  return (
    showResend &&
      <div className="mt2 mb1">
        <span onClick={resend}>
          <Link content={content} />
        </span>
      </div>
  );
};

ResendLink.propTypes = {
  content: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

ResendLink.defaultProps = {
  content: null
};

export default ResendLink;
