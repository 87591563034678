import PropTypes from "prop-types";

const FieldWrapper = ({ header, children }) => {
  return (
    <div className="account-details__section">
      <div className="account-details__section__left">
        <h3>{header}</h3>
      </div>

      <div className="account-details__section__right">{children}</div>
    </div>
  );
};

FieldWrapper.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FieldWrapper;
