import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  ResponsiveSideMenu,
  SideMenuHamburger,
} from "@macrium/shared-components";

import { getSideMenuProps } from "../selectors/sideMenu";
import { getSelectedRoute, hasUnsavedChanges } from "../selectors/basics";
import ROUTES from "../app/routes";
import { logoutThunk } from "../slices/auth/login";
import Logo from "./auth/HeaderAndFooter/PortalLogo.jsx";

import "./SideMenu.styles.scss";

const Header = () => (
  <div className="cWhite pb4">
    <Logo />
  </div>
);

const PortalSideMenu = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const sideMenuProps = useSelector(getSideMenuProps);
  const isBlocking = useSelector(hasUnsavedChanges);
  const selected = useSelector(getSelectedRoute);

  const dispatch = useDispatch();

  const goTo = (page) => {
    if (page === ROUTES.LOGOUT && !isBlocking) {
      return dispatch(logoutThunk());
    }

    dispatch(push(page));
  };

  return (
    <div className="resp-side-menu fullbleed flex flex-column fullwidth">
      <div className="p2 bgGrey1 border resp-side-menu__hamburger">
        <SideMenuHamburger
          open={open}
          onClick={setOpen}
          title="Macrium Software"
        />

        <div className="cWhite resp-side-menu__logo">
          <Logo />
        </div>
      </div>

      <div className="resp-side-menu__content">
        <ResponsiveSideMenu
          {...sideMenuProps}
          open={open}
          onOpenChange={setOpen}
          selected={selected}
          onItemClick={(item) => {
            goTo(item);
          }}
          sidebarPusherClassName="fullheight"
          header={<Header />}
          hideHeadersOnSmallBreakpoint
          enableMenuItemsTransition
          size="massive"
        >
          {children}
        </ResponsiveSideMenu>
      </div>
    </div>
  );
};

PortalSideMenu.propTypes = {
  children: PropTypes.any,
};

PortalSideMenu.defaultProps = {
  children: null,
};

export default PortalSideMenu;
