import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOADING, IDLE, FAILED } from "../actionStatuses.js";
import { getBillingInfo } from "../../app/api/billing.js";
import { get, isEmpty } from "lodash";

const REDUCER_NAME = "billing";

const initialState = {
  status: LOADING,
  orders: [],
  error: "",
  loaded: false
};

export const fetchBillingAsync = createAsyncThunk(
  "billing, fetchBilling",
  async (email, thunkAPI) => {
    const res = await getBillingInfo(email);
    if (!res.success) return thunkAPI.rejectWithValue(res);

    return res;
  }
);

export const billingSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingAsync.pending, (state) => {
        if (!state.loaded || !isEmpty(state.error)) state.status = LOADING;
      })
      .addCase(fetchBillingAsync.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.orders = get(payload, "data.summary", []);
        state.error = "";
        state.loaded = true;
      })
      .addCase(fetchBillingAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.error = payload.message;
        state.loaded = false;
      });
  }
});

export default billingSlice.reducer;
