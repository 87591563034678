import { MIcon } from "@macrium/shared-components";
import PropTypes from "prop-types";
import "./CreateAnAccount.styles.scss";

const InfoComponent = ({ iconName, text }) => (
  <>
    <MIcon name={iconName} size="large" color="cLogoLightBlue" />
    <p className="cGrey3">{text}</p>
  </>
);
const CreateAnAccount = ({ header, subHeader, hideForMobile }) => {
  return (
    <div className="trial-message">
      <h2>{header}</h2>
      <div className="mb4">
        {hideForMobile ? (
          "Head to a desktop device to download"
        ) : (
          <>
            <div>{subHeader}</div>
            <span className="cGrey3">(no credit card required)</span>

            <div className="trial-message__summary">
              <div className="trial-message__summary__item">
                <InfoComponent iconName="download" text="Access and download product trials" />
              </div>

              <div className="trial-message__summary__item">
                <InfoComponent iconName="key" text="View and manage software licence keys" />
              </div>

              <div className="trial-message__summary__item">
                <InfoComponent iconName="circle question" text="Access set up guides and help" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateAnAccount;
CreateAnAccount.propNames = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  hideForMobile: PropTypes.bool.isRequired,
};
