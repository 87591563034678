import React from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";

import "./Changelog.styles.scss";
import ChangelogMD from "../CHANGELOG.md";

const Changelog = () => {
  const [changelog, setChangelog] = React.useState("");

  React.useEffect(() => {
    axios(ChangelogMD)
      .then((response) => response.data)
      .then((text) => {
        setChangelog(text);
      });
  }, []);

  return (
    <div className="p3">
      <ReactMarkdown className="changelog-container">{changelog}</ReactMarkdown>
    </div>
  );
};

export default Changelog;
