import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import PropTypes from "prop-types";

import CancelButton from "../../generic/CancelButton";
import EmailInput from "../../generic/Inputs/EmailInput";
import FieldWrapper from "../FieldWrapper/FieldWrapper";

const BillingChange = ({ value }) => {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setEmail] = useState(value);
  const [error, setError] = useState("");

  useEffect(() => {
    setEmail(value || "");
  }, [value]);

  const handleCancelClick = () => {
    setEditMode(false);
    setEmail(value || "");
    setError("");
  };

  return (
    <FieldWrapper header="Billing">
      <div className={`account-details__section__right__item ${editMode && "edit-mode"}`}>
        <div className="account-details__section__right__item__wrapper">
          {editMode ? (
            <Form>
              <Form.Field error={!!error}>
                <EmailInput
                  value={inputValue}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter new Billing Email Address"
                />
                <FormFieldError error={error} />
              </Form.Field>
            </Form>
          ) : (
            <div>{value || "-"}</div>
          )}
        </div>
        <div className={`account-details__btn-wrap ${editMode && "account-details__btn-wrap--edit-mode"}`}>
          {editMode && <CancelButton onCancelClick={handleCancelClick} />}{" "}
          {/* This billing email is not used anywhere and some people raised possible issues in the future,
            if they edit it and it's not in sync with cleve rbridge and other stuff */}
          {/* <EditButton
              editMode={editMode}
              onEditClick={handleEditClick}
              onSaveClick={handleSaveClick}
            /> */}
        </div>
      </div>
    </FieldWrapper>
  );
};

BillingChange.propTypes = {
  value: PropTypes.string,
};
BillingChange.defaultProps = {
  value: "",
};

export default BillingChange;
