import moment from "moment";
import PropTypes from "prop-types";
import { Segment } from "semantic-ui-react";
import { isNil, map } from "lodash";
import { MIcon } from "@macrium/shared-components";

const BillingContainer = ({ orders }) => {
  return (
    <div>
      {map(orders, ({ m_transId, m_transTime, m_resller, m_LicenseeEmail }, i) => (
        <Segment
          data-testid="Bill-block"
          raised
          key={`${m_transId}-${i}`}
          className={i === orders.length - 1 ? "" : "mb3"}
        >
          <div className="flex flex-justify">
            <div className="order__title">
              <MIcon name="receipt" size="large" className="mr2" />
              <div className="order">
                <h3 className="bold m0">Order ID:</h3>
                <h3 className="bold m0 pl1">{m_transId}</h3>
              </div>
            </div>
          </div>
          <div className="order">
            <div className="order__details">
              <p className="bold m0">Purchase Date</p>
              <p className="m0">{moment.utc(m_transTime).local().format("YYYY-MM-DD")}</p>
            </div>
            <div className="order__details">
              <p className="bold m0">Seller</p>
              <p className="m0">{isNil(m_resller) ? "Macrium - Cleverbridge" : m_resller}</p>
            </div>
            <div className="order__details">
              <p className="bold m0">Licensee Contact</p>
              <p className="m0 email-address">{m_LicenseeEmail}</p>
            </div>
          </div>
        </Segment>
      ))}
    </div>
  );
};
BillingContainer.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default BillingContainer;
