import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

const ExternalLink = ({ content, url, hasOwnLogo }) => (
  <a
    href={url}
    className="flex flex-center getting-started-container__link justify-between border rounded p2 clickable "
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="bold m0 flex-grow center ">{content}</div>
    {!hasOwnLogo && <Icon className="m0" name="external" size="big" />}
  </a>
);

export default ExternalLink;

ExternalLink.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  url: PropTypes.string.isRequired,
  hasOwnLogo: PropTypes.bool,
};
ExternalLink.defaultProps = {
  hasOwnLogo: false,
};
