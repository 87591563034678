import { get } from "lodash";
import {
  DEPLOYMENT_KIT,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  REFLECT_SERVER,
  REFLECT_SERVER_SUBSCRIPTION,
  REFLECT_WORKSTATION,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  SERVER_PLUS,
  SERVER_PLUS_SUBSCRIPTION,
  SITE_MANAGER,
  SITE_MANAGER_SUBSCRIPTION,
  TECHNICIAN,
  TECHNICIAN_SUBSCRIPTION,
} from "../constants/licenses";
import { LEGACY_DOWNLOAD_URLS, SUBSCRIPTION } from "../constants/licenses-products";

const getDownloadUrl = (data, downloadUrlsV8) => {
  let downloadUrl = "";

  // To take care of downgraded and upgraded keys (where "version" is the initial version of the key)
  if (data.version === "8" || data.subscription === SUBSCRIPTION) {
    /*
     * Hack needed until we have a proper download url coming from the backend.
     * TODO: It needs adding to the Release table probably
     * Exception: Tech and DeploymentKit. They are Subscription but there are legacy versions
     */
    switch (data.description) {
      case SITE_MANAGER_SUBSCRIPTION:
        downloadUrl = downloadUrlsV8[SITE_MANAGER]?.downloadURL;
        break;
      case REFLECT_SERVER_SUBSCRIPTION:
        downloadUrl = downloadUrlsV8[REFLECT_SERVER]?.downloadURL;
        break;
      case REFLECT_WORKSTATION_SUBSCRIPTION:
        downloadUrl = downloadUrlsV8[REFLECT_WORKSTATION]?.downloadURL;
        break;
      case SERVER_PLUS_SUBSCRIPTION:
        downloadUrl = downloadUrlsV8[SERVER_PLUS]?.downloadURL;
        break;
      case TECHNICIAN:
      case TECHNICIAN_SUBSCRIPTION: {
        if (data.version === "6" || data.version === "7") {
          downloadUrl = get(LEGACY_DOWNLOAD_URLS, `${data.version}.${data.description}`, "");
        } else {
          downloadUrl = downloadUrlsV8[TECHNICIAN]?.downloadURL;
        }
        break;
      }
      case DEPLOYMENT_KIT:
      case DEPLOYMENT_KIT_SUBSCRIPTION: {
        if (data.version === "6" || data.version === "7") {
          downloadUrl = get(LEGACY_DOWNLOAD_URLS, `${data.version}.${data.description}`, "");
        } else {
          downloadUrl = downloadUrlsV8[DEPLOYMENT_KIT]?.downloadURL;
        }
        break;
      }

      default:
        downloadUrl = downloadUrlsV8[data.description]?.downloadURL;
    }
  } else if (data.version === "6" || data.version === "7") {
    downloadUrl = get(LEGACY_DOWNLOAD_URLS, `${data.version}.${data.description}`, "");
  }

  return downloadUrl;
};
export default getDownloadUrl;
