import * as Sentry from "@sentry/react";

import { store } from "./store";
import { getAccountEmail } from "../selectors/basics";
import CONFIG from "./config";

if (CONFIG.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: CONFIG.REACT_APP_SENTRY_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://manage.macrium.com"],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  
    beforeSend(event) {
      event.tags = event.tags || {};
  
      // Add user context
      event.tags['macrium_account_email'] = getAccountEmail(store.getState()) || "";
      
      return event;
    }
  });  
}
