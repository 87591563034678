import React from "react";
import moment from "moment";
import { MIcon } from "@macrium/shared-components";

import HeaderWithContent from "./HeaderWithContent.jsx";
import RegistrationCode from "./RegistrationCode.jsx";
import { SUBSCRIPTION, PERPETUAL } from "../../../constants/licenses-products.js";
import { SITEDEPLOY, SITE_MANAGER } from "../../../constants/licenses.js";
import { formatDate } from "../../../utils/generic.js";
import SubscriptionLabel from "../../licence/SubscriptionLabel/SubscriptionLabel.jsx";
import { useSelector } from "react-redux";
import { isEnterpriseUser } from "../../../selectors/basics.js";

const LicenseHeader = ({ data, account }) => {
  const isExpired = moment().isAfter(moment(data.nextExpiry));
  const isEnterprise = useSelector(isEnterpriseUser);
  return (
    <div className="product-card__product__extended-header">
      <div className="flex flex-wrap" style={{ gap: "1em" }}>
        {data.subscription === PERPETUAL && data.lastPurchasedOn && (
          <div>
            <HeaderWithContent title="Last Purchased On:">{moment(data.lastPurchasedOn).format("L")}</HeaderWithContent>
          </div>
        )}

        {data.isTrial === "1" && (
          <>
            <RegistrationCode account={account} />

            {data.description === SITE_MANAGER && (
              <div className="site-manager-trial-info">
                <div className="site-manager-trial-info__icon">
                  <MIcon name="info circle" size="large" color="cGrey4" className="mr1" />
                </div>
                <div className="site-manager-trial-info__text">
                  Each Site Manager trial starts with a MAL Server trial license and a MAL Workstation (5-seat) trial
                  license
                </div>
              </div>
            )}
          </>
        )}

        {data.subscription === SUBSCRIPTION && (
          <>
            <div className="product-card__product__extended-header__subscription">
              {isExpired ? (
                <HeaderWithContent title="Expired On:">
                  {data.lastExpiry ? moment(data.lastExpiry).format("L") : null}
                </HeaderWithContent>
              ) : (
                <>
                  <HeaderWithContent title="Upcoming Renewal Date:">
                    {data.nextExpiry ? formatDate(data.nextExpiry.unix()) : null}
                  </HeaderWithContent>
                  <HeaderWithContent
                    title="Total To Pay:"
                    className="product-card__product__extended-header__subscription-pay"
                  >
                    {data.totalToPay}
                  </HeaderWithContent>
                </>
              )}
            </div>
          </>
        )}

        {(data.description === SITEDEPLOY || data.description === SITE_MANAGER) && data.seats && !isEnterprise && (
          <HeaderWithContent title={data.description === SITEDEPLOY ? "Endpoints:" : "Seats:"}>
            {data.seats}
          </HeaderWithContent>
        )}
      </div>

      <div>
        <SubscriptionLabel
          isPerpetual={data.subscription !== SUBSCRIPTION}
          isExpired={isExpired}
          isTrial={data.isTrial === "1"}
        />
      </div>
    </div>
  );
};

export default LicenseHeader;
