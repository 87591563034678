import React from "react";
import PropTypes from "prop-types";
import { isUndefined, isEmpty, map, get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "semantic-ui-react";
import { updateFilter } from "../../../slices/products/products.js";
import { BLUE_PILL, GREY_PILL } from "../../../constants/licenses.js";
import { isEnterpriseUser } from "../../../selectors/basics.js";
import "./TagFilter.styles.scss";
import doNotShowTag from "../../../utils/license/doNotShowTag.js";

const TagFilter = ({ tagSystemNeeded, count, filteredCount, allRenewalSame, description, filters, allExpired }) => {
  const dispatch = useDispatch();
  const isEnterprise = useSelector(isEnterpriseUser);
  return (
    <>
      {tagSystemNeeded && (
        <div className="flex flex-center flex-wrap pb3 tagFilterContainer">
          {map(count, (value, tagName) => {
            if (
              doNotShowTag({
                tagName: tagName,
                allRenewalSame: allRenewalSame,
                allExpired: allExpired,
                isEnterprise: isEnterprise,
              })
            )
              return null;
            const noFiltersSelected = isEmpty(filteredCount);
            const isLabelClickable = noFiltersSelected || !isUndefined(filteredCount[tagName]);
            const currentLabelCount = filteredCount[tagName] || 0;
            return (
              <Label
                key={tagName}
                data-testid={isLabelClickable ? "clickable" : "disabled"}
                content={
                  <div className="flex">
                    {!isEnterprise && (
                      <div className="tagFilterCount">({!noFiltersSelected ? currentLabelCount : value})</div>
                    )}
                    <div>{tagName}</div>
                  </div>
                }
                circular
                className={`${isUndefined(get(filters, `${description}.${tagName}`)) ? GREY_PILL : BLUE_PILL} ${
                  isLabelClickable ? "clickable" : "disabled"
                }`}
                onClick={() =>
                  isLabelClickable
                    ? dispatch(
                        updateFilter({
                          key: description,
                          filter: [tagName],
                        })
                      )
                    : null
                }
              />
            );
          })}
        </div>
      )}
    </>
  );
};

TagFilter.propTypes = {
  tagSystemNeeded: PropTypes.bool,
  count: PropTypes.object,
  totalCount: PropTypes.number,
  filteredCount: PropTypes.object,
  allRenewalSame: PropTypes.bool,
  description: PropTypes.string,
  filters: PropTypes.object,
};

TagFilter.defaultProps = {
  tagSystemNeeded: false,
  count: {},
  totalCount: 0,
  filteredCount: {},
  allRenewalSame: false,
  description: "",
  filters: {},
};

export default TagFilter;
