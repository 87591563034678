import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Input } from "semantic-ui-react";
import { MIcon, FormFieldError } from "@macrium/shared-components";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { get, isEmpty } from "lodash";
import * as COUNTRIES from "countries-list";

import CountrySelector from "../generic/Inputs/CountrySelector";

import "./ProfilingAddress.styles.scss";
import FormFieldController from "../generic/FormFieldController";
import { ADDRESS_MAX_LENGTH } from "../../constants/forms/validation";
import { useDispatch } from "react-redux";
import { setIsBlocking } from "../../slices/prompt/prompt";
import { hasUnsavedChanges } from "../../selectors/basics";
import { store } from "../../app/store";

const places = ["places"];
const ProfilingAddress = ({
  addressLine1,
  addressLine2,
  city,
  stateProvince,
  postalZipCode,
  country,
  oneFieldPerLine,
  errors,
  setValue,
  control,
  autoFocus,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAxpKjcDyABvoZR7szeIoL4aX3jcPCA_kU",
    libraries: places,
  });
  const [addressLookUp, setAddressLookUp] = React.useState("");
  const [manualEntry, setManualEntry] = React.useState(false);
  const searchBoxRef = React.useRef();
  const dispatch = useDispatch();
  const hasAnyAddressLine = !!(addressLine1 || addressLine2 || stateProvince || postalZipCode || country || city);
  const isBlocking = hasUnsavedChanges(store.getState());

  React.useEffect(() => {
    if (manualEntry) return;
    if (hasAnyAddressLine || !isLoaded || loadError) setManualEntry(true);

    return () => dispatch(setIsBlocking(false));
  }, [manualEntry, hasAnyAddressLine, dispatch, isLoaded, loadError]);

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();

    if (places && places.length > 0) {
      const place = places[0];

      const components = {};

      if (isEmpty(place?.address_components)) return;

      place.address_components.forEach((component) => {
        component.types.forEach((type) => {
          components[type] = component.long_name;
          components[`${type}_short_name`] = component.short_name;
        });
      });

      let line1 = "";
      if (components["street_number"]) {
        line1 += components["street_number"];
      }
      if (components["route"]) {
        line1 = line1 ? `${line1} ${components["route"]}` : components["route"];
      }

      const line2 = components["sublocality_level_1"] || "";

      const city = components["locality"] || components["postal_town"];

      let stateProvince = "";

      if (components["country"] === "United Kingdom") {
        stateProvince = components["administrative_area_level_2"];
      } else {
        stateProvince = components["administrative_area_level_1"];
      }

      const postalZipCode = components["postal_code"];

      const countryShortName = components["country_short_name"];

      const countryName = get(COUNTRIES.countries, `${countryShortName}.name`);

      setManualEntry(true);
      setAddressLookUp("");
      dispatch(setIsBlocking(true));
      setValue("addressLine1", line1 || "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("addressLine2", line2 || "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("city", city || "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("stateProvince", stateProvince || "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("postalZipCode", postalZipCode || "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
      setValue("country", countryName || "", { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  };
  const ignoreKeyPress = (e) => {
    if (e.key === "Enter") {
      return e.preventDefault();
    }
    if (!isBlocking) {
      dispatch(setIsBlocking(true));
    }
    return;
  };

  return (
    <>
      {isLoaded && !loadError && (
        <Form.Field error={!manualEntry && !addressLookUp && (!!errors?.city || !!errors?.country)}>
          <label>Address Lookup</label>

          <StandaloneSearchBox onLoad={(ref) => (searchBoxRef.current = ref)} onPlacesChanged={handlePlacesChanged}>
            <div className="relative">
              <Input
                icon
                autoFocus={autoFocus}
                iconPosition="left"
                placeholder="Search for your address..."
                value={addressLookUp}
                onChange={(e) => setAddressLookUp(e.target.value)}
              >
                <input />
                <MIcon name="home" />
              </Input>
              {addressLookUp && (
                <Button
                  content="Enter Manually"
                  size="small"
                  className="manual-address-button"
                  onClick={() => {
                    setManualEntry(true);
                    setAddressLookUp("");
                  }}
                />
              )}
            </div>
          </StandaloneSearchBox>

          <FormFieldError
            error={!manualEntry && !addressLookUp && (!!errors?.city || !!errors?.country) ? "Address is required" : ""}
          />
        </Form.Field>
      )}

      <Form.Field hidden={!manualEntry} error={!!errors?.addressLine1}>
        <label>Address Line 1</label>

        <FormFieldController
          name="addressLine1"
          control={control}
          rules={ADDRESS_MAX_LENGTH}
          render={({ field }) => <Input {...field} onKeyDown={ignoreKeyPress} />}
        />

        <FormFieldError error={errors?.addressLine1?.message} />
      </Form.Field>

      <Form.Field hidden={!manualEntry} error={!!errors?.addressLine2}>
        <label>Address Line 2</label>

        <FormFieldController
          name="addressLine2"
          control={control}
          render={({ field }) => <Input {...field} onKeyDown={ignoreKeyPress} />}
        />

        <FormFieldError error={errors?.addressLine2?.message} />
      </Form.Field>

      <Form.Field hidden={!manualEntry} error={!!errors?.city}>
        <label>City</label>

        <FormFieldController
          name="city"
          control={control}
          rules={{ required: "City is required" }}
          render={({ field }) => <Input {...field} onKeyDown={ignoreKeyPress} />}
        />

        <FormFieldError error={errors?.city?.message} />
      </Form.Field>

      <div className={oneFieldPerLine ? "" : "flex justify-between"} style={{ gap: "10px" }}>
        <Form.Field hidden={!manualEntry} style={{ flex: 1 }} error={!!errors?.stateProvince}>
          <label>State/Province</label>
          <FormFieldController
            name="stateProvince"
            control={control}
            render={({ field }) => <Input {...field} onKeyDown={ignoreKeyPress} />}
          />

          <FormFieldError error={errors?.stateProvince?.message} />
        </Form.Field>

        <Form.Field
          hidden={!manualEntry}
          style={{ flex: 1 }}
          className={oneFieldPerLine ? "mb2" : ""}
          error={!!errors?.postalZipCode}
        >
          <label>Postcode / Zip code</label>

          <FormFieldController
            name="postalZipCode"
            control={control}
            render={({ field }) => <Input {...field} onKeyDown={ignoreKeyPress} />}
          />

          <FormFieldError error={errors?.postalZipCode?.message} />
        </Form.Field>
      </div>

      <Form.Field hidden={!manualEntry} error={!!errors?.country}>
        <label>Country</label>

        <FormFieldController
          name="country"
          control={control}
          rules={{ required: "Country is required" }}
          render={({ field: { ref, onChange, ...field } }) => (
            <CountrySelector
              onChange={(e, { value }) => onChange(get(COUNTRIES.countries, `${value}.name`))}
              {...field}
            />
          )}
        />

        <FormFieldError error={errors?.country?.message} />
      </Form.Field>
    </>
  );
};

ProfilingAddress.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  stateProvince: PropTypes.string,
  postalZipCode: PropTypes.string,
  country: PropTypes.string,
  autoFocus: PropTypes.bool,
  oneFieldPerLine: PropTypes.bool,

  errors: PropTypes.object, // errors from react-hook-form
  control: PropTypes.any.isRequired, // control property from react-hook-form
  setValue: PropTypes.func.isRequired, // setValue from react-hook-form
};

ProfilingAddress.defaultProps = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  stateProvince: "",
  postalZipCode: "",
  country: "",
  errors: {},
  oneFieldPerLine: false,
};

export default ProfilingAddress;
