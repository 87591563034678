import { REFLECT_HOME } from "../../constants/licenses";

export const progressStages = [
  {
    productName: REFLECT_HOME,
    videoUrl: "https://www.youtube.com/embed/ojKh3IMQWAc",
    stages: [
      { title: "Create your Macrium Account", videoStages: [], number: 1 },
      {
        title: "Install Macrium Reflect Home",
        isComplete: false,
        isActive: false,
        number: 2,
        videoStages: [
          {
            number: 1,
            title: "Download the installer",
            content: (
              <p>
                From the &apos;<b>Downloads</b>&apos; page of your Macrium account, select &apos;<b>Download</b>&apos;
                under the Reflect Home card.
              </p>
            ),
          },
          {
            number: 2,
            title: "Enter your license key",
            content: (
              <p>
                If you&apos;ve purchased Reflect then your license keys can be found on the &apos;<b>Licenses</b>&apos;
                page of your Macrium account. Enter your license key into the installer to associate it with this
                computer. If you&apos;re trialing Reflect, then no license key will be required.
              </p>
            ),
          },
          {
            number: 3,
            title: "Select components to install",
            content: <p>Select the installation options for Macrium Reflect Home. </p>,
          },
          {
            number: 4,
            title: "Complete the installer ",
            content: <p />,
          },
        ],
      },
      {
        title: "Create an image of your disks",
        isComplete: false,
        isActive: false,
        number: 3,
        videoStages: [
          {
            number: 1,
            title: "Decide where your backups will be stored",
            content: (
              <p>
                First, decide where your backups will be stored. The amount of storage you will need will depend on the
                data you&apos;re backing up and how many backups you want to keep at any one time.
              </p>
            ),
          },
          {
            number: 2,
            title: "Select what you want to back up",
            content: (
              <p>
                Next, decide what you will be backing up. At a minimum, we recommend creating a disk image of your
                operating system. This will ensure that you can get your system up and running again in the event of a
                disaster.
              </p>
            ),
          },
          {
            number: 3,
            title: "Select the destination",
            content: <p>Select where the backups will be stored.</p>,
          },
          {
            number: 4,
            title: "Create a schedule and define retention rules",
            content: (
              <p>
                Select a schedule from the list of templates and configure the retention rules to control how many
                backups to keep.
              </p>
            ),
          },
          {
            number: 5,
            title: "Finish and options",
            content: (
              <p>
                In the window that opens, leave &apos;<b>Save as a Backup Definition File</b>&apos; checked to save your
                backup settings and &apos;<b>Run this backup now</b>&apos; to create a backup immediately.
              </p>
            ),
          },
        ],
      },
      {
        title: "View your backups, schedules, and logs",
        isComplete: false,
        isActive: false,
        number: 4,
        videoStages: [
          {
            number: 1,
            title: "View and manage backup",
            content: (
              <p>
                To view and manage your backups, select the &apos;<b>Existing Backups</b>&apos; tab.
              </p>
            ),
          },
          {
            number: 2,
            title: "View and manage backup",
            content: (
              <p>
                To view and edit schedules, select the &apos;<b>Scheduled Backups</b>&apos; tab.
              </p>
            ),
          },
          {
            number: 3,
            title: "View log files",
            content: (
              <p>
                Any time a backup runs, a log file will be created that contains information about the backup, including
                whether the backup was created successfully or not.
              </p>
            ),
          },
        ],
      },
      {
        title: "Create rescue media",
        isComplete: false,
        isActive: false,
        number: 5,
        videoStages: [
          {
            number: 1,
            title: "Open the rescue media builder",
            content: (
              <p>
                In the event of a disaster, you may not be able to log in to Windows to restore your backups. A rescue
                media will ensure that you can access Macrium Reflect to restore your backups in the event of a
                disaster. We recommend using a USB drive to create your rescue media, this will only need to be around
                2GB.
              </p>
            ),
          },
          {
            number: 2,
            title: "Create the rescue media",
            content: (
              <p>
                For most media, the default settings will be suitable, select your USB drive then select &apos;
                <b>Build</b>&apos;. On older operating systems, you may be prompted to download some components from
                Microsoft.
              </p>
            ),
          },
          {
            number: 3,
            title: "Eject your media",
            content: (
              <p>
                Keep this media safe in a convenient location. In the event of a disaster, it can be used to restore
                your data.
              </p>
            ),
          },
        ],
      },
    ],
  },
];
export const finalStage = {
  title: "Finish and Close",
  isActive: true,
  isComplete: false,
};
