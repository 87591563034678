import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const OEMCompleted = ({ handleReset }) => {
  return (
    <div>
      <div className="mb1">Thanks for activating the above License.</div>
      <div className="mb3">You will receive an email shortly with your key.</div>
      <div className="mb2">To activate another license please click below.</div>
      <Button primary onClick={() => handleReset()} content="Activate another license" />
    </div>
  );
};

export default OEMCompleted;

OEMCompleted.propTypes = {
  handleReset: PropTypes.func.isRequired,
};
