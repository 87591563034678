import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Input } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { MIcon, FormFieldError } from "@macrium/shared-components";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import { updateAccountDetailsAsync } from "../../slices/account/details";
import { getAccountDetails, getIsProfiling, getTrialProduct, isDetailsUpdating } from "../../selectors/basics";
import { downloadTrialAsync } from "../../slices/downloads/downloads";
import { clearTrialProduct, setIsProfiling } from "../../slices/products/trials";
import { removeWebsiteDownloadCookie } from "../../utils/download";
import { MACRIUM_TRIAL } from "../../constants/cookies";
import { createToastWithHeader } from "../generic/ToastWithHeader";
import * as TOAST_TYPES from "../../constants/toast";
import PhoneInput from "../generic/Inputs/PhoneInput";
import ProfilingAddress from "./ProfilingAddress";
import { isSuccessAction } from "../../utils/redux/actions";
import useTrialProduct from "../../utils/hooks/useTrialProduct";
import FormFieldController from "../generic/FormFieldController";
import { PHONE_MAX_LENGTH, JOB_MAX_LENGTH, COMPANY_MAX_LENGTH } from "../../constants/forms/validation";
import { setIsBlocking } from "../../slices/prompt/prompt";

const ProfilingModal = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      job: "",
      company: "",
      phone: "",
      addressLine1: "",
      addressLine2: "",
      stateProvince: "",
      postalZipCode: "",
      city: "",
      country: "",
    },
  });

  const accountDetails = useSelector(getAccountDetails);
  const trialProductDetails = useSelector(getTrialProduct);
  const dispatch = useDispatch();

  const isProfiling = useSelector(getIsProfiling);
  const isUpdating = useSelector(isDetailsUpdating);
  const needsProfiling = useTrialProduct();

  useEffect(() => {
    if (needsProfiling?.profilingNeeded) dispatch(setIsProfiling(true));
  }, [dispatch, needsProfiling?.profilingNeeded]);

  const closeModal = (cancelled) => {
    dispatch(setIsProfiling(false));

    // If there is a cookie, it means the download action was started externally
    if (Cookies.get(MACRIUM_TRIAL)) {
      removeWebsiteDownloadCookie();

      if (cancelled) {
        toast(
          createToastWithHeader(
            "Download Cancelled",
            <>You can download this product from the Overview or Downloads page</>
          ),
          { type: TOAST_TYPES.INFO }
        );
      }
    }

    dispatch(clearTrialProduct());
  };

  useEffect(() => {
    if (accountDetails) {
      setValue("job", accountDetails.job || "");
      setValue("company", accountDetails.company || "");
      setValue("phone", accountDetails.phone || "");
      setValue("addressLine1", accountDetails.addressLine1 || "");
      setValue("addressLine2", accountDetails.addressLine2 || "");
      setValue("city", accountDetails.city || "");
      setValue("stateProvince", accountDetails.stateProvince || "");
      setValue("postalZipCode", accountDetails.postalZipCode || "");
      setValue("country", accountDetails.country || "");
    }
  }, [setValue, accountDetails]);

  const onSubmit = async (form) => {
    // Just for prevention. react hook form does not call onSubmit if there are errors
    if (!isEmpty(errors)) return;

    const updatedData = {
      ...accountDetails,
      job: form.job,
      company: form.company,
      phone: form.phone,
      addressLine1: form.addressLine1,
      addressLine2: form.addressLine2,
      city: form.city,
      stateProvince: form.stateProvince,
      postalZipCode: form.postalZipCode,
      country: form.country,
    };

    const action = await dispatch(updateAccountDetailsAsync(updatedData));

    if (isSuccessAction(action)) {
      closeModal(0);
      reset();
      dispatch(setIsBlocking(false));
      if (!isEmpty(trialProductDetails)) {
        const { architecture, edition, description, siteType } = trialProductDetails;

        // Send siteType because the profiling modal can break the flow (click download - actually start download)
        // Needs to keep the context of where it has come from
        dispatch(downloadTrialAsync({ architecture, edition, description, productName: description }, { siteType }));
      }
    }
  };

  return (
    <Modal
      open={isProfiling}
      size="tiny"
      closeIcon={
        <span onClick={() => closeModal(1)}>
          <MIcon name="close" className="close-modal-button" />
        </span>
      }
      className="profiling-modal"
    >
      <Modal.Header>Just One More Thing Please {accountDetails.firstName}...</Modal.Header>

      <Modal.Content>
        <p className="mb3">In order to download a business product can you please fill in the missing details below</p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field error={!!errors?.job}>
            <label>Job Role</label>

            <FormFieldController
              name="job"
              control={control}
              rules={{ required: "Job Role is required", ...JOB_MAX_LENGTH }}
              render={({ field }) => (
                <Input icon iconPosition="left" autoFocus placeholder="Job Role" {...field}>
                  <input />
                  <MIcon name="job role" />
                </Input>
              )}
            />

            <FormFieldError error={errors?.job?.message} />
          </Form.Field>

          <Form.Field error={!!errors?.company}>
            <label>Your Company</label>
            <FormFieldController
              name="company"
              control={control}
              rules={{ required: "Company is required", ...COMPANY_MAX_LENGTH }}
              render={({ field }) => (
                <Input icon iconPosition="left" placeholder="Your Company" {...field}>
                  <input />
                  <MIcon name="company" />
                </Input>
              )}
            />
            <FormFieldError error={errors?.company?.message} />
          </Form.Field>

          <Form.Field>
            <label>Phone Number (Optional)</label>

            <FormFieldController
              name="phone"
              control={control}
              rules={PHONE_MAX_LENGTH}
              render={({ field: { ref, ...field } }) => <PhoneInput {...field} />}
            />

            <FormFieldError error={errors?.phone?.message} />
          </Form.Field>

          <ProfilingAddress
            addressLine1={watch("addressLine1")}
            addressLine2={watch("addressLine2")}
            city={watch("city")}
            stateProvince={watch("stateProvince")}
            postalZipCode={watch("postalZipCode")}
            country={watch("country")}
            setValue={setValue}
            errors={errors}
            control={control}
            autoFocus={false}
          />

          <Button
            loading={isUpdating}
            disabled={isUpdating}
            content="Complete My Profile"
            size="large"
            primary
            fluid
            type="submit"
            className="mt3"
          />
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ProfilingModal;
