import { push } from "connected-react-router";
import { get } from "lodash";

import { setAuthCookie } from "../../utils/auth";
import ROUTES from "../../app/routes";
import { broadcastLogin } from "../../app/broadcastChannel";
import { fetchInitialData } from "./login";
import { LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME } from "../../constants/auth";

/**
 * Helper functions
 */
export const handleLoginResponse = async (dispatch, payload, email) => {
  localStorage.removeItem(LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME);

  if (get(payload, "data.needsActivation"))
    return dispatch(push(`${ROUTES.AFTER_REGISTER}?email=${email}`));

  if (get(payload, "data.needs2FA")) return dispatch(push(ROUTES.TFA));

  if (payload.success) {
    setAuthCookie(payload.data.token);

    broadcastLogin();

    dispatch(fetchInitialData());
  }
};
