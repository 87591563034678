import { doGetRequest } from "./requester";

const apiLicenceUrl = "api/licence";
export const getLicences = (email) =>
  doGetRequest(`${apiLicenceUrl}/licences/${encodeURIComponent(email)}`);

export const getLicenceInfo = (email) =>
  doGetRequest(
    `${apiLicenceUrl}/getlicenseinfo?extraDetail=true&email=${encodeURIComponent(email)}`
  );

export const getUnassignedLicenses = (email) =>
  doGetRequest(`${apiLicenceUrl}/getunassignedlicenses?email=${encodeURIComponent(email)}`);
