import { Link } from "@macrium/shared-components";
import "./TandCPolicy.styles.scss";
import moment from "moment";
import { /*EULA_POLICY,*/ PRIVACY_POLICY } from "../../../constants/urls";

const TandCPolicy = ({ loggedIn }) => {
  const currentYear = moment().local().year();
  return (
    <div className={`tAndCcontainer ${loggedIn ? "loggedInText" : "loggedOutText"}`}>
      <div>
        Copyright &copy; {currentYear} - Paramount Software UK Limited<span className="ml1">|</span>
      </div>
      <a className="mx1" href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
        <Link content="Terms & Privacy Policy" />
      </a>
      {/* |
       <a className="mx1" href={EULA_POLICY} target="_blank" rel="noopener noreferrer">
        <Link content="EULA Policy" />
      </a> */}
    </div>
  );
};

export default TandCPolicy;
