import React from "react";
import { Button } from "semantic-ui-react";

import "./BlockResellerMessage.styles.scss";
import { PARTNER_PORTAL, SUBMIT_TICKET } from "../../constants/urls";

export const BlockResellerMessage = () => (
  <>
    <p>We've noticed that you're trying to use an email which is already registered to a Macrium reseller account.</p>
    <p>Please login into your reseller account or contact support.</p>

    <p className="flex">
      <Button primary as="a" href={PARTNER_PORTAL} target="_blank" rel="noopener noreferrer">
        Reseller Login
      </Button>
      <Button secondary className="ml2" as="a" href={SUBMIT_TICKET} target="_blank" rel="noopener noreferrer">
        Contact Support
      </Button>
    </p>
  </>
);
