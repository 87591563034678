import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { get } from "lodash";
import { toast } from "react-toastify";

import { IDLE, LOADING, FAILED } from "../actionStatuses";
import * as API from "../../app/api/authAccount";
import {
  setAuthCookie,
  setLoginCookie,
  removeAuthCookie,
  setRememberEmail,
  removeRememberEmail,
  setRememberCheckBox,
  getInitialAuthTokenForStore,
} from "../../utils/auth";
import * as TOAST_TYPES from "../../constants/toast";
import { getLoginForm, isLoggedIn } from "../../selectors/basics";
import ROUTES from "../../app/routes";
import {
  broadcastSetAuthToken,
  broadcastLogout,
} from "../../app/broadcastChannel";
import { changeEmailAsync } from "../account/details";
import {
  googleRegisterThunk,
  googleTryRegisterThunk,
  microsoftRegisterThunk,
  microsoftTryRegisterThunk,
} from "./register";
import { handleLoginResponse } from "./helpers";
import { LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME } from "../../constants/auth";

const REDUCER_NAME = "login";

const initialState = {
  status: IDLE,
  authToken: getInitialAuthTokenForStore(),
  code1: "",
  form: {
    email: "",
    password: "",
    rememberMe: false,
  },
  universalMessage: "",
  needsActivationAfterLogin: false,
  hasDoneInitialLoad: false,
};

/**
 * Async Actions
 */
const loginThunk = createAsyncThunk(
  `${REDUCER_NAME}/loginRequest`,
  async (form, thunkAPI) => {
    const response = await API.login(form);

    if (!response.success) {
      if (!get(response, "data.needsActivation")) {
        toast(response.message, { type: TOAST_TYPES.ERROR });
      }

      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    if (form.rememberMe) {
      setRememberEmail(form.email);
    } else {
      removeRememberEmail();
    }
    setRememberCheckBox(form.rememberMe);
    setLoginCookie();

    return response;
  }
);

const googleLoginThunk = createAsyncThunk(
  `${REDUCER_NAME}/googleLoginRequest`,
  async (data, thunkAPI) => {
    const response = await API.googleLogin(data);

    if (!response.success) {
      toast(response.message, { type: TOAST_TYPES.ERROR });

      return thunkAPI.rejectWithValue(response);
    }
    setLoginCookie();

    return response;
  }
);

const microsoftLoginThunk = createAsyncThunk(
  `${REDUCER_NAME}/microsoftLoginRequest`,
  async (microsoftToken, thunkAPI) => {
    const response = await API.microsoftLogin(microsoftToken);

    if (!response.success) {
      toast(response.message, { type: TOAST_TYPES.ERROR });

      return thunkAPI.rejectWithValue(response);
    }
    setLoginCookie();

    return response;
  }
);

// Redirect the user to the previous page/overview page after a successfully login
export const loginAndThenRedirectAsync = () => async (dispatch, getStore) => {
  const form = getLoginForm(getStore());

  const { payload } = await dispatch(loginThunk(form));

  await handleLoginResponse(dispatch, payload, form.email);
};

// Login user after successfully resetting password
export const loginAfterPasswordResetAsync =
  (form) => async (dispatch, getStore) => {
    const { payload } = await dispatch(loginThunk(form));

    if (payload.success)
      await handleLoginResponse(dispatch, payload, form.email);

    // User is redirected by the custom routers
  };

export const googleLoginAndThenRedirectAsync = (data) => async (dispatch) => {
  const { payload } = await dispatch(googleLoginThunk(data));

  if (payload.success)
    await handleLoginResponse(dispatch, payload, payload.data.email);

  // User is redirected by the custom routers
};

export const microsoftLoginAndThenRedirectAsync =
  (data) => async (dispatch) => {
    const { payload } = await dispatch(microsoftLoginThunk(data));

    if (payload.success)
      await handleLoginResponse(dispatch, payload, payload.data.email);

    // User is redirected by the custom routers
  };

/**
 * See refreshAuthTokenAsync for more details on race condition
 */
const refreshAuthTokenThunk = createAsyncThunk(
  `${REDUCER_NAME}/refreshAuthTokenRequest`,
  async (data, thunkAPI) => {
    const response = await API.refreshAuthToken();

    const loggedIn = isLoggedIn(thunkAPI.getState());

    if (!response.success || !loggedIn) return thunkAPI.rejectWithValue(response);

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const setCookieAndBroadcast = (authToken) => {
  setAuthCookie(authToken);
  broadcastSetAuthToken({ authToken });
};

/**
 * This function needs to be useful carefully.
 * There is a race condition where /refreshtoken and /invalidatetoen (logout) can be fired at the same time.
 * If the /refreshtoken request ends after /invalidatetoken (logout), the new auth token will be saved in the store and the app breaks.
 * This function should only be used to refresh the auth token when the user is logged in!
 * It can ignore the response if the user is not logged in.
 * @param {*} form 
 * @returns 
 */
export const refreshAuthTokenAsync = (form) => async (dispatch, getState) => {
  const { payload } = await dispatch(refreshAuthTokenThunk(form));

  const loggedIn = isLoggedIn(getState());

  if (!payload.success|| !loggedIn) return;

  setCookieAndBroadcast(payload.data.token);
};

/**
 * Reducer
 */
export const loginReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    clearState: () => initialState,
    setFormFieldValue: (state, { payload }) => {
      state.form[payload.field] = payload.value;
    },
    loginWithSession: (state, { payload: authToken }) => {
      state.authToken = authToken;
    },
    setHasDoneInitialLoad: (state, { payload: hasDone }) => {
      state.hasDoneInitialLoad = hasDone;
    },
    setAuthToken: (state, { payload }) => {
      state.authToken = payload.authToken;
    },
    fetchInitialData: (state) => state,
    clearAfterLogin: (state) => ({
      ...initialState,
      needsActivationAfterLogin: state.needsActivationAfterLogin,
      code1: state.code1,
      authToken: state.authToken,
      form: {
        ...initialState.form,
        email: state.form.email,
      },
    }),
    setAuthTokenAfter2FA: (state, { payload }) => {
      state.authToken = payload.authToken;
      state.code1 = "";
      state.form = initialState.form;
    },
    setUniversalMessage: (state, { payload }) => {
      state.universalMessage = payload.message || "";
    },
    // RootReducer clears the state but passes down the action
    // Because the store is cleared before this action, we can override the universalMessage here
    logout: (state, { payload = {} }) => {
      state.authToken = ""; // clear authToken just to make sure
      state.universalMessage = payload.message || "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.status = LOADING;
        state.universalMessage = "";
        state.needsActivationAfterLogin = false;
      })
      .addCase(loginThunk.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.authToken = payload.data.needs2FA ? "" : payload.data.token;
        state.code1 = payload.data.needs2FA ? payload.data.code1 : "";
        state.form = {
          ...initialState.form,
          email: state.form.email || payload.data.email, // necessary for when Resetting Password and needs 2fa
        };
        state.needsActivationAfterLogin = false;
      })
      .addCase(loginThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.needsActivationAfterLogin = get(
          payload,
          "data.needsActivation",
          false
        );
      })

      .addCase(googleLoginThunk.pending, (state) => {
        state.status = LOADING;
        state.code1 = "";
      })
      .addCase(googleLoginThunk.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.authToken = payload.data.needs2FA ? "" : payload.data.token;
        state.code1 = payload.data.needs2FA ? payload.data.code1 : "";
        state.form = {
          ...initialState.form,
          email: payload.data.email, // necessary for when it needs 2fa
        };
      })
      .addCase(googleLoginThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
      })

      .addCase(microsoftLoginThunk.pending, (state) => {
        state.status = LOADING;
        state.code1 = "";
      })
      .addCase(microsoftLoginThunk.fulfilled, (state, { payload }) => {
        state.status = IDLE;
        state.authToken = payload.data.needs2FA ? "" : payload.data.token;
        state.code1 = payload.data.needs2FA ? payload.data.code1 : "";
        state.form = {
          ...initialState.form,
          email: payload.data.email, // necessary for when it needs 2fa
        };
      })
      .addCase(microsoftLoginThunk.rejected, (state, { payload }) => {
        state.status = FAILED;
      })

      .addCase(refreshAuthTokenThunk.fulfilled, (state, { payload }) => {
        state.authToken = payload.data.token;
      })

      // Update authToken when email is successfully changed
      .addCase(changeEmailAsync.fulfilled, (state, { payload }) => {
        state.authToken = payload.data.token;
      })
      // Update authToken after try google register
      .addCase(googleTryRegisterThunk.fulfilled, (state, { payload }) => {
        state.authToken = payload.data.needs2FA ? "" : payload.data.token;
        state.code1 = payload.data.needs2FA ? payload.data.code1 : "";
        state.form = {
          ...initialState.form,
          email: payload.data.email, // necessary for when it needs 2fa
        };
      })
      // Update authToken after google register
      .addCase(googleRegisterThunk.fulfilled, (state, { payload }) => {
        state.authToken = payload.data.token;
      })
      // Update authToken after try microsoft register
      .addCase(microsoftTryRegisterThunk.fulfilled, (state, { payload }) => {
        state.authToken = payload.data.needs2FA ? "" : payload.data.token;
        state.code1 = payload.data.needs2FA ? payload.data.code1 : "";
        state.form = {
          ...initialState.form,
          email: payload.data.email, // necessary for when it needs 2fa
        };
      })
      // Update authToken after microsoft register
      .addCase(microsoftRegisterThunk.fulfilled, (state, { payload }) => {
        state.authToken = payload.data.token;
      });
  },
});

/**
 * Actions
 */
export const {
  setFormFieldValue,
  clearAfterLogin,
  setAuthTokenAfter2FA,
  clearState,
  setUniversalMessage,
  logout,
  setAuthToken,
  loginWithSession,
  fetchInitialData,
  setHasDoneInitialLoad,
} = loginReducer.actions;

// It takes care of removing the cookie and dispatch the logout action which will clear the store
export const logoutThunk =
  (payload = {}) =>
  (dispatch) => {
    removeAuthCookie();

    API.invalidateToken();

    broadcastLogout();

    dispatch(logout({ message: payload.message || "" }));

    dispatch(push(ROUTES.LOGIN));

    localStorage.removeItem(LAST_TOKEN_TIME_REFRESH_LOCAL_STORAGE_NAME);

    if (!payload.message)
      toast("You Have Successfully Logged Out", { type: TOAST_TYPES.SUCCESS });
  };

export default loginReducer.reducer;
