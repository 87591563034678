import { useRef } from "react";
import { map, isUndefined, find } from "lodash";
import { Popup } from "semantic-ui-react";
import { isMobile, isIOS } from "react-device-detect";
import plur from "plur";
import {
  EXPIRED,
  TECHNICIAN,
  DEPLOYMENT_KIT,
  LICENSES_DESCRIPTIVE_STRING,
  QTY,
  UPGRADE_KEY,
  OFFLINE_ACTIVATED_WARNING,
} from "../../constants/licenses.js";
import PropTypes from "prop-types";
import { MIcon } from "@macrium/shared-components";
import CopyToClipboard from "../generic/CopyToClipboard.jsx";

const TagDisplay = ({ description, pairedKey, isDowngraded, isVirtualBundle, text, id, statuses }) => {
  const isMobileDevice = isIOS || isMobile;
  const mobileRef = useRef();
  if (id === OFFLINE_ACTIVATED_WARNING) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf " color="orange" />
            </div>
          }
          wide
          hideOnScroll
          content={
            <div>
              <p className="">
                This offline-activated license is approaching its reactivation date. Once it expires, reactivation will
                be required on the same device.
              </p>
            </div>
          }
          position={isMobileDevice ? "bottom center" : "bottom right"}
          context={isMobileDevice ? mobileRef : null}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (!isUndefined(pairedKey) && id.startsWith("V") && id.length === 2) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          wide
          hideOnScroll
          content={
            <div>
              <p className="bold mb2">
                {`This license has been ${isDowngraded ? "downgraded" : "upgraded"} from the
                    following ${plur("key", pairedKey.length)}`}
              </p>
              {pairedKey.length > 1 ? (
                map(pairedKey, (oldKey, i) => (
                  <p key={oldKey + i}>
                    <CopyToClipboard textToCopy={oldKey} /> {oldKey}
                  </p>
                ))
              ) : (
                <p className="monospace flex">
                  <CopyToClipboard textToCopy={pairedKey[0]} /> {pairedKey[0]}
                </p>
              )}
            </div>
          }
          position={isMobileDevice ? "bottom center" : "bottom right"}
          context={isMobileDevice ? mobileRef : null}
          hoverable
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (id === UPGRADE_KEY) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={
            <div>
              <p className="bold mb2">
                {`Enter this key into version ${find(statuses, ({ text }) => text.startsWith("V"))?.text.substring(
                  1
                )} installation of ${LICENSES_DESCRIPTIVE_STRING[description]} for instructions`}
              </p>
              <p>This version is no longer supported by Macrium</p>
            </div>
          }
          context={isMobileDevice ? mobileRef : null}
          wide
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (isVirtualBundle && id === QTY) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={"This license can only be installed on virtual machines"}
          wide
          context={isMobileDevice ? mobileRef : null}
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  if (id === EXPIRED && (description === TECHNICIAN || description === DEPLOYMENT_KIT)) {
    return (
      <div className="licence-row__label">
        <span>{text}</span>
        <Popup
          className="fix-popup-alignment"
          trigger={
            <div data-testid="popup-trigger">
              <MIcon size="largeSmall" name="info circle" className="mlhalf mt0 mb0" />
            </div>
          }
          hideOnScroll
          content={"This license key can can no longer be used to create technician's portable application"}
          wide
          context={isMobileDevice ? mobileRef : null}
          position={isMobileDevice ? "bottom center" : "bottom right"}
        />
        {isMobileDevice && <div ref={mobileRef} className="licence-row__mobile-ref" />}
      </div>
    );
  }
  return text;
};
export default TagDisplay;

TagDisplay.propTypes = {
  description: PropTypes.string.isRequired,
  pairedKey: PropTypes.array,
  isDowngraded: PropTypes.bool,
  isVirtualBundle: PropTypes.bool,
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  statuses: PropTypes.array,
  activationCode: PropTypes.string,
};
TagDisplay.defaultProps = {
  text: "",
  description: "",
  activationCode: "guid-goes-here",
};
