import React, { useEffect, useCallback } from "react";
import { Tab } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { push, getLocation } from "connected-react-router";

import Login from "./login.jsx";
import Register from "./register.jsx";
import ROUTES from "../../app/routes";
import { registerType } from "../../selectors/basics.js";
import { disabledAuthTabs } from "../../selectors/auth.js";
import { clearAfterLogin } from "../../slices/auth/login.js";
import { REGISTER_TYPES } from "../../constants/auth.js";
import { checkIsRegistered } from "../../app/api/authAccount.js";
import { TrialMessage } from "./TrialMessage.jsx";
import { getTrialFromCookie } from "../../utils/getTrialFromCookie.js";
import OfflineActivationLink from "./OfflineActivationLink/OfflineActivationLink.jsx";

const AuthContent = ({ children }) => <div className="auth-content">{children}</div>;

const panes = [{ menuItem: "Login" }, { menuItem: "Register" }];

const LOGIN_INDEX = 0;
const REGISTER_INDEX = 1;

const Auth = () => {
  const { pathname, search } = useSelector(getLocation);
  const disableMenu = useSelector(disabledAuthTabs);
  const registerTypeValue = useSelector(registerType);
  const trialProductDetails = getTrialFromCookie();

  const [activeIndex, setActiveIndex] = React.useState(pathname === ROUTES.LOGIN ? LOGIN_INDEX : REGISTER_INDEX);

  const dispatch = useDispatch();
  const goTo = useCallback((value) => dispatch(push(value)), [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const email = params.get("purchaseemail");
    if (email) {
      checkIsRegistered(email)
        .then((response) => {
          const isRegistered = JSON.parse(response.message);
          if (!isRegistered) {
            setActiveIndex(REGISTER_INDEX);
            goTo(`${ROUTES.REGISTER}?email=${email}`);
          }
        })
        .catch((error) => {
          console.log("Error checking registration status: ", error);
        });
    }
  }, [search, goTo]);

  return (
    <>
      {trialProductDetails && <TrialMessage trialDetails={trialProductDetails} />}
      <OfflineActivationLink />
      {registerTypeValue === REGISTER_TYPES.EMAIL && (
        <Tab
          id="macrium-auth-tabs"
          menu={{
            secondary: true,
            pointing: true,
            tabular: true,
            style: {
              display: "flex",
              justifyContent: "center",
              border: "none",
            },
            className: disableMenu ? "disabled" : "",
          }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={(e, { activeIndex }) => {
            dispatch(clearAfterLogin()); // to clean the universal message and other login properties in the store
            goTo(activeIndex === LOGIN_INDEX ? ROUTES.LOGIN : ROUTES.REGISTER);
          }}
        />
      )}

      <AuthContent>
        {activeIndex === LOGIN_INDEX && <Login />}
        {activeIndex === REGISTER_INDEX && <Register />}
      </AuthContent>
    </>
  );
};

export default Auth;
