import {
  DEPLOYMENT_KIT,
  DEPLOYMENT_KIT_SUBSCRIPTION,
  REFLECT_HOME,
  REFLECT_HOME_SUBSCRIPTION,
  REFLECT_SERVER,
  REFLECT_SERVER_SUBSCRIPTION,
  REFLECT_WORKSTATION,
  REFLECT_WORKSTATION_SUBSCRIPTION,
  SERVER_PLUS,
  SERVER_PLUS_SUBSCRIPTION,
  SITEDEPLOY,
  SITE_MANAGER,
  SITE_MANAGER_SUBSCRIPTION,
  TECHNICIAN,
  TECHNICIAN_SUBSCRIPTION,
} from "./licenses";

export const hotjarEvents = {
  [REFLECT_SERVER]: "clicked_download_reflect_server",
  [REFLECT_SERVER_SUBSCRIPTION]: "clicked_download_reflect_server",
  [DEPLOYMENT_KIT]: "clicked_download_deployment_kit",
  [DEPLOYMENT_KIT_SUBSCRIPTION]: "clicked_download_deployment_kit",
  [TECHNICIAN]: "clicked_download_technician",
  [TECHNICIAN_SUBSCRIPTION]: "clicked_download_technician",
  [REFLECT_HOME]: "clicked_download_reflect_home",
  [REFLECT_HOME_SUBSCRIPTION]: "clicked_download_reflect_home",
  [REFLECT_WORKSTATION]: "clicked_download_reflect_workstation",
  [REFLECT_WORKSTATION_SUBSCRIPTION]: "clicked_download_reflect_workstation",
  [SERVER_PLUS]: "clicked_download_server_plus",
  [SERVER_PLUS_SUBSCRIPTION]: "clicked_download_server_plus",
  [SITEDEPLOY]: "clicked_download_sitedeploy",
  [SITE_MANAGER]: "clicked_download_site_manager",
  [SITE_MANAGER_SUBSCRIPTION]: "clicked_download_site_manager",
};
export const CLICKED_DOWNLOAD_BUTTON = "clicked_download_button";
export const ONBOARDING_CARD_CLOSED = "onboarding_card_closed";
