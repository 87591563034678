import { createSlice } from '@reduxjs/toolkit';

const REDUCER_NAME = 'prompt';

const initialState = {
  open: true,
  isBlocking: false,
  requestedPage: undefined
};

export const promptReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setIsBlocking: (state, { payload: isBlocking }) => {
      state.isBlocking = isBlocking;
    },
    setOpen: (state, { payload: { open, requestedPage } }) => {
      state.open = open;
      state.requestedPage = requestedPage
    }
  }
});

export const { clearState, setIsBlocking, setOpen } = promptReducer.actions;

export default promptReducer.reducer;
