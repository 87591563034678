import { Input, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { FormFieldError, Link } from "@macrium/shared-components";
import { noop } from "lodash";
import {
  OLD_REFLECT_OFFLINE_ACTIVATION,
  REFLECT_RELEASE_NOTES,
  SITE_MANAGER_RELEASE_NOTES,
  SUBMIT_TICKET,
} from "../../../constants/urls";
import {
  isProductSiteDeploy,
  isProductSiteManager,
  isProductSiteManagerOrSiteDeploy,
} from "../../../utils/license/productIsSiteManager";
import { LICENSES_DESCRIPTIVE_STRING, SITEDEPLOY, SITE_MANAGER } from "../../../constants/licenses";
import { INVALID_ACTIVATION_CODE, INVALID_LICENSE_KEY } from "../../../constants/error-codes";

const OfflineStepOne = ({
  activationCode,
  alreadyActiveOfflineKey,
  setActivationCode,
  handleActivation,
  selectedLicenseKey,
  showExpiryWarning,
  error,
  notLoggedIn,
  isActivating,
  productDescription,
}) => {
  const isSM = isProductSiteManager(productDescription);
  const isSD = isProductSiteDeploy(productDescription);
  const isSMSD = isProductSiteManagerOrSiteDeploy(productDescription);

  const shouldShowReflectNotes = notLoggedIn || (productDescription && !isSMSD);

  const shouldShowSMNotes = notLoggedIn || (productDescription && isSMSD);

  return (
    <div className="flex justify-between flex-column offline-step-container">
      <div>
        <div className="offline-step-header">Step 1</div>
        {showExpiryWarning && (
          <div data-testid="offline-expiry-warning">
            <FormFieldError
              className="py1 red"
              error="This License will expire within 1 month, be aware that you will need to reactivate it on the same machine
            once the subscription renews."
            />
          </div>
        )}
        <div className="offline-step-content mt2">
          <p>
            {notLoggedIn ? (
              "Some Macrium products"
            ) : (
              <>
                {isSM && LICENSES_DESCRIPTIVE_STRING[SITE_MANAGER]}
                {isSD && LICENSES_DESCRIPTIVE_STRING[SITEDEPLOY]}
                {!isSD && !isSM && "Macrium Reflect"}
              </>
            )}{" "}
            can be used in offline environments like secure networks or remote locations but please note that an
            internet connection is required for any patches or major updates. We recommend periodically checking the{" "}
            {shouldShowReflectNotes && (
              <>
                <a href={REFLECT_RELEASE_NOTES} target="_blank" rel="noopener noreferrer">
                  Macrium Reflect release notes
                </a>{" "}
              </>
            )}
            {notLoggedIn && <>and the </>}
            {shouldShowSMNotes && (
              <a href={SITE_MANAGER_RELEASE_NOTES} target="_blank" rel="noopener noreferrer">
                Macrium Site Manager release notes
              </a>
            )}{" "}
            for any updates relevant to your system.
          </p>
          <p>
            Once a license key is offline activated, it cannot be reverted, transferred, or used on another device, you
            will need to contact support for any changes.
          </p>
          {alreadyActiveOfflineKey ? (
            <p>
              The selected license is already activated and linked to an offline device, to generate a new offline key
              for the same device, press continue.
            </p>
          ) : (
            <p>
              Get the activation code from the offline device, paste into the &apos;Activation Code&apos; field below
              and press &apos;Offline Activate&apos;
            </p>
          )}

          {notLoggedIn && (
            /** TODO: to be removed when we release SM/SD offline activation (perpetual and subs) */
            <p style={{ fontSize: "11px" }}>
              If you need to activate version 8 Site Manager keys,{" "}
              <a href={OLD_REFLECT_OFFLINE_ACTIVATION} target="_blank" rel="noopener noreferrer">
                click here
              </a>
              .
            </p>
          )}
        </div>
      </div>
      {alreadyActiveOfflineKey ? (
        <div>
          <Button
            className="mt3"
            primary
            disabled={isActivating}
            onClick={handleActivation}
            loading={isActivating}
            content="Continue"
          />
        </div>
      ) : (
        <div className={`flex flex-column mt2 ${notLoggedIn ? "" : "offline-stepOne-form"}`}>
          <div className="bold my1">Activation Code:</div>
          <Input
            autoFocus={selectedLicenseKey}
            placeholder="Enter Code..."
            error={!!error && error === INVALID_ACTIVATION_CODE}
            value={activationCode}
            onChange={(e) => setActivationCode(e.target.value)}
          />

          {error && error !== INVALID_LICENSE_KEY && (
            <>
              <FormFieldError className="py1 red" error={error} />
              {notLoggedIn && (
                <div className="flex">
                  If this is unexpected, please
                  <a href={SUBMIT_TICKET} target="_blank" rel="noopener noreferrer" className="mlhalf">
                    <Link>contact support</Link>
                  </a>
                  .
                </div>
              )}
            </>
          )}
          <Button
            className="mt3"
            primary
            disabled={!activationCode || isActivating || !selectedLicenseKey}
            onClick={handleActivation}
            loading={isActivating}
            content="Offline Activate"
          />
        </div>
      )}
    </div>
  );
};
OfflineStepOne.propTypes = {
  activationCode: PropTypes.string.isRequired,
  setActivationCode: PropTypes.func.isRequired,
  handleActivation: PropTypes.func.isRequired,
  error: PropTypes.string,
  isActivating: PropTypes.bool.isRequired,
  notLoggedIn: PropTypes.bool,
  alreadyActiveOfflineKey: PropTypes.bool,
  showExpiryWarning: PropTypes.bool,
  selectedLicenseKey: PropTypes.string.isRequired,
  productDescription: PropTypes.string,
};
OfflineStepOne.defaultProps = {
  notLoggedIn: false,
  showExpiryWarning: false,
  error: null,
  alreadyActiveOfflineKey: false,
  productDescription: noop(),
};

export default OfflineStepOne;
