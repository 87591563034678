import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";

const CommPrefs = ({ accountDetails, handleChange, handleSave }) => {
  const [commPrefsFlag, setCommPrefsFlag] = useState(false);

  useEffect(() => {
    if (accountDetails) {
      setCommPrefsFlag(accountDetails.subscribe);
    }
  }, [accountDetails]);

  return (
    <div className="account-details__section">
      <div className="account-details__section__left">
        <h3 className="account-details__section__left__title">Communication</h3>
        <h3 className="account-details__section__left__title">Preferences</h3>
      </div>

      <div className="account-details__section__right">
        <p className="account-details__section__right__text">
          I'd like Macrium to keep me up to date with news and offers about the software by email:
        </p>
        <Form>
          <Form.Group>
            <Form.Radio
              label="Yes"
              value={true}
              checked={commPrefsFlag === true}
              onChange={(e, { value }) => {
                handleChange(e, "subscribe");
                handleSave({ subscribe: value });
                setCommPrefsFlag(value);
              }}
            />
            <Form.Radio
              label="No"
              value={false}
              checked={commPrefsFlag === false}
              onChange={(e, { value }) => {
                handleChange(e, "subscribe");
                handleSave({ subscribe: value });
                setCommPrefsFlag(value);
              }}
            />
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default CommPrefs;
