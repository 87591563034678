import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { EventType, BrowserAuthErrorMessage } from "@azure/msal-browser";

import SSOButton from "./SSOButton.jsx";

/*
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps
 */
const MicrosoftButton = ({ authCallback, width, register }) => {
  const { instance } = useMsal();

  React.useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      // Called after user being redirected from the Microsoft authentication
      if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || message.eventType === EventType.LOGIN_SUCCESS) {
        authCallback(message.payload);
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [authCallback, instance]);

  return (
    <SSOButton
      type="microsoft"
      text={register ? "Sign up" : "Sign in"}
      width={width}
      onClick={() => {
        instance
          .loginRedirect({
            scopes: ["user.read"],
            prompt: "select_account",
          })
          .then((response) => authCallback(response))
          .catch((err) => {
            if (err.errorCode === BrowserAuthErrorMessage.redirectInIframeError.code) {
              toast("Redirects are not supported on iframes", {
                type: "error",
              });
            } else if (err.errorCode === BrowserAuthErrorMessage.popupWindowError.code) {
              toast("Please allow pop-ups for this site", {
                type: "error",
              });
            } else if (err.errorCode === BrowserAuthErrorMessage.userCancelledError.code) {
              toast(`Could not finish the authentication process (${err.errorCode})`, {
                type: "error",
              });
            }
          });
      }}
    />
  );
};

MicrosoftButton.propTypes = {
  authCallback: PropTypes.func.isRequired,
  width: PropTypes.string,
  register: PropTypes.bool,
};

MicrosoftButton.defaultProps = {
  register: false,
};

export default MicrosoftButton;
