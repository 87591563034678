import { map } from "lodash";
import PropTypes from "prop-types";
import { Button, Segment } from "semantic-ui-react";

import "./BasicOnboardingCard.styles.scss";
import { LICENSES_DESCRIPTIVE_STRING } from "../../../constants/licenses";
import { unix } from "moment";
import CopyToClipboard from "../../generic/CopyToClipboard";
import ListItem from "./ListItem/ListItem";

const onboardingSteps = (name, licenseKey) => [
  { id: 1, text: <span>Once the trial software has completed downloading, run the installer.</span> },
  { id: 2, text: <span>Proceed through the installation steps until you reach the license key page.</span> },
  { id: 3, text: <span>Uncheck the trial option.</span> },
  {
    id: 4,
    text: (
      <div className="flex flex-wrap items-center">
        <span className="mr1">Enter your {name} license key: </span>
        <div className="flex items-center">
          <CopyToClipboard textToCopy={licenseKey} />
          <b className="mx1">{licenseKey}</b>
        </div>
        <span>to enable the {name} features.</span>
      </div>
    ),
  },
];

const BasicOnboardingCard = ({ name, licenseKey, handleClose, expiryDate }) => {
  const productName = LICENSES_DESCRIPTIVE_STRING[name];

  // Hack - The server creates the license key for Tech/DK on the server and it's unaware of the user's timezone.
  // The license key expires on a specific day, at midnight (on the installed computer)
  // To show a consistent date to all users, we add the daylight saving time to the date.
  // Because the backend always returns the beginning of the day, we should be covered.
  const validExpiryDate = unix(expiryDate)?.utc().utcOffset(60).format("L") || null;

  return (
    <Segment id={`${name}-onboarding`}>
      <div className="top-row-container">
        <h4 className="card-header">Get started with {productName}</h4>{" "}
      </div>
      <p className="subheading">Follow these steps to install and set up the {productName} trial:</p>
      <ul className="m0 p0">
        {map(onboardingSteps(name, licenseKey), (item) => (
          <ListItem item={item} key={item.id} />
        ))}
      </ul>
      {validExpiryDate && (
        <p className="subheading pt2">
          Your {name} trial license key will remain active until: <b className="ml1">{validExpiryDate}</b>
        </p>
      )}
      <Button primary content="Finish" className="fullWidthOnMobile" onClick={() => handleClose(name)} />
    </Segment>
  );
};

BasicOnboardingCard.propTypes = {
  name: PropTypes.string.isRequired,
  licenseKey: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  expiryDate: PropTypes.string,
};
BasicOnboardingCard.defaultProps = {
  expiryDate: null,
};
export default BasicOnboardingCard;
