import { QTY } from "../../constants/licenses";
import { isLicenseSiteManagerType } from "./productIsSiteManager";
import statusValidForOfflineActivation from "./statusValidForOfflineActivation";

const setUpLicenseOptions = (licenses) => {
  if (!licenses || licenses.length === 0) return [];
  const licenseOptions = licenses.reduce((acc, license) => {
    if (statusValidForOfflineActivation(license?.statuses) && !license.isVirtualBundle) {
      const productName = license.statuses.find((status) => isLicenseSiteManagerType(status.id))?.id || null;
      const seats = license.statuses.find((status) => status.id === QTY)?.text || "";
      const productText = productName ? `${productName} (${seats})` : null;
      const productRenewal =
        license.statuses.find((status) => status?.text?.startsWith("Ren") || status?.test?.startsWith("Sup"))?.text ||
        null;
      return [
        ...acc,
        {
          id: license.licenseId,
          title: license.licenseId,
          subHeading: productRenewal,
          key: license.licenseId,
          description: productText,
          disabled: false,
        },
      ];
    }
    return acc;
  }, []);

  return licenseOptions.sort((a, b) => a?.description?.localeCompare(b.description));
};
export default setUpLicenseOptions;
