import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, MIcon } from '@macrium/shared-components';
import Cookies from 'js-cookie';
import { includes } from 'lodash';

import { INFO } from '../constants/toast';
import { MACRIUM_TRIAL } from "../constants/cookies.js";

let abortController = null;

export const getAbortTrialDownloadController = () => abortController;

/**
 * Function to download an external file
 * @param {Object} downloadInfo
 * @param {string} downloadInfo.downloadUrl - URL to download the file from
 * @param {string} downloadInfo.filename - Name of the downloaded file
 * @param {Object} config
 * @param {string} config.progressMessage - Message which will be shown on the toast notification
 * @param {func} config.onCancel - Callback for when the user cancels the download
 */
export const downloadExternalFile = async ({ downloadUrl, filename }, { progressMessage, onCancel }) => {
  let toastId = null;

  abortController = new AbortController();

  try {
    /**
     * Download File
     */
    const configDownload = {
      method: "get",
      url: downloadUrl,
      responseType: "blob",
      signal: abortController.signal,
      onDownloadProgress: function (axiosProgressEvent) {
        // check if we already displayed a toast
        if (toastId === null) {
          toastId = toast(
            <div>
              {progressMessage}
              
              <div style={{ fontSize: '12px' }} className="mt2">
                <Link content="Cancel Download" onClick={onCancel} />
              </div>
            </div>, {
            type: INFO,
            progress: axiosProgressEvent.progress,
            closeButton: false,
            draggable: false,
            closeOnClick: false,
            className: "download-toast toast-large",
            icon: <MIcon name="download" color="cGrey1" />
          });
        } else {
          toast.update(toastId, { progress: axiosProgressEvent.progress });
        }
      },
    };

    let responseDownload;

    try {
      responseDownload = await axios(configDownload);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        // clear abortController and toast
        abortController = null;
        toast.done(toastId);

        return { cancelled: true };
      } else {
        console.error('Error downloading trial: ', err);

        throw new Error("Invalid Response to Download:", err.message);
      }
    }

    // clear abortController
    abortController = null;

    if (!responseDownload) throw new Error("Invalid response to download");

    // Clear toast
    toast.done(toastId);

    const objectUrl = window.URL.createObjectURL(responseDownload.data);
    
    const link = document.createElement("a");
    link.href = objectUrl;
    
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    
    link.click();
    link.remove();

    return { success: true };
  } catch (err) {
    abortController = null;

    console.error('General error downloading trial: ', err);

    return { success: false };
  }
};

export const removeWebsiteDownloadCookie = () => {
  /**
   * Firefox is special - t does not remove cookies that have expired (Chrome does) and there was no way to remove it programmatically.
   * The only solution I found is to set the value of the same cookie with "undefined" and then delete
   * 
   */
  /**
   * Because the cookie comes from the website, domain needs to be present to be successfully deleted
   * https://github.com/js-cookie/js-cookie#basic-usage - scroll down to "IMPORTANT!" bit
   * */
  const options = {};

  if (!includes(window.location.hostname, "localhost")) {
    options.domain = ".macrium.com";
  }

  Cookies.set(MACRIUM_TRIAL, undefined, options);

  Cookies.remove(MACRIUM_TRIAL, options);
}
