import PropTypes from "prop-types";
import { Button, Input } from "semantic-ui-react";
import { FormFieldError } from "@macrium/shared-components";
import { INVALID_LICENSE_KEY, NOT_VALID_OEM_LICENSE } from "../../../constants/error-codes";
import PhoneNumbers from "../../generic/PhoneNumbers/PhoneNumbers";

const OEMWelcomPage = ({
  activationCode,
  setActivationCode,
  handleActivation,
  error,
  isActivating,
  selectedLicenseKey,
}) => {
  const errorButNotLicenseKey = !!error && error !== INVALID_LICENSE_KEY;
  return (
    <div className="flex flex-column mt3">
      <div className="bold mb1 h4">Welcome to your Macrium Setup</div>
      <div className="mb2">
        To complete the setup process and receive your activation key, please enter your details below.
      </div>
      <div className="bold mb1">How to Activate</div>
      <ul>
        <li>
          <b>Enter Your Information:</b>
          <span className="ml1">Provide your License Key and Activation Code.</span>
        </li>
        <li>
          <b>Submit:</b>
          <span className="ml1">
            Click the "Continue" button below and complete the form on the next page to submit your information.
          </span>
        </li>
        <li>
          <b>Check Your Email:</b>
          <span className="ml1">
            We will send your key to the email address you provide. Simply follow the instructions in the email to
            finalize your setup.
          </span>
        </li>
      </ul>

      <div className="bold my1">Activation Code:</div>
      <Input
        autoFocus={selectedLicenseKey}
        placeholder="Enter Code..."
        value={activationCode}
        error={errorButNotLicenseKey}
        onChange={(e) => setActivationCode(e.target.value)}
      />
      {errorButNotLicenseKey && error !== NOT_VALID_OEM_LICENSE && <FormFieldError className="py1 red" error={error} />}
      <Button
        className="mt2"
        primary
        disabled={!activationCode || isActivating || !selectedLicenseKey}
        onClick={handleActivation}
        loading={isActivating}
        content="Continue"
      />
      <div className="bold mt3 mb1">Need Assistance?</div>
      <div className="mb2">
        If you have any questions or need help with the activation process, our support team is here to assist you.
      </div>
      <PhoneNumbers />
    </div>
  );
};

export default OEMWelcomPage;

OEMWelcomPage.propTypes = {
  activationCode: PropTypes.string.isRequired,
  setActivationCode: PropTypes.func.isRequired,
  handleActivation: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isActivating: PropTypes.bool.isRequired,
  selectedLicenseKey: PropTypes.string.isRequired,
};
