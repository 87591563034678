const OVERVIEW = "OVERVIEW";
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const REGISTER = "REGISTER";
const OFFLINE_ACTIVATION = "OFFLINE_ACTIVATION";
const OEM_ACTIVATION = "OEM_ACTIVATION";
const TFA = "TFA";
const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const RESET_PASSWORD = "RESET_PASSWORD";
const DOWNLOADS = "DOWNLOADS";
const BILLING = "BILLING";
const ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS";
const CONFIRM_EMAIL = "CONFIRM_EMAIL";
const AFTER_REGISTER = "AFTER_REGISTER";
const LICENCES = "LICENCES";
const CHANGELOG = "CHANGELOG";

const ROUTES = {
  [OVERVIEW]: "/",

  [LOGIN]: "/login",
  [LOGOUT]: "/logout",
  [REGISTER]: "/register",
  [TFA]: "/2fa",
  [OFFLINE_ACTIVATION]: "/offlineactivation",
  [OEM_ACTIVATION]: "/oemactivation",
  [FORGOT_PASSWORD]: "/forgotpassword",
  [RESET_PASSWORD]: "/resetpassword",
  [LICENCES]: "/licences",
  [DOWNLOADS]: "/downloads",
  [BILLING]: "/billing",
  [ACCOUNT_SETTINGS]: "/account",
  [CONFIRM_EMAIL]: "/confirmemail",
  [AFTER_REGISTER]: "/afterregister",

  [CHANGELOG]: "/changelog",
};

export const ROUTES_NAMING = {
  [LOGIN]: "Login",
  [LOGOUT]: "Logout",
  [REGISTER]: "Register",

  [OVERVIEW]: "Overview",
  [LICENCES]: "Licenses",
  [DOWNLOADS]: "Download",
  [BILLING]: "Billing",
  [ACCOUNT_SETTINGS]: "Profile & Settings",
};

export default ROUTES;
