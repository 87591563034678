import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import * as TOAST_TYPES from "../../constants/toast";
import { IDLE, LOADING, FAILED } from "../actionStatuses";
import * as API from "../../app/api/authAccount";

const REDUCER_NAME = "keys";

const initialState = {
  status: IDLE,
  errorMessage: null,
};

/**
 * Async Actions
 */
export const requestKeysAsync = createAsyncThunk(`${REDUCER_NAME}/createfreshdeskticket`, async (data, thunkAPI) => {
  const response = await API.postKeyRequest(data);

  if (!response.success) return thunkAPI.rejectWithValue(response);
  toast("Your ticket has been created successfully, we'll be in touch via email shortly.", {
    type: TOAST_TYPES.SUCCESS,
  });
  return response;
});

/**
 * Reducer
 */
export const keysReducer = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestKeysAsync.pending, (state) => {
        state.status = LOADING;
        state.errorMessage = null;
      })
      .addCase(requestKeysAsync.fulfilled, (state) => {
        state.status = IDLE;
        state.errorMessage = null;
      })
      .addCase(requestKeysAsync.rejected, (state, { payload }) => {
        state.status = FAILED;
        state.errorMessage = payload;
      });
  },
});

export default keysReducer.reducer;
