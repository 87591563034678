import { createSelector } from "reselect";
import { getCollectedCookieData, getOfflineProductDetails, getSelectedLicenseKey, isProductsLoaded } from "./basics";
import { EXPIRED, OFFLINE_ACTIVATED_ACTIVE, OFFLINE_ACTIVATED_WARNING } from "../constants/licenses";
import { getLicensesForLicensesPage } from "./licenses";
import showOfflineActivationOptions from "../utils/license/showOfflineActivationOptions";

export const getNextAvailableLicense = createSelector(
  [getOfflineProductDetails, getSelectedLicenseKey],
  (product, alreadySelectedKey) => {
    if (alreadySelectedKey) return alreadySelectedKey;
    const findAvailableLicense = (licenses) => {
      if (!licenses || licenses.length === 0) return null;
      const licenseWithAvailableLicense =
        licenses.find(
          (license) =>
            !license.statuses.find(
              (status) =>
                status.id === EXPIRED ||
                status.id === OFFLINE_ACTIVATED_ACTIVE ||
                status.id === OFFLINE_ACTIVATED_WARNING
            )
        ) || null;
      return licenseWithAvailableLicense?.licenseId || null;
    };
    return findAvailableLicense(product?.licenses);
  }
);
export const getFullOfflineLicenseDetails = createSelector(
  [getLicensesForLicensesPage, getCollectedCookieData, isProductsLoaded],
  (products, offlineData, loaded) => {
    if (products && offlineData && loaded) {
      const { key, activationCode } = offlineData;
      const productsArray = Object.values(products);
      const thisProduct = productsArray.find((product) =>
        product.licenses.find((license) => license.licenseId === key)
      );
      const isEligibleProduct = showOfflineActivationOptions(thisProduct?.description) ? thisProduct : null;
      return {
        description: isEligibleProduct?.description || "No product found",
        licenses: isEligibleProduct?.licenses || [],
        activationCode: activationCode,
        selectedKey: key,
      };
    }
    return null;
  }
);
