import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form } from "semantic-ui-react";
import { get, every } from "lodash";
import PropTypes from "prop-types";
import { updatePasswordAsync } from "../../../slices/account/details";
import EditButton from "../../generic/EditButton";
import PasswordFormField from "./PasswordFormField";
import PasswordRequirements from "../../generic/PasswordRequirements";
import { checkPasswordRequirements } from "../../../utils/requirements";
import CancelButton from "../../generic/CancelButton";
import { REGISTER_TYPES } from "../../../constants/auth";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import FieldWrapper from "../FieldWrapper/FieldWrapper";

const passwordField = "password";
const newPasswordField = "newPassword";
const confirmNewPasswordField = "confirmNewPassword";

const initialState = {
  [passwordField]: "",
  [newPasswordField]: "",
  [confirmNewPasswordField]: "",
};
const setFieldAction = { type: "setFieldAction" };
const clearStateAction = { type: "clearState" };

const reducer = (state, { type, payload }) => {
  switch (type) {
    case setFieldAction.type: {
      return { ...state, [payload.field]: payload.value };
    }

    case clearStateAction.type: {
      return initialState;
    }

    default:
      break;
  }
};

const PasswordChange = ({ loginMethod }) => {
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [passwordsMatchError, setPasswordsMatchError] = useState("");
  const [passwords, internalDispatch] = React.useReducer(reducer, initialState);
  const isMobileDevice = isMobile || isTablet || isIOS;

  const dispatch = useDispatch();

  const setFormField = (value, field) => {
    setPasswordsMatchError("");

    internalDispatch({ ...setFieldAction, payload: { value, field } });
  };

  const { requirementsMetResults, allRequirementsMetResult, passwordsMatch, passwordStartEndsEmpty } =
    checkPasswordRequirements(passwords.newPassword, passwords.confirmNewPassword);

  const onEditClick = async () => {
    setEditMode(true);
    internalDispatch(clearStateAction);
    setShowValidation(false);
  };

  const onSave = async () => {
    let res = {};

    if (!allRequirementsMetResult) return;

    if (!passwordsMatch) {
      setShowValidation(true);

      return setPasswordsMatchError("Passwords do not match");
    }

    if (passwordStartEndsEmpty) {
      setShowValidation(true);

      return setPasswordsMatchError("Password cannot start or end with empty spaces");
    }

    setPasswordsMatchError("");

    if (allRequirementsMetResult && passwordsMatch && !passwordStartEndsEmpty) {
      setIsLoading(true);
      res = await dispatch(
        updatePasswordAsync({
          currentPassword: passwords.password,
          newPassword: passwords.newPassword,
        })
      );
    }

    if (get(res, "payload.success", false)) {
      setIsLoading(false);
      setEditMode(!editMode);
      internalDispatch(clearStateAction);
      setShowValidation(false);
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault();

    setEditMode(false);
    setShowValidation(false);
    setPasswordsMatchError("");
    internalDispatch(clearStateAction);
  };

  const ignoreKeyPress = (e) => {
    if (e.key === "Enter") {
      return e.preventDefault();
    }
    return;
  };
  return (
    <FieldWrapper header="Password">
      <div className="account-details__section__right__item">
        <Form
          className={`flex flex-auto account-details__password-form
        ${editMode ? "account-details__password-form--edit-mode" : ""}`}
        >
          <div className="fullwidth account-details__password-form__fields">
            {editMode ? (
              <div className="account-details__password-form__fields__wrap ">
                <PasswordFormField
                  placeholder="Enter Current Password"
                  value={passwords.password}
                  field={passwordField}
                  onChange={setFormField}
                  onKeyDown={ignoreKeyPress}
                  autoFocus
                  className="my3"
                />

                <PasswordRequirements requirementsMetResults={requirementsMetResults} showValidation={showValidation} />

                <PasswordFormField
                  placeholder="Enter a New Password"
                  value={passwords.newPassword}
                  field={newPasswordField}
                  onChange={setFormField}
                  onKeyDown={ignoreKeyPress}
                  error={passwordsMatchError}
                  className="my3"
                />

                <PasswordFormField
                  placeholder="Confirm New Password"
                  value={passwords.confirmNewPassword}
                  field={confirmNewPasswordField}
                  onChange={setFormField}
                  className="mt3"
                  onKeyDown={ignoreKeyPress}
                  error={passwordsMatchError}
                />
              </div>
            ) : (
              <div className="pt1">************</div>
            )}
          </div>
          <div
            className={`flex ${editMode ? (isMobileDevice ? "" : "flex-column justify-between") : ""}
           ${editMode ? "account-details__password-form__buttons--edit-mode" : ""} ${
              loginMethod !== REGISTER_TYPES.EMAIL ? "flex-center flex-grow" : ""
            }`}
          >
            {loginMethod === REGISTER_TYPES.EMAIL ? (
              <>
                {editMode && <CancelButton onCancelClick={handleCancelClick} disabled={isLoading} />}

                <EditButton
                  editMode={editMode}
                  onEditClick={onEditClick}
                  onSaveClick={onSave}
                  disabled={editMode && (isLoading || !every(requirementsMetResults, (req) => req.pass))}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <div className="right-align">
                Managed by {loginMethod === REGISTER_TYPES.GOOGLE ? "Google" : "Microsoft"}
              </div>
            )}
          </div>
        </Form>
      </div>
    </FieldWrapper>
  );
};
PasswordChange.propTypes = {
  loginMethod: PropTypes.string.isRequired,
};

export default PasswordChange;
