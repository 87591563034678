import { MIcon } from "@macrium/shared-components";
import { UK_SP_PHONE_NUMBER, US_SP_PHONE_NUMBER } from "../../../constants/support/support";

const PhoneNumbers = () => {
  return (
    <div className="support-contact-container">
      <div className="support-contact-container__contact">
        <MIcon className="mr2 noshrink" name="us ball" size="normalLarge" />
        <div>
          <div className="support-contact-container__number">
            <span>US Phone Support</span> <b className="mlhalf">{US_SP_PHONE_NUMBER}</b>
          </div>
          <p className="support-contact-container__opening-hours">
            MON - FRI
            {/* 09:00 - 16:00 MST */}
          </p>
        </div>
      </div>
      <div className="support-contact-container__contact">
        <MIcon className="mr2 noshrink" name="gb ball" size="normalLarge" />
        <div className="flex flex-column">
          <div className="support-contact-container__number">
            <span>UK Phone Support</span> <b>{UK_SP_PHONE_NUMBER}</b>
          </div>
          <p className="support-contact-container__opening-hours">
            MON - FRI
            {/* 09:00 - 17:00 GMT */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumbers;
